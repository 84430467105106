import { BrowserRouter, Navigate, Route, Routes, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import './assets/fe/css/preloader.css';

import AdminLayout from "./Layout/AdminLayout";
import FrontLayout from "./Layout/FrontLayout";
import { RoutesPath } from "./Routes";
import { API } from "./Helpers/ApiService";
import { URL } from "./Helpers/URL";

import Home from "./Pages/Home";
import Login from "./Pages/Login";
import Register from "./Pages/Register";
import Account from "./Pages/Account";
import NotFound from "./Pages/NotFound";
import { setUser } from "./reducers/authReducer";

import AdminLogin from "./Pages/Admin/AdminLogin";
import Dashboard from "./Pages/Admin/Dashboard";
import Country from "./Pages/Admin/Country";
import Domain from "./Pages/Admin/Domain";
import Agency from "./Pages/Admin/Agency";
import Designation from "./Pages/Admin/Designation";
import Department from "./Pages/Admin/Department";
import CMSPages from "./Pages/Admin/CMSpages";
import Speaker from "./Pages/Admin/Speaker";
import ContentPage from "./Pages/ContentPage";
import Speakers from "./Pages/Speakers";
import Contact from "./Pages/Contact";
import Addon from "./Pages/Admin/Addon";
import Users from "./Pages/Admin/Users";
import Supports from "./Pages/Admin/Supports";
import Language from "./Pages/Admin/Languages";
import Notification from "./Pages/Admin/Notification";
import RegisterNew from "./Pages/RegisterNew";
import LoginNew from "./Pages/LoginNew";
import Settings from "./Pages/Admin/Settings";
import Glimpses from "./Pages/Admin/Glimpses";
import TermsCondition from "./Pages/TermCondition";
import Schedule from "./Pages/Schedule";
import ForgotPassword from "./Pages/ForgotPassword";
import ResetPassword from "./Pages/ResetPassword";
import Download from "./Pages/Admin/Downloads";
import FeedbackFrom from "./Pages/Admin/FeedBackFrom";
import FeedbackFormFront from "./Pages/FeedBack";
import FeedbackReport from "./Pages/Admin/FeedbackReport";
import FeedbackReportDetail from "./Pages/Admin/FeedbackReportDetail";
import EventSummaryReport from "./Pages/Admin/EventSummaryReport";
import Rank from "./Pages/Admin/Rank";
import State from "./Pages/Admin/State";
import Category from "./Pages/Admin/Category";
import Event from "./Pages/Admin/Event";
import Events from "./Pages/Events";
import EventsDetails from "./Pages/EventDetail";
import PaymentReceipt from "./Pages/Admin/PaymentReceipt";
import SupportDesk from "./Pages/Admin/SupportDesk";
import SupportDeskPage from "./Pages/SupportDesk";
import UserSupportPage from "./Pages/Admin/UserSupportPage";
import Upload from "./Pages/Admin/Upload";
import JudgesEvent from "./Pages/Admin/JudgeEvent";
import ComingSoonLayout from "./Layout/CommingSoonLayout";
import Feedback from "./Pages/Admin/Feedback";

function App() {
	const dispatch = useDispatch();
	const auth = useSelector(state => state.auth);

	const [isLoaded, setIsLoaded] = useState(false);
	const [preview, setPreview] = useState(false);

	useEffect(() => {
		API({
			url: URL.ME,
			method: 'GET',
		}).then(res => {
			setIsLoaded(true)
			if (res?.code) {
				return;
			}
			dispatch(setUser(res));
		});

		const queryString = window.location?.search?.includes('preview=true');
		if (queryString) {
			setPreview(true)
		}
	}, []);

	const AuthAdmin = ({ children, redirectTo }) => {
		if (["admin", "manager", 'supportDesk', 'judge', 'superAdmin'].includes(auth?.value?.role)) {
			return children;
		} else {
			return <Navigate to={RoutesPath.Admin.path} state={{ redirect: redirectTo }} />;
		}
	}
	const AuthUser = ({ children, redirectTo }) => {
		if (auth?.value?.firstName) {
			return children;
		} else {
			return <Navigate to={RoutesPath.Login.path} state={{ redirect: redirectTo }} />;
		}
	}
	return (
		<BrowserRouter>
			{
				!isLoaded ? <div className="preloader-main"><div className="custom-loader"></div></div> : (
					<Routes>
						<Route path={RoutesPath.Home.path} element={
							<FrontLayout />
						}>
							<Route path={RoutesPath.Home.path} index element={<Home />} />
							<Route path={RoutesPath.Login.path} index element={<LoginNew />} />
							{/* <Route path={RoutesPath.Register.path} index element={<RegisterNew />} /> */}
							<Route path={RoutesPath.AboutInner.path} index element={<ContentPage />} />
							<Route path={RoutesPath.AgendaInner.path} index element={<ContentPage />} />
							<Route path={RoutesPath.CulturalProgram.path} index element={<ContentPage />} />
							<Route path={RoutesPath.TourismHeritage.path} index element={<ContentPage />} />
							<Route path={RoutesPath.SupportInner.path} index element={<ContentPage />} />
							<Route path={RoutesPath.Speakers.path} index element={<Speakers />} />
							<Route path={RoutesPath.TermAndCondition.path} index element={<TermsCondition />} />
							<Route path={RoutesPath.PrivacyPolicy.path} index element={<TermsCondition />} />
							{/* <Route path={RoutesPath.Schedule.path} index element={<Schedule />} /> */}
							<Route path={RoutesPath.ForgotPassword.path} index element={<ForgotPassword />} />
							<Route path={RoutesPath.ResetPassword.path} index element={<ResetPassword />} />

							<Route path={RoutesPath.Contact.path} index element={
								<AuthUser redirectTo={RoutesPath.Contact.path}>
									<SupportDeskPage />
								</AuthUser>
							} />
							<Route path={RoutesPath.Schedule.path} index element={
								<AuthUser redirectTo={RoutesPath.Schedule.path}>
									<Events />
								</AuthUser>
							} />
							<Route path={RoutesPath.LiveEvents.path} index element={
								<AuthUser redirectTo={RoutesPath.LiveEvents.path}>
									<Events />
								</AuthUser>
							} />
							<Route path={RoutesPath.Result.path} index element={
								<AuthUser redirectTo={RoutesPath.Result.path}>
									<Events />
								</AuthUser>
							} />

							<Route path={RoutesPath.EventDetails.path} index element={<EventsDetails />} />
							<Route path={RoutesPath.Glimpse.path} index element={<ContentPage />} />
							<Route path={RoutesPath.InformationDesk.path} index element={<ContentPage />} />
							<Route path={RoutesPath.MediaCoverage.path} index element={<ContentPage />} />
							<Route path={RoutesPath.Account.path} index element={
								<AuthUser redirectTo={RoutesPath.Account.path}>
									<Account />
								</AuthUser>
							} />
							<Route path={RoutesPath.FeedbackFormFront.path} index element={
								<AuthUser redirectTo={RoutesPath.FeedbackFormFront.path}>
									<FeedbackFormFront />
								</AuthUser>
							} />
						</Route>

						<Route path={RoutesPath.Admin.path}>
							<Route path={RoutesPath.Admin.path} index element={<AdminLogin />} />
							<Route element={<AdminLayout />}>
								<Route path={RoutesPath.Dashboard.path} element={
									<AuthAdmin redirectTo={RoutesPath.Dashboard.path}>
										<Dashboard />
									</AuthAdmin>
								} />
								<Route path={RoutesPath.Country.path} element={
									<AuthAdmin redirectTo={RoutesPath.Country.path}>
										<Country />
									</AuthAdmin>
								} />
								<Route path={RoutesPath.Rank.path} element={
									<AuthAdmin redirectTo={RoutesPath.Rank.path}>
										<Rank />
									</AuthAdmin>
								} />
								<Route path={RoutesPath.State.path} element={
									<AuthAdmin redirectTo={RoutesPath.State.path}>
										<State />
									</AuthAdmin>
								} />
								<Route path={RoutesPath.Category.path} element={
									<AuthAdmin redirectTo={RoutesPath.Category.path}>
										<Category />
									</AuthAdmin>
								} />
								<Route path={RoutesPath.Event.path} element={
									<AuthAdmin redirectTo={RoutesPath.Event.path}>
										<Event />
									</AuthAdmin>
								} />
								<Route path={RoutesPath.Domain.path} element={
									<AuthAdmin redirectTo={RoutesPath.Domain.path}>
										<Domain />
									</AuthAdmin>
								} />
								<Route path={RoutesPath.Agency.path} element={
									<AuthAdmin redirectTo={RoutesPath.Agency.path}>
										<Agency />
									</AuthAdmin>
								} />
								<Route path={RoutesPath.Designation.path} element={
									<AuthAdmin redirectTo={RoutesPath.Designation.path}>
										<Designation />
									</AuthAdmin>
								} />
								<Route path={RoutesPath.Department.path} element={
									<AuthAdmin redirectTo={RoutesPath.Department.path}>
										<Department />
									</AuthAdmin>
								} />
								<Route path={RoutesPath.CMSPages.path} element={
									<AuthAdmin redirectTo={RoutesPath.CMSPages.path}>
										<CMSPages />
									</AuthAdmin>
								} />
								<Route path={RoutesPath.Speaker.path} element={
									<AuthAdmin redirectTo={RoutesPath.Speaker.path}>
										<Speaker />
									</AuthAdmin>
								} />
								<Route path={RoutesPath.Addon.path} element={
									<AuthAdmin redirectTo={RoutesPath.Addon.path}>
										<Addon />
									</AuthAdmin>
								} />
								<Route path={RoutesPath.Language.path} element={
									<AuthAdmin redirectTo={RoutesPath.Language.path}>
										<Language />
									</AuthAdmin>
								} />
								<Route path={RoutesPath.Users.path} element={
									<AuthAdmin redirectTo={RoutesPath.Users.path}>
										<Users />
									</AuthAdmin>
								} />
								<Route path={RoutesPath.Supports.path} element={
									<AuthAdmin redirectTo={RoutesPath.Supports.path}>
										<Supports />
									</AuthAdmin>
								} />
								<Route path={RoutesPath.Notification.path} element={
									<AuthAdmin redirectTo={RoutesPath.Notification.path}>
										<Notification />
									</AuthAdmin>
								} />
								<Route path={RoutesPath.Setting.path} element={
									<AuthAdmin redirectTo={RoutesPath.Setting.path}>
										<Settings />
									</AuthAdmin>
								} />
								<Route path={RoutesPath.Glimpses.path} element={
									<AuthAdmin redirectTo={RoutesPath.Glimpses.path}>
										<Glimpses />
									</AuthAdmin>
								} />
								<Route path={RoutesPath.Download.path} element={
									<AuthAdmin redirectTo={RoutesPath.Download.path}>
										<Download />
									</AuthAdmin>
								} />
								<Route path={RoutesPath.Feedbacks.path} element={
									<AuthAdmin redirectTo={RoutesPath.Feedbacks.path}>
										<Feedback />
									</AuthAdmin>
								} />
								<Route path={RoutesPath.FeedbackReport.path} element={
									<AuthAdmin redirectTo={RoutesPath.FeedbackReport.path}>
										<FeedbackReport />
									</AuthAdmin>
								} />
								<Route path={RoutesPath.FeedbackReportDetails.path} element={
									<AuthAdmin redirectTo={RoutesPath.FeedbackReportDetails.path}>
										<FeedbackReportDetail />
									</AuthAdmin>
								} />
								<Route path={RoutesPath.EventSummaryReport.path} element={
									<AuthAdmin redirectTo={RoutesPath.EventSummaryReport.path}>
										<EventSummaryReport />
									</AuthAdmin>
								} />
								<Route path={RoutesPath.PaymentReceipt.path} element={
									<AuthAdmin redirectTo={RoutesPath.PaymentReceipt.path}>
										<PaymentReceipt />
									</AuthAdmin>
								} />

								<Route path={RoutesPath.SupportDesk.path} element={
									<AuthAdmin redirectTo={RoutesPath.SupportDesk.path}>
										<SupportDesk />
									</AuthAdmin>
								} />
								<Route path={RoutesPath.UserSupportDesk.path} element={
									<AuthAdmin redirectTo={RoutesPath.UserSupportDesk.path}>
										<SupportDesk />
									</AuthAdmin>
								} />

								<Route path={RoutesPath.Upload.path} element={
									<AuthAdmin redirectTo={RoutesPath.Upload.path}>
										<Upload />
									</AuthAdmin>
								} />

								<Route path={RoutesPath.JudgesEvent.path} element={
									<AuthAdmin redirectTo={RoutesPath.JudgesEvent.path}>
										<JudgesEvent />
									</AuthAdmin>
								} />
							</Route>
						</Route>
						<Route path='*' element={<NotFound />} />
					</Routes>
				)
			}
		</BrowserRouter>
	);
}

export default App;
