export const URL = {
    adminLogin: 'admin/login',
    LOGIN: 'auth/login',
    REGISTER: 'auth/register',
    REGISTER_DATA: 'auth/register/data',
    ME: 'auth/me',
    CHANGE_PASSWORD: 'auth/change_password',
    GET_PAGES: 'data/pages',
    GET_PAGE: 'data/page/',
    GET_PAGE_BY_TYPE: 'data/pageByType/',
    GET_SPEAKERS: 'data/speakers',
    SUPPORT: 'data/support',
    USER_DOWNLOADS: 'data/downloads',
    OPINION_CLICK: 'data/opinion/click/',
    FEEDBACK_FORM_GET: 'data/feedback/form/',
    FEEDBACK_SEND: 'data/feedback',
    CATEGORY_LIST: 'data/getCategory',
    CONTACT: 'contact',
    FORGOT_PASSWORD: 'forgot-password',
    RESET_PASSWORD: 'reset-password/',
    GET_EVENTS: 'data/event-list/',
    EVENTS_DETAILS: 'data/event-detail/',
    EVENT_USERS: 'data/event-users/',
    GET_RANKS: 'data/getRank',
    GET_STATES: 'data/getStates',
    GET_SUPPORT_USERS: 'data/get-support-users',
    GET_SUPPORT_DESK: 'data/get-support-desk',
    GET_USER_SUPPORT_DESK: 'data/get-user-support-desk',
    SAVE_USER_SUPPORT_DESK: 'data/save-user-support-desk/',
    MY_NOTIFICATION: 'data/my-notification',
    UPLOAD_EVENT_DOCUMENT: 'data/event/document/',
    COMPLETE_EVENT: 'data/event/complete/',
    SAVE_WINNERS_EVENT: 'data/event/winners/',
    GET_WINNERS_EVENT: 'data/event/winners/',
    VISITOR_COUNT: 'data/visitor/count',
    GET_SUPPORT_LIST_DRP: 'data/get-support-list',

    //Admin URLS
    UPLOAD: 'auth/upload',
    COUNTRY: 'country',
    RANK: 'rank',
    STATE: 'state',
    CATEGORY: 'category',
    EVENT: 'event',
    DOMAIN: 'domain',
    AGENCY: 'agency',
    DESIGNATION: 'designation',
    DEPARTMENT: 'department',
    CMS_PAGES: 'cmsPages',
    SPEAKER: 'speaker',
    ADDONS: 'addon',
    USERS: 'users',
    DOWNLOADS: 'download',
    USERS_ALL: 'data/alluser',
    FEEDBACK_REPORT: 'data/feedback-report',
    FEEDBACK_REPORT_DETAILS: 'data/feedback-report-details/',
    EVENT_SUMMARY_REPORT: 'data/event-summary-report',
    EXPORT_USER: 'data/users/export',
    DOWNLOAD_FILE: 'data/download/',
    LANGUAGE: 'language',
    NOTIFICATION_SEND: 'notification/send',
    GET_SETTINGS: 'data/settings',
    UPDATE_SETTINGS: 'data/settings/update',
    USER_CERT_UPDATE: 'data/users/cer/update',
    USER_CERT_DELETE: 'data/users/cer/remove',
    SESSION_UPDATE: 'data/session/',
    FEEDBACK_FORM: 'feedbackform',
    PAYMENT_RECEIPT: 'payment-receipt',
    SUPPORT_DESK: 'support-desk',
    FILE_UPLOAD: 'file-upload',
    GET_JUDGES_USER: 'data/get-judges-users',
    GET_MANAGERS: 'data/get-managers',
    GET_EVENT_ATTENDANCE: 'data/get-event-attendance/',
    SUBMIT_EVENT_ATTENDANCE: 'data/submit=event-attendance/',
    EXPORT_EVENT_ATTENDANCE: 'data/export=event-attendance/',
    GET_DASHBOARD_COUNT: 'data/get-dashboard-count/',
    NOTIFICATION_COUNT: 'data/my-notifications/',
    READ_NOTIFICATION: 'data/read-notifications/',
    GET_MY_PARTICIPANTS: 'data/get-my-participants/',
    EVENT_PARTICIPANTS_COUNT: 'data/event-user-count',
    ORG_PARTICIPANTS_COUNT: 'data/org-user-count',
    PARTICIPANTS_COUNT: 'data/participants-count',
    EXPORT_EVENT_PARTICIPANTS_COUNT: 'data/export-event-user-count',
    EXPORT_ORG_PARTICIPANTS_COUNT: 'data/export-org-user-count',
    EXPORT_PARTICIPANTS_COUNT: 'data/export-participants-count',
    EXPORT_EVENT_USER: 'data/export-event-users/',
    FEEDBACK_LIST: 'data/feedbacks/all',
    FEEDBACK: 'data/feedbacks',
    APPROVE_CERTIFICATES: 'data/approve-certificates/',
    MY_CERTIFICATES: 'data/my-certificates',
};