import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import OwlCarousel from 'react-owl-carousel';
import { useSelector } from 'react-redux';
import { Box, Modal } from '@mui/material';
import HTMLFlipBook from "react-pageflip";

import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import ArtBoard from '../assets/fe/images/Artboard.png';
import Girl from '../assets/fe/images/logoaipdm.png';
import Writer from '../assets/fe/images/RPFNEW.png';
import Vector from '../assets/fe/images/resource/vector.png';

import { URL } from '../Helpers/URL';
import { API } from '../Helpers/ApiService';
import { DOMAIN_URL, modelBoxStyle } from '../Helpers/Constants';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { RoutesPath } from '../Routes';

const Home = () => {
    const { t, i18n } = useTranslation();
    const auth = useSelector(state => state.auth);
    const [visible, setVisible] = useState(true);
    const [speakers, setSpeakers] = useState([]);
    const [speakerModel, setSpeakerModel] = useState(null);
    const [slider, setSlider] = useState([]);
    const [glimpsesVideos, setGlimpsesVideos] = useState([]);
    const [glimpsesImage, setGlimpsesImage] = useState([]);
    const [tickers, setTickers] = useState([]);

    useEffect(() => {
        setVisible(false)
        API({
            url: URL.GET_SPEAKERS,
            method: 'GET',
        }).then(res => {
            if (res?.code) {
                return;
            }
            setVisible(true)
            setSpeakers(res)
        });
        window.initWow();
        setTimeout(() => {
            $('.lightbox-image').fancybox({
                openEffect: 'fade',
                closeEffect: 'fade',
                helpers: {
                    media: {}
                }
            });
            window.dispatchEvent(new Event('resize'));
        }, 1000);
    }, []);

    useEffect(() => {
        if (auth.settings?.sliderImages) {
            setSlider(JSON.parse(auth.settings?.sliderImages))
        }
        if (auth.settings?.glimpsesVideos) {
            setGlimpsesVideos(JSON.parse(auth.settings?.glimpsesVideos))
        }
        if (auth.settings?.glimpsesImages) {
            setGlimpsesImage(JSON.parse(auth.settings?.glimpsesImages))
        }
        if (auth.settings?.tickerArray) {
            setTickers(JSON.parse(auth.settings?.tickerArray))
        }
    }, [auth.settings])

    useEffect(() => {
        setVisible(false);
        setTimeout(() => {
            setVisible(true);
        }, 100)
    }, [auth])

    const onOpinionPollClick = (session) => {
        API({
            url: URL.OPINION_CLICK + session,
            method: 'GET',
        })
    }

    return (
        <>
            {
                slider.length ? <>
                    <section className="about-section pt-4 pb-0">
                        <div className="auto-container">
                            <div className="row">
                                <div className="col-12">
                                    <OwlCarousel
                                        className='owl-theme home-slider'
                                        loop
                                        margin={10}
                                        items={1}
                                        nav={true}
                                        autoplay
                                        autoplayTimeout={10000}
                                        animateOut={'fadeOut'}
                                        animateIn={'fadeIn'}
                                        smartSpeed={7000}
                                    >
                                        {
                                            slider.map((e, i) => <div key={i} className='item'>
                                                <img className='img-responsive' src={DOMAIN_URL + e} />
                                            </div>)
                                        }
                                    </OwlCarousel>
                                </div>
                            </div>
                        </div>
                    </section>
                </> : null
            }

            {
                tickers.length ? <section className="about-section pt-4 pb-4">
                    <div className="auto-container">
                        <div className="row">
                            <div className="ol-12">
                                <div className="marquee">
                                    <div className="marquee-content">
                                        <p className="marquee-inner">
                                            {
                                                tickers.map((e, i) => <span key={i}>
                                                    {i != 0 ? '|' : ''}
                                                    <span style={{ color: '#fff' }} className="mr-3 ml-3">
                                                        {
                                                            e.link ? <a href={e.link} target='_blank' style={{ color: '#fff' }}>
                                                                {e.text[auth.language]}
                                                            </a> : e.text[auth.language]
                                                        }
                                                    </span>
                                                </span>)
                                            }
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> : null
            }

            {
                auth.value && auth.settings?.isLiveChecked == 'true' && auth.settings?.live ? <>
                    <section className="about-section pt-4 pb-0">
                        <div className="auto-container">
                            <div className="row">
                                <div className="col-12">
                                    <iframe width="100%" height="533" src={auth.settings?.live} title="UIC Live" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                                </div>
                            </div>
                        </div>
                    </section>
                </> : null
            }

            <section className="about-section about-home pt-3 pb-0">
                <div className="auto-container">
                    <div className="row">
                        <div className="mb-2 image-column col-lg-6 col-md-12 col-sm-12" style={{ marginTop: -20 }}>
                            <div className="about-image-wrapper">
                                <figure className="image-3 wow zoomIn" data-wow-delay="900ms" style={{ backgroundColor: '#fff', paddingTop: 60, paddingBottom: 60 }}>
                                    <a target='_blank' href='https://aipscb.com/'>
                                        <img src={Girl} width={250} style={{ height: 135, paddingLeft: 35, paddingRight: 35 }} alt="" />
                                    </a>
                                </figure>
                                <figure className="image-2 wow zoomIn" data-wow-delay="600ms" style={{ backgroundColor: '#FFFFFF' }}>
                                    <a target='_blank' href='https://rpf.indianrailways.gov.in/RPF/'>
                                        <img src={Writer} width={250} alt="" style={{ padding: 40 }} />
                                    </a>
                                </figure>
                                <figure className="image-1 wow zoomIn" data-wow-delay="300ms">
                                    <img src={Vector} alt="" width="400" />
                                </figure>
                                {
                                    auth.settings?.teaserVideo ? <>
                                        <a href={DOMAIN_URL + auth.settings?.teaserVideo} className="lightbox-image play-btn wow zoomIn" data-wow-delay="1200ms">
                                            <span className="icon fa fa-play"></span>
                                        </a>
                                    </> : null
                                }
                            </div>
                        </div>
                        <div className="mb-2 content-column col-lg-6 col-md-12 col-sm-12 mt-2 d-flex  align-items-end">
                            <div className="inner-column pt-0">
                                <div className="text-center sec-title mb-1 ">
                                    <h5 className='text-center'>{t('heading_1')}</h5>
                                    <h5 className='text-center'>{t('heading_2')}</h5>
                                    <span className="divider mt-1"></span>
                                </div>
                                <p className='mb-1' style={{ textAlign: 'justify', lineHeight: 1.4 }}><b> {t('heading_3')}</b></p>
                                <p className='mb-3 ' style={{ textAlign: 'justify', lineHeight: 1.4 }}> {t('pg_1')}</p>
                                <p className='mb-1' style={{ textAlign: 'justify', lineHeight: 1.4 }}><b> {t('pg_2')}</b></p>
                                <p className='mb-1' style={{ textAlign: 'justify', lineHeight: 1.4 }}><b> {t('pg_3')}</b></p>
                                <p className='mb-3 ' style={{ textAlign: 'justify', lineHeight: 1.4 }}> {t('pg_4')}</p>
                                <p className='mb-3 ' style={{ textAlign: 'justify', lineHeight: 1.4 }}> {t('pg_5')}</p>
                                <p className='mb-1' style={{ textAlign: 'justify', lineHeight: 1.4 }}><b> {t('pg_6')}</b></p>
                                <p className='mb-1 ' style={{ textAlign: 'justify', lineHeight: 1.4 }}> <b>{t('pg_8')}</b>{t('pg_9')}</p>
                                <p className='mb-1 ' style={{ textAlign: 'justify', lineHeight: 1.4 }}> <b>{t('pg_10')}</b>{t('pg_11')}</p>
                                <p className='mb-3 ' style={{ textAlign: 'justify', lineHeight: 1.4 }}> {t('pg_12')}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="about-section about-home pt-3 pb-5">
                <div className="auto-container">
                    <div className="sec-title text-center" style={{ marginBottom: 10 }}>
                        <h2>{t('flipbook_title')}</h2>
                        <span className="divider"></span>
                    </div>
                    <div className="row">
                        <div className="mb-2 col-12">
                            <div id="flipbook-container" style={{ width: '100%', height: '600px' }}>
                                <iframe id="flipbook" height={'100%'} width={'100%'} src="https://67thaipdm.org/flipbook/" frameBorder="0" allowFullScreen></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </section>



            {
                speakers.length && visible ? <section className="speakers-section pt-3 pb-0">
                    <div className="anim-icons full-width">
                        <span className="icon icon-dotted-circle"></span>
                    </div>
                    <div className="auto-container">
                        <div className="sec-title text-center" style={{ marginBottom: 10 }}>
                            <h2>{t('speaker_expert')}</h2>
                            <span className="divider"></span>
                        </div>
                        <div className="row">
                            <div className='col-12'>
                                <OwlCarousel
                                    className='owl-theme glimpses-slider'
                                    loop
                                    margin={10}
                                    items={4}
                                    nav={true}
                                    responsive={{
                                        0: {
                                            items: 1
                                        },
                                        600: {
                                            items: 3
                                        },
                                        1000: {
                                            items: 4
                                        }
                                    }}
                                    autoplay
                                    autoplayTimeout={4000}
                                    smartSpeed={450}
                                >
                                    {
                                        speakers.map((element, index) => <div onClick={() => setSpeakerModel(element)} key={index} className="speaker-block col-12 wow fadeInUp">
                                            <div className="inner-box">
                                                <div className="image-box">
                                                    <figure className="image">
                                                        <a>
                                                            <img src={DOMAIN_URL + element.image} alt="" />
                                                        </a>
                                                    </figure>
                                                </div>
                                                <div className="info-box text-center">
                                                    <h4 className="name"><a>{element.name}</a></h4>
                                                    <span className="designation">{element.designation[auth.language]}</span>
                                                </div>
                                            </div>
                                        </div>)
                                    }
                                </OwlCarousel>
                            </div>
                        </div>
                    </div>
                </section> : null
            }

            <section className="speakers-section pt-3 pb-0">
                <div className="anim-icons full-width">
                    <span className="icon icon-dotted-circle"></span>
                </div>
                <div className="auto-container">
                    <div className="sec-title text-center" style={{ marginBottom: 10 }}>
                        <h2>{t('glimpses_title')}</h2>
                        <span className="divider"></span>
                    </div>
                    {
                        glimpsesVideos.length ? <div className="row">
                            <div className='col-12'>
                                <OwlCarousel
                                    className='owl-theme glimpses-slider'
                                    loop={false}
                                    margin={10}
                                    nav
                                    responsive={{
                                        0: {
                                            items: 1
                                        },
                                        600: {
                                            items: 3
                                        },
                                        1000: {
                                            items: 3
                                        }
                                    }}
                                >
                                    {
                                        glimpsesVideos.map((e, i) => <div key={i} className='item'>
                                            <video width="100%" height="240" controls>
                                                <source src={DOMAIN_URL + e} type="video/mp4" />
                                            </video>
                                        </div>)
                                    }

                                </OwlCarousel>
                            </div>
                        </div> : null
                    }

                </div>
            </section>
            {
                glimpsesImage.length ? <section className="speakers-section pt-3 pb-3">
                    <div className="auto-container">
                        <div className="row">
                            <div className='col-12'>
                                <OwlCarousel
                                    className='owl-theme glimpses-slider'
                                    loop={false}
                                    margin={10}
                                    nav
                                    responsive={{
                                        0: {
                                            items: 1
                                        },
                                        600: {
                                            items: 3
                                        },
                                        1000: {
                                            items: 3
                                        }
                                    }}
                                >
                                    {
                                        glimpsesImage.map((e, i) => <div key={i} className='item'>
                                            <img src={DOMAIN_URL + e} width="100%" />
                                        </div>)
                                    }
                                </OwlCarousel>
                            </div>
                        </div>
                    </div>
                </section> : null
            }
            <Modal
                open={speakerModel ? true : false}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={[modelBoxStyle, { width: '70%', overflow: 'auto', maxHeight: '100vh' }]} className="speaker-popup-box">
                    <div className='row'>
                        <div className='col-6'>
                            <h5>Details</h5>
                        </div>
                        <div className='col-6'>
                            <i style={{
                                position: 'absolute',
                                top: -15,
                                right: -15,
                                cursor: 'pointer',
                                padding: 10,
                            }} className='fa fa-times' onClick={() => setSpeakerModel(null)} />
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-12 mt-2 mb-3'>
                            <div style={{
                                float: 'left',
                                maxWidth: '30%',
                                textAlign: "center",
                                margin: ' 0px 12px 3px 0px',
                                border: '1px solid #f2f2f2',
                                padding: 20,
                            }}
                                className="speaker-popup">
                                <img src={DOMAIN_URL + speakerModel?.image} alt="" />
                                <div className='text-center'>
                                    <h5 className="name"><a>{speakerModel?.name}</a></h5>
                                    <span className="designation">{speakerModel?.designation[auth.language]}</span>
                                </div>
                            </div>
                            <p className="text-justify mb-3">
                                {/* <b>Brief about Presenter: </b> */}
                                {speakerModel?.description ? speakerModel?.description[auth.language] : ''}
                            </p>
                            {/* {
                                speakerModel?.session ? <p className="designation text-justify">
                                    <b>Session: </b>
                                    <span>{speakerModel?.session[auth.language]}</span>
                                </p> : null
                            }
                            {
                                speakerModel?.dateTime ? <p className="designation mt-3 text-justify">
                                    <b>Time: </b>
                                    <span>{moment(speakerModel?.dateTime).format('DD-MM-YYYY hh:mm A')}</span>
                                </p> : null
                            } */}
                        </div>
                    </div>
                </Box>
            </Modal>
        </>
    );
}

export default Home;
