

const TermsCondition = () => {
    return (
        <>
            <section className="speakers-section">
                <div className="anim-icons full-width">
                    <span className="icon icon-dotted-circle"></span>
                </div>
                <div className="auto-container">
                    <div className="row">
                        <div className="col-12">
                            <h2>Privacy Policy</h2>
                            <p className="mb-3 text-justify">This Web Portal is designed, developed and hosted by RPF, Ministry of Railways, Government of India.</p>
                            <p className="mb-3 text-justify">Though all efforts have been made to ensure the accuracy and currency of the content on this Portal, the same should not be construed as a statement of law or used for any legal purposes. We accept no responsibility in relation to the accuracy, completeness, usefulness or otherwise, of the contents. Users are advised to verify/ check any information with the relevant Government department(s) and /or other source(s), and obtain any appropriate professional advice before acting on the information provided in the Portal.</p>
                            <p className="mb-3 text-justify">In no event we will be liable for any expense, loss or damage including, without limitation, indirect or consequential loss or damage, or any expense, loss or damage whatsoever arising from use, or loss of use, of data, arising out of or in connection with the use of this portal.</p>
                            <p className="mb-3 text-justify">Links to other websites that have been included on this portal are provided for public convenience only. We will not be responsible for the contents or reliability of linked websites and does not necessarily endorse the view expressed within them. We cannot guarantee the availability of such linked pages at all times.</p>
                            <p className="mb-3 text-justify">Material featured on this portal may be reproduced free of charge after taking proper permission by sending a mail to us. However, the material has to be reproduced accurately and not to be used in a deregulatory manner or in a misleading context. Wherever the material is being published or issued to others, the source must be prominently acknowledged. However, the permission to reproduce this material shall not extend to any material which is identified as being copyright of a third party. Authorization to reproduce such material must be obtained from the departments/copyright holders concerned.</p>
                            <p className="mb-3 text-justify">These terms and conditions shall be governed by and construed in accordance with the Indian Laws. Any dispute arising under these terms and conditions shall be subject to the exclusive jurisdiction of the Courts of India.</p>
                            <p className="mb-3 text-justify">The application do not collect and use any kind of personal or sensitive data.</p>
                            <p className="mb-3 text-justify">For any inquiry contact details about privacy policy : aipdm2024@gmail.com </p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
export default TermsCondition;
