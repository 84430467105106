import { useEffect, useState } from 'react';
import { ThreeCircles } from 'react-loader-spinner';
import { confirmAlert } from 'react-confirm-alert';
import DataTable from 'react-data-table-component';
import { Box, Modal } from '@mui/material';
import { toast } from 'react-toastify';
import _ from 'lodash';

import { availableLanguages, modelBoxStyle } from '../../Helpers/Constants';
import { API } from '../../Helpers/ApiService';
import { RoutesPath } from "../../Routes";
import { URL } from '../../Helpers/URL';
import Language from '../../Component/Language';
import { ContentState, EditorState, convertFromHTML, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { Editor } from 'react-draft-wysiwyg';
import { useSelector } from 'react-redux';


const SupportDesk = () => {
    const fd = {
        name: {},
        content: {},
        user: null,
        state: null
    }
    availableLanguages.map(e => {
        fd.name[e] = '';
        fd.content[e] = '';
    });

    const columns = [
        {
            name: 'Name',
            center: true,
            selector: row => row.name?.en,
        },
        {
            name: 'User',
            center: true,
            selector: row => row.user?.firstName + ' ' + row.user?.surname,
        },
        {
            name: 'Organization',
            center: true,
            selector: row => row.state?.state?.en,
        },
        {
            name: 'Status',
            center: true,
            selector: row => row.isActive,
            cell: row => <StatusColumn row={row} />
        },
        {
            name: 'Action',
            center: true,
            cell: row => <ActionColumn row={row} />
        },
    ];

    const auth = useSelector(state => state.auth)

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [model, setModel] = useState(null);
    const [form, setForm] = useState(fd);
    const [formLoading, setFormLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [language, setLanguage] = useState('en');
    const [users, setUsers] = useState([]);
    const [states, setStates] = useState([]);
    const [editor, setEditor] = useState(EditorState.createEmpty());
    const [name, setName] = useState('');
    const [user, setUser] = useState('');
    const [state, setState] = useState('');

    useEffect(() => {
        setFormData('content', draftToHtml(convertToRaw(editor.getCurrentContent())));
    }, [editor]);

    useEffect(() => {
        setEditor(EditorState.createWithContent(
            ContentState.createFromBlockArray(
                convertFromHTML(form.content[language])
            )
        ));
    }, [language]);

    useEffect(() => {
        getData();
    }, [name, user, state]);

    useEffect(() => {
        getData();
    }, [perPage]);

    const getData = (page = 1) => {
        setLoading(true);
        API({
            url: URL.SUPPORT_DESK + '?page=' + page + '&limit=' + perPage + '&name=' + name + '&state=' + state + '&user=' + user,
            method: 'GET',
        }).then(res => {
            setLoading(false);
            if (res?.code) {
                return;
            }
            setTotalRows(res.totalResults);
            setData(res.results);
        });

        API({
            url: URL.GET_SUPPORT_USERS,
            method: 'GET',
        }).then(res => {
            setUsers(res);
        });

        API({
            url: URL.GET_STATES,
            method: 'GET',
        }).then(res => {
            setStates(res);
        });
    }

    const handleCreate = () => {
        setModel({
            mode: 'create',
        });
    }

    const handleEdit = (data) => {
        API({
            url: URL.SUPPORT_DESK + '/' + data?.id,
            method: 'GET',
        }).then(res => {
            if (res?.code) {
                toast.error(res.message)
                return;
            }
            setEditor(EditorState.createWithContent(
                ContentState.createFromBlockArray(
                    convertFromHTML(res.content[language])
                )
            ));
            let tempForm = _.cloneDeep(fd);
            tempForm.name = res.name;
            tempForm.content = res.content;
            tempForm.user = res.user;
            tempForm.state = res.state;
            setForm(tempForm);
            setModel({
                mode: 'edit',
                editId: data.id
            });
        });
    }

    const handleSave = () => {
        setFormLoading(true);
        var payload = _.cloneDeep(form);
        if (auth.value.role != 'admin') {
            delete payload?.user;
        }
        API({
            url: model?.mode == 'edit' ? URL.SUPPORT_DESK + '/' + model?.editId : URL.SUPPORT_DESK,
            method: model?.mode == 'edit' ? 'PATCH' : 'POST',
            data: payload
        }).then(res => {
            setFormLoading(false);
            if (res?.code) {
                toast.error(res.message)
                return;
            }
            resetForm();
        });

    }

    const StatusColumn = ({ row }) => {
        const handleStatusChange = (e) => {
            API({
                url: URL.SUPPORT_DESK + '/' + row.id,
                method: 'PATCH',
                data: { isActive: e.target.checked ? 'Active' : 'InActive' }
            }).then(res => {
                if (res?.code) {
                    toast.error(res.message)
                    return;
                }
                getData();
            });
        }
        return (
            <div className="form-group">
                <div className="custom-control custom-switch">
                    <input type="checkbox" className="custom-control-input" checked={row.isActive == 'Active' ? true : false} disabled={auth.value.role != 'admin'} id={row.id} onChange={handleStatusChange} />
                    <label className="custom-control-label" htmlFor={row.id}></label>
                </div>
            </div>
        );
    }

    const ActionColumn = ({ row }) => {
        const removeItem = () => {
            confirmAlert({
                title: 'Are you sure?',
                message: 'Want to delete item.',
                buttons: [
                    {
                        label: 'Yes',
                        onClick: () => API({
                            url: URL.SUPPORT_DESK + '/' + row?.id,
                            method: 'DELETE',
                        }).then(res => {
                            if (res?.code) {
                                toast.error(res.message)
                                return;
                            }
                            toast.success('Support desk deleted succesfully');
                            getData();
                        })
                    },
                    { label: 'No', }
                ]
            });

        }
        return (
            <>
                <button className='btn btn-info btn-sm' onClick={() => handleEdit(row)}>< i className='fa fa-edit' /></button>
                {/* {
                    auth.value.role == 'admin' ? <button className='ml-1 btn btn-danger btn-sm' onClick={removeItem}>< i className='fa fa-trash' /></button> : null
                } */}
            </>
        );
    }

    const setFormData = (name, value) => {
        let data = _.cloneDeep(form);
        if (name == 'user' || name == 'state') {
            data[name] = value;
        } else {
            data[name][language] = value;
        }
        setForm(data);
    }

    const resetForm = () => {
        setModel(null);
        setForm(fd);
        setLanguage('en');
        getData();
    }

    const uploadCallback = (file, callback) => {
        return new Promise((resolve, reject) => {
            const reader = new window.FileReader();
            reader.onloadend = async () => {
                const response = await API({
                    url: URL.UPLOAD,
                    method: 'POST',
                    data: { file },
                    formData: true
                });
                resolve({ data: { link: DOMAIN_URL + 'uploads/' + response[0].filename } });
            };
            reader.readAsDataURL(file);
        });
    }

    return (
        <>
            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="m-0">Support Desk</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><a href={RoutesPath.Admin.Dashboard}>Home</a></li>
                                <li className="breadcrumb-item active">Support Desk</li>
                            </ol>
                        </div>
                    </div>
                    <div className="row mb-2">
                        <div className="col-3">
                            <div className="form-group mt-1 mb-3 ">
                                <label>Search</label>
                                <input type="text" className="form-control" value={name} placeholder="Search" onChange={e => setName(e.target.value)} />
                            </div>
                        </div>
                        {
                            auth.value.role == 'admin' ? <>
                                <div className="col-3">
                                    <div className="form-group">
                                        <label>User</label>
                                        <select className='form-control' onChange={e => setUser(e.target.value)} value={user}>
                                            <option value={''}>Select user</option>
                                            {
                                                users.map((e, i) => <option value={e.id} key={i}>{e.firstName + ' ' + e.surname}</option>)
                                            }
                                        </select>
                                    </div>
                                </div>
                            </> : null
                        }
                        <div className="col-3">
                            <div className="form-group">
                                <label>Organization</label>
                                <select className='form-control' onChange={e => setState(e.target.value)} value={state}>
                                    <option value={''}>Select Organization </option>
                                    {
                                        states.map((e, i) => <option value={e.id} key={i}>{e.state.en}</option>)
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="col-3 d-flex align-items-center">
                            <button className='btn btn-info mt-3' onClick={() => {
                                setName('')
                                setUser('')
                                setState('')
                            }}>Clear Filter</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card">
                                <div className="card-header">
                                    <div className='row'>
                                        <div className='col-6 d-flex align-items-center'>
                                            <h3 className="card-title">Support Desk Listing</h3>
                                        </div>
                                        <div className='col-6 d-flex justify-content-end'>
                                            <button className='btn btn-primary' onClick={handleCreate}>
                                                Create
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className='row'>
                                        <div className='col-12'>
                                            <DataTable
                                                columns={columns}
                                                data={data}
                                                progressPending={loading}
                                                pagination
                                                paginationServer
                                                paginationRowsPerPageOptions={[10, 25, 50, 100, 200]}
                                                paginationTotalRows={totalRows}
                                                onChangeRowsPerPage={(newPerPage, page) => { setPerPage(newPerPage); getData(page); }}
                                                onChangePage={page => getData(page)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal
                open={model ? true : false}
                onClose={() => resetForm()}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={modelBoxStyle}>
                    <div className='row'>
                        <div className='col-6'>
                            <h5>{model?.mode == 'create' ? 'Create' : 'Edit'}</h5>
                        </div>
                        <div className='col-6'>
                            <i style={{
                                position: 'absolute',
                                top: -15,
                                right: -15,
                                cursor: 'pointer',
                                padding: 10,
                            }} className='fa fa-times' onClick={() => resetForm()} />
                        </div>
                    </div>
                    <Language lang={language} onChange={lang => setLanguage(lang)} />
                    <div className='row'>
                        <div className='col-12'>
                            <div className="form-group mt-3 mb-3 ">
                                <input type="text" className="form-control" value={form?.name[language]} placeholder="Enter name" onChange={e => setFormData('name', e.target.value)} />
                            </div>
                        </div>
                        {
                            auth.value.role == 'admin' ? <>
                                <div className='col-6'>
                                    <div className="form-group mt-3 mb-3 ">
                                        <select className='form-control' onChange={e => setFormData('user', e.target.value)} value={form?.user ?? ''}>
                                            <option value={''}>Select user</option>
                                            {
                                                users.map((e, i) => <option value={e.id} key={i}>{e.firstName + ' ' + e.surname}</option>)
                                            }
                                        </select>
                                    </div>
                                </div>
                            </> : null
                        }
                        <div className={`col-${auth.value.role == 'admin' ? "6" : '12'}`}>
                            <div className="form-group mt-3 mb-3 ">
                                <select className='form-control' onChange={e => setFormData('state', e.target.value)} value={form?.state ?? ''}>
                                    <option value={''}>Select Organization </option>
                                    {
                                        states.map((e, i) => <option value={e.id} key={i}>{e.state.en}</option>)
                                    }
                                </select>
                            </div>
                        </div>
                        <div className='col-12'>
                            <div className="form-group mt-3 mb-3 ">
                                <Editor
                                    toolbar={{
                                        image: {
                                            uploadCallback: uploadCallback,
                                            previewImage: true,
                                            inputAccept: 'image/*',
                                        },
                                    }}
                                    editorState={editor}
                                    onEditorStateChange={setEditor}
                                    wrapperClassName="wrapper-class"
                                    editorClassName="editor-class"
                                    toolbarClassName="toolbar-class"
                                />
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-12 d-flex justify-content-end'>
                            <button type='button' className='btn btn-sm btn-secondary' onClick={() => resetForm()}>Cancel</button>
                            <button type='button' onClick={handleSave} className='btn btn-sm btn-success ml-1'>Save</button>
                        </div>
                    </div>
                    {
                        formLoading ? <div className='model-overlay'>
                            <ThreeCircles
                                height="100"
                                width="100"
                                color="#4fa94d"
                                wrapperStyle={{}}
                                wrapperClass=""
                                visible={true}
                                ariaLabel="three-circles-rotating"
                                outerCircleColor=""
                                innerCircleColor=""
                                middleCircleColor=""
                            />
                        </div> : null
                    }

                </Box>
            </Modal>
        </>
    );
}

export default SupportDesk;
