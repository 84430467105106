import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import OwlCarousel from 'react-owl-carousel';
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Box, Modal } from '@mui/material';

import BGImage11 from '../assets/fe/images/menubg.png';

import DataImage1 from '../assets/fe/images/data/1.jpg';
import DataImage2 from '../assets/fe/images/data/2.jpg';
import DataImage3 from '../assets/fe/images/data/3.jpg';
import DataImage4 from '../assets/fe/images/data/4.jpg';
import DataImage5 from '../assets/fe/images/data/5.jpg';
import DataImage6 from '../assets/fe/images/data/6.jpg';
import DataImage7 from '../assets/fe/images/data/7.jpg';
import DataImage8 from '../assets/fe/images/data/8.jpg';
import DataImage9 from '../assets/fe/images/data/9.jpg';

import GalleryImage1 from '../assets/fe/images/gallary/1.png';
import GalleryImage2 from '../assets/fe/images/gallary/2.png';
import GalleryImage3 from '../assets/fe/images/gallary/3.png';
import GalleryImage4 from '../assets/fe/images/gallary/4.png';
import GalleryImage5 from '../assets/fe/images/gallary/5.png';
import GalleryImage6 from '../assets/fe/images/gallary/6.png';
import GalleryImage7 from '../assets/fe/images/gallary/7.png';
import GalleryImage8 from '../assets/fe/images/gallary/8.png';
import GalleryImage9 from '../assets/fe/images/gallary/9.png';
import GalleryImage10 from '../assets/fe/images/gallary/10.png';
import GalleryImage11 from '../assets/fe/images/gallary/11.png';
import GalleryImage12 from '../assets/fe/images/gallary/12.png';
import GalleryImage13 from '../assets/fe/images/gallary/13.png';
import GalleryImage14 from '../assets/fe/images/gallary/14.png';
import GalleryImage15 from '../assets/fe/images/gallary/15.png';
import GalleryImage16 from '../assets/fe/images/gallary/16.png';
import GalleryImage17 from '../assets/fe/images/gallary/17.png';

import { API } from "../Helpers/ApiService";
import { DOMAIN_URL, modelBoxStyle } from "../Helpers/Constants";
import { URL } from "../Helpers/URL";
import { RoutesPath } from "../Routes";
import moment from "moment";

const Speakers = () => {
    const { t, i18n } = useTranslation();
    const auth = useSelector(state => state.auth);

    const [speakers, setSpeakers] = useState([]);
    const [speakerModel, setSpeakerModel] = useState(null);

    useEffect(() => {
        API({
            url: URL.GET_SPEAKERS,
            method: 'GET',
        }).then(res => {
            if (res?.code) {
                return;
            }
            setSpeakers(res)
        });
    }, []);
    return (
        <>
            <section className="page-title" style={{ backgroundImage: "url(" + BGImage11 + ")" }} >
                <div className="anim-icons full-width">
                    <span className="icon icon-bull-eye"></span>
                    <span className="icon icon-dotted-circle"></span>
                </div>
                <div className="auto-container">
                    <div className="title-outer">
                        <h1>{t('our_speaker')}</h1>
                    </div>
                </div>
            </section>
            {
                speakers.length ? <section className="speakers-section">
                    <div className="anim-icons full-width">
                        <span className="icon icon-dotted-circle"></span>
                    </div>
                    <div className="auto-container">
                        <div className="sec-title text-center">
                            <h2>
                                {t('data_stats')}
                            </h2>
                            <span className="divider"></span>
                        </div>
                        <div className="row">
                            <OwlCarousel
                                className='owl-theme home-slider'
                                loop
                                margin={10}
                                items={1}
                                nav={true}
                                autoplay
                                autoplayTimeout={10000}
                                animateOut={'fadeOut'}
                                animateIn={'fadeIn'}
                                smartSpeed={7000}
                            >
                                {
                                    [DataImage1, DataImage2, DataImage3, DataImage4, DataImage5, DataImage6, DataImage7, DataImage8, DataImage9].map((e, i) => <div key={i} className='item'>
                                        <img className='img-responsive' src={e} />
                                    </div>)
                                }
                            </OwlCarousel>


                        </div>
                        <div className="sec-title text-center">
                            <h2>
                                {t('gallary')}
                            </h2>
                            <span className="divider"></span>
                        </div>
                        <div className="row">
                            <OwlCarousel
                                className='owl-theme home-slider'
                                loop
                                margin={10}
                                items={1}
                                nav={true}
                                autoplay
                                autoplayTimeout={10000}
                                animateOut={'fadeOut'}
                                animateIn={'fadeIn'}
                                smartSpeed={7000}
                            >
                                {
                                    [GalleryImage1, GalleryImage2, GalleryImage3, GalleryImage4, GalleryImage5, GalleryImage6, GalleryImage7, GalleryImage8, GalleryImage9, GalleryImage10, GalleryImage11, GalleryImage12, GalleryImage14, GalleryImage15, GalleryImage16, GalleryImage17].map((e, i) => <div key={i} className='item'>
                                        <img className='img-responsive' src={e} />
                                    </div>)
                                }
                            </OwlCarousel>


                        </div>
                    </div>
                </section> : null
            }

        </>
    );
}
export default Speakers;
