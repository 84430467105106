import { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import _ from 'lodash';

import { API } from '../../Helpers/ApiService';
import { RoutesPath } from "../../Routes";
import { URL } from '../../Helpers/URL';


const Supports = () => {
    const columns = [
        {
            name: 'Name',
            center: true,
            selector: row => row.name,
        },
        {
            name: 'Email',
            center: true,
            selector: row => row.email,
        },
        {
            name: 'Phone',
            center: true,
            selector: row => row?.phone,
        },
        {
            name: 'Message',
            center: true,
            selector: row => row.message,
        },
        // {
        //     name: 'Support Sevice',
        //     center: true,
        //     selector: row => row.addons,
        //     cell: row => <>
        //         {
        //             row.addons.length ? <table border="1" width={'100%'}><tbody>
        //                 <tr >
        //                     <th>Support</th>
        //                     <th className='text-right'>Other Details</th>
        //                 </tr>
        //                 {
        //                     row.addons.map((e, i) => <tr key={i}>
        //                         <td >{e.addonData?.addon?.en ?? '-'}</td>
        //                         <td className='text-right'>{e.otherDetails ?? '-'}</td>
        //                     </tr>)
        //                 }
        //             </tbody></table> : '-'
        //         }
        //     </>
        // },
    ];
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);

    useEffect(() => {
        getData();
    }, []);

    const getData = (page = 1) => {
        setLoading(true);
        API({
            url: URL.SUPPORT + '?page=' + page + '&limit=' + perPage,
            method: 'GET',
        }).then(res => {
            setLoading(false);
            if (res?.code) {
                return;
            }
            setTotalRows(res.totalResults);
            setData(res.results);
        });
    }



    return (
        <>
            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="m-0">Supports</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><a href={RoutesPath.Admin.Dashboard}>Home</a></li>
                                <li className="breadcrumb-item active">Supports</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>

            <div className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card">
                                <div className="card-header">
                                    <div className='row'>
                                        <div className='col-6 d-flex align-items-center'>
                                            <h3 className="card-title">Supports Listing</h3>
                                        </div>
                                        <div className='col-6 d-flex justify-content-end'>

                                        </div>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className='row'>
                                        <div className='col-12'>
                                            <DataTable
                                                columns={columns}
                                                data={data}
                                                progressPending={loading}
                                                pagination
                                                paginationServer
                                                paginationTotalRows={totalRows}
                                                onChangeRowsPerPage={(newPerPage, page) => { setPerPage(newPerPage); getData(page); }}
                                                onChangePage={page => getData(page)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Supports;
