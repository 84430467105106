import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import EN from "./locales/en.json";
import HI from "./locales/hi.json";
import DE from "./locales/de.json";
import ES from "./locales/es.json";
import FR from "./locales/fr.json";
import IT from "./locales/it.json";

const resources = {
    en: { translation: EN },
    hi: { translation: HI },
    de: { translation: DE },
    es: { translation: ES },
    fr: { translation: FR },
    it: { translation: IT },
};

i18n.use(initReactI18next).init({
    resources,
    lng: "en",
    fallbackLng: 'en',
    keySeparator: false,
    interpolation: {
        escapeValue: false,
    },
});

export default i18n;