
export const RoutesPath = {
    Home: { path: "/" },
    Login: { path: "/login" },
    Register: { path: "/register" },
    Account: { path: "/account" },
    AboutInner: { path: "/about/:id" },
    AgendaInner: { path: "/agenda/:id" },
    SupportInner: { path: "/support-service/:id" },
    CulturalProgram: { path: "/cultural-program/:id" },
    TourismHeritage: { path: "/tourism-heritage/:id" },
    Speakers: { path: "/past-state-records" },
    Contact: { path: "/contact" },
    TermAndCondition: { path: "/terms-condition" },
    PrivacyPolicy: { path: "/privacy-and-policy" },
    Schedule: { path: "/schedule" },
    LiveEvents: { path: "/live-events" },
    Result: { path: "/result" },
    EventDetails: { path: "/event/:id" },
    ForgotPassword: { path: "/forgot-password" },
    ResetPassword: { path: "/reset-password/:token" },
    FeedbackFormFront: { path: "/feedback/:id" },
    Glimpse: { path: "/glimpse/:id" },
    InformationDesk: { path: "/information-desk/:id" },
    MediaCoverage: { path: "/media-coverage/:id" },

    Admin: { path: "/backoffice" },
    Dashboard: { path: "/backoffice/dashboard" },
    Country: { path: "/backoffice/countries" },
    Domain: { path: "/backoffice/domains" },
    Agency: { path: "/backoffice/agencies" },
    Designation: { path: "/backoffice/designations" },
    Department: { path: "/backoffice/departments" },
    CMSPages: { path: "/backoffice/cms-pages" },
    Speaker: { path: "/backoffice/past-state-records" },
    Addon: { path: "/backoffice/addon" },
    Language: { path: "/backoffice/language" },
    Users: { path: "/backoffice/users" },
    Supports: { path: "/backoffice/support" },
    Notification: { path: "/backoffice/notification" },
    Setting: { path: "/backoffice/setting" },
    Glimpses: { path: "/backoffice/glimpses" },
    Download: { path: "/backoffice/download" },
    Feedbacks: { path: "/backoffice/feedbacks" },
    FeedbackReport: { path: "/backoffice/feedback-report" },
    FeedbackReportDetails: { path: "/backoffice/feedback-report-details/:formId" },
    EventSummaryReport: { path: "/backoffice/event-summary-report" },
    Rank: { path: "/backoffice/rank" },
    State: { path: "/backoffice/state" },
    Category: { path: "/backoffice/category" },
    Event: { path: "/backoffice/event" },
    PaymentReceipt: { path: "/backoffice/payment-receipt" },
    SupportDesk: { path: "/backoffice/support-desk" },
    UserSupportDesk: { path: "/backoffice/support-desk-single" },
    Upload: { path: "/backoffice/upload" },
    JudgesEvent: { path: "/backoffice/judges/event" },

    NotFound: { path: "/404" },
};