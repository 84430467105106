import { useEffect, useState } from 'react';
import { ThreeCircles } from 'react-loader-spinner';
import { confirmAlert } from 'react-confirm-alert';
import DataTable from 'react-data-table-component';
import { Box, Modal } from '@mui/material';
import { toast } from 'react-toastify';
import _ from 'lodash';

import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import { DOMAIN_URL, availableLanguages, modelBoxStyle } from '../../Helpers/Constants';
import { API } from '../../Helpers/ApiService';
import { RoutesPath } from "../../Routes";
import { URL } from '../../Helpers/URL';
import Language from '../../Component/Language';
import { ContentState, EditorState, convertFromHTML, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { Editor } from 'react-draft-wysiwyg';
import moment from 'moment';
import Multiselect from 'multiselect-react-dropdown';
import { useSelector } from 'react-redux';


const Event = () => {
    const fd = {
        category: null,
        name: {},
        description: {},
        images: [],
        date: '',
        fee: '',
        status: '',
        terms: [''],
        judges: [],
    }
    availableLanguages.map(e => {
        fd.name[e] = '';
        fd.description[e] = '';
    });

    const columns = [
        {
            name: 'Category',
            center: true,
            selector: row => row?.category?.category[availableLanguages[0]],
        },
        {
            name: 'Name',
            center: true,
            selector: row => row?.name[availableLanguages[0]],
        },
        {
            name: 'Fee',
            center: true,
            selector: row => row.fee,
        },
        {
            name: 'Status',
            center: true,
            selector: row => row.status,
        },
        {
            name: 'isActive',
            center: true,
            selector: row => row.isActive,
            cell: row => <StatusColumn row={row} />
        },
        {
            name: 'Action',
            center: true,
            cell: row => <ActionColumn row={row} />
        },
    ];

    const auth = useSelector(state => state.auth);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [model, setModel] = useState(null);
    const [certModel, setCertModel] = useState(null);
    const [imageModel, setImageModel] = useState(null);
    const [winnerModel, setWinnerModel] = useState(null);
    const [form, setForm] = useState(fd);
    const [formLoading, setFormLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [language, setLanguage] = useState('en');
    const [categories, setCategories] = useState([]);
    const [editor, setEditor] = useState(EditorState.createEmpty());
    const [judges, setJudges] = useState([]);
    const [eventUsers, setEventUsers] = useState([]);

    useEffect(() => {
        getData();
    }, []);

    useEffect(() => {
        getData();
    }, [perPage]);

    useEffect(() => {
        setFormData('description', draftToHtml(convertToRaw(editor.getCurrentContent())));
    }, [editor]);

    useEffect(() => {
        setEditor(EditorState.createWithContent(
            ContentState.createFromBlockArray(
                convertFromHTML(form.description[language])
            )
        ));
    }, [language]);

    const getData = (page = 1) => {
        setLoading(true);
        API({
            url: URL.EVENT + '?page=' + page + '&limit=' + perPage,
            method: 'GET',
        }).then(res => {
            setLoading(false);
            if (res?.code) {
                return;
            }
            setTotalRows(res.totalResults);
            setData(res.results);
        });

        API({
            url: URL.CATEGORY_LIST,
            method: 'GET',
        }).then(res => {
            setCategories(res)
        });

        API({
            url: URL.GET_JUDGES_USER,
            method: 'GET',
        }).then(res => {
            setJudges(res)
        });
    }

    const handleCreate = () => {
        setModel({
            mode: 'create',
        });
    }

    const handleEdit = (data) => {
        API({
            url: URL.EVENT + '/' + data?.id,
            method: 'GET',
        }).then(res => {
            if (res?.code) {
                toast.error(res.message)
                return;
            }
            let tempForm = _.cloneDeep(fd);
            setEditor(EditorState.createWithContent(
                ContentState.createFromBlockArray(
                    convertFromHTML(res.description[language])
                )
            ));
            tempForm.category = res.category;
            tempForm.name = res.name;
            tempForm.images = res.images;
            tempForm.status = res.status;
            tempForm.fee = res.fee;
            tempForm.judges = judges.filter(e => res.judges.includes(e.id));
            tempForm.terms = res.terms?.length ? res.terms : [''];
            tempForm.date = moment(res.date).format('YYYY-MM-DD');
            setForm(tempForm);
            setModel({
                mode: 'edit',
                editId: data.id
            });
        });
    }

    const handleSave = () => {
        var payload = _.cloneDeep(form);
        payload.judges = payload.judges.map(e => { return e.id });

        setFormLoading(true);
        API({
            url: model?.mode == 'edit' ? URL.EVENT + '/' + model?.editId : URL.EVENT,
            method: model?.mode == 'edit' ? 'PATCH' : 'POST',
            data: payload
        }).then(res => {
            setFormLoading(false);
            if (res?.code) {
                toast.error(res.message)
                return;
            }
            resetForm();
        });

    }

    const StatusColumn = ({ row }) => {
        const handleStatusChange = (e) => {
            API({
                url: URL.EVENT + '/' + row.id,
                method: 'PATCH',
                data: { isActive: e.target.checked ? 'Active' : 'InActive' }
            }).then(res => {
                if (res?.code) {
                    toast.error(res.message)
                    return;
                }
                getData();
            });
        }
        return (
            <div className="form-group">
                <div className="custom-control custom-switch">
                    <input type="checkbox" className="custom-control-input" checked={row.isActive == 'Active' ? true : false} id={row.id} onChange={handleStatusChange} />
                    <label className="custom-control-label" htmlFor={row.id}></label>
                </div>
            </div>
        );
    }

    const ActionColumn = ({ row }) => {
        const removeItem = () => {
            confirmAlert({
                title: 'Are you sure?',
                message: 'Want to delete item.',
                buttons: [
                    {
                        label: 'Yes',
                        onClick: () => API({
                            url: URL.EVENT + '/' + row?.id,
                            method: 'DELETE',
                        }).then(res => {
                            if (res?.code) {
                                toast.error(res.message)
                                return;
                            }
                            toast.success('Event deleted successfully');
                            getData();
                        })
                    },
                    { label: 'No', }
                ]
            });

        }
        return (
            <>
                {
                    auth?.value?.role == "admin" && row.judgeDocument?.length ? <button className='ml-1 btn btn-info btn-sm mr-1' title='Judge Certificates' onClick={() => setCertModel(row)}>< i className='fa fa-list' /></button> : null
                }
                {
                    auth?.value?.role == "admin" && row.status == 'Completed' ? <button className='ml-1 btn btn-info btn-sm mr-1' title='Choose Winner' onClick={() => {

                        API({
                            url: URL.EVENT_USERS + row.id,
                            method: 'GET',
                        }).then(res => {
                            setEventUsers(res)

                            if (!row?.winners?.length) {
                                row['winners'] = [
                                    {
                                        users: [],
                                        score: '',
                                        position: '1'
                                    }
                                ]
                            } else {
                                row?.winners?.map((e, i) => {
                                    row.winners[i].users = res.filter(eu => e.users.includes(eu.id));
                                });
                            }
                            setWinnerModel(row)
                        });
                    }}>< i className='fa fa-trophy' /></button> : null
                }
                <button className='btn btn-info btn-sm' onClick={() => handleEdit(row)}>< i className='fa fa-edit' /></button>
                {/* <button className='ml-1 btn btn-danger btn-sm' onClick={removeItem}>< i className='fa fa-trash' /></button> */}
            </>
        );
    }

    const setFormData = (name, value) => {
        let data = _.cloneDeep(form);
        if (['name', 'description'].includes(name)) {
            data[name][language] = value;
        } else {
            data[name] = value;
        }
        setForm(data);
    }

    const resetForm = () => {
        setModel(null);
        setForm(fd);
        setLanguage('en');
        getData();
    }

    const onChangeFile = (e, name) => {
        let files = e.target.files;
        if (!files.length) {
            return;
        }
        setFormLoading(true);
        let payload = {};
        for (let index = 0; index < files.length; index++) {
            payload['file' + index] = files[index];
        }
        API({
            url: URL.UPLOAD,
            method: 'POST',
            data: payload,
            formData: true
        }).then(res => {
            e.target.value = null;
            setFormLoading(false);
            if (res?.code) {
                toast.error(res.message);
                return;
            }
            let data = _.cloneDeep(form);
            res.map(e => data['images'].push({ image: 'uploads/' + e.filename, caption: '' }));
            setForm(data);
        });
    }

    const removeImage = (index, name) => {
        let data = _.cloneDeep(form);
        data.images.splice(index, 1);
        setForm(data);
    }

    const uploadCallback = (file, callback) => {
        return new Promise((resolve, reject) => {
            const reader = new window.FileReader();
            reader.onloadend = async () => {
                const response = await API({
                    url: URL.UPLOAD,
                    method: 'POST',
                    data: { file },
                    formData: true
                });
                resolve({ data: { link: DOMAIN_URL + 'uploads/' + response[0].filename } });
            };
            reader.readAsDataURL(file);
        });
    }

    const addCaption = (index) => {
        let data = _.cloneDeep(form);
        setImageModel({
            index: index,
            caption: data.images[index].caption
        });
    }

    const completeEvent = (e) => {
        API({
            url: URL.COMPLETE_EVENT + certModel?.id,
            method: 'POST',
            data: certModel.judgeDocument
        }).then(res => {
            e.target.value = null;
            if (res?.code) {
                toast.error(res.message);
                return;
            }
            toast.success('Event marked completed')
            getData();
            setCertModel(null);
        });
    }

    const getFileName = (name) => {
        const parts = name.split('/');
        return parts[parts?.length - 1]
    }

    const setWinnerData = (index, name, value) => {
        let data = _.cloneDeep(winnerModel);
        data.winners[index][name] = value;
        setWinnerModel(data);
    }

    const saveWinner = () => {
        var payload = _.cloneDeep(winnerModel.winners);
        payload.map((e, i) => {
            payload[i].users = e.users.map(e => { return e.id });
        });

        API({
            url: URL.SAVE_WINNERS_EVENT + winnerModel?.id,
            method: 'POST',
            data: { winners: payload }
        }).then(res => {
            if (res?.code) {
                toast.error(res.message);
                return;
            }
            toast.success('Winner saved successfully!')
            setWinnerModel(null);
            getData();
        });
    }


    return (
        <>
            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="m-0">Event</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><a href={RoutesPath.Admin.Dashboard}>Home</a></li>
                                <li className="breadcrumb-item active">Event</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>

            <div className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card">
                                <div className="card-header">
                                    <div className='row'>
                                        <div className='col-6 d-flex align-items-center'>
                                            <h3 className="card-title">Event Listing</h3>
                                        </div>
                                        <div className='col-6 d-flex justify-content-end'>
                                            <button className='btn btn-primary' onClick={handleCreate}>
                                                Create
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className='row'>
                                        <div className='col-12'>
                                            <DataTable
                                                columns={columns}
                                                data={data}
                                                progressPending={loading}
                                                pagination
                                                paginationServer
                                                paginationRowsPerPageOptions={[10, 25, 50, 100, 200]}
                                                paginationTotalRows={totalRows}
                                                onChangeRowsPerPage={(newPerPage, page) => { setPerPage(newPerPage); getData(page); }}
                                                onChangePage={page => getData(page)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal
                open={model ? true : false}
                onClose={() => resetForm()}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={modelBoxStyle}>
                    <div className='row'>
                        <div className='col-6'>
                            <h5>{model?.mode == 'create' ? 'Create' : 'Edit'}</h5>
                        </div>
                        <div className='col-6'>
                            <i style={{
                                position: 'absolute',
                                top: -15,
                                right: -15,
                                cursor: 'pointer',
                                padding: 10,
                            }} className='fa fa-times' onClick={() => resetForm()} />
                        </div>
                    </div>
                    <Language lang={language} onChange={lang => setLanguage(lang)} />
                    <div className='row'>
                        <div className='col-6'>
                            <div className="form-group mt-3 mb-3 ">
                                <select className='form-control' onChange={e => setFormData('category', e.target.value)} value={form?.category ?? ''}>
                                    <option value={''} disabled>Select Category</option>
                                    {
                                        categories.map((e, i) => <option key={i} value={e.id}>{e.category[availableLanguages[0]]}</option>)
                                    }
                                </select>
                            </div>
                        </div>
                        <div className='col-6'>
                            <div className="form-group mt-3 mb-3 ">
                                <select className='form-control' onChange={e => setFormData('status', e.target.value)} value={form?.status ?? ''}>
                                    <option value={''} disabled>Select Status</option>
                                    <option value={'Scheduled'}>Scheduled</option>
                                    <option value={'Live'}>Live</option>
                                    <option value={'Completed'}>Completed</option>
                                </select>
                            </div>
                        </div>
                        <div className='col-6'>
                            <div className="form-group mt-3 mb-3 ">
                                <input type="text" className="form-control" value={form.name[language]} placeholder="Enter name" onChange={e => setFormData('name', e.target.value)} />
                            </div>
                        </div>
                        <div className='col-6'>
                            <div className="form-group mt-3 mb-3 ">
                                <input type="text" className="form-control" value={form.fee} placeholder="Enter fee" onChange={e => setFormData('fee', e.target.value)} />
                            </div>
                        </div>
                        <div className='col-6'>
                            <div className="form-group mt-3 mb-3 ">
                                <input type="date" className="form-control" value={form.date} placeholder="Select Date" onChange={e => setFormData('date', e.target.value)} />
                            </div>
                        </div>
                        <div className='col-6'>
                            <div className="form-group mt-3 mb-3 ">
                                <Multiselect
                                    className="register-input"
                                    selectedValues={form.judges}
                                    options={judges}
                                    onSelect={(list) => setFormData('judges', list)}
                                    onRemove={(list) => setFormData('judges', list)}
                                    displayValue="name"
                                />
                            </div>
                        </div>
                        <div className='col-12'>
                            <div className="form-group mt-3 mb-3 ">
                                <Editor
                                    toolbar={{
                                        image: {
                                            uploadCallback: uploadCallback,
                                            previewImage: true,
                                            inputAccept: 'image/*',
                                        },
                                    }}
                                    editorState={editor}
                                    onEditorStateChange={setEditor}
                                    wrapperClassName="wrapper-class"
                                    editorClassName="editor-class"
                                    toolbarClassName="toolbar-class"
                                />
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-12 mb-2'>
                            <div className='row'>
                                <div className='col-6'>
                                    <h5>Images</h5>
                                </div>
                                <div className='col-6'>
                                    <input type="file" accept='image/*' onChange={e => onChangeFile(e, 'slider')} style={{ marginTop: 3 }} multiple />
                                </div>
                            </div>
                        </div>
                        {
                            form.images.map((e, i) => <div key={i} className='col-3'>
                                <i style={{
                                    position: 'absolute',
                                    top: 10,
                                    right: 10,
                                    padding: 10,
                                    cursor: 'pointer',
                                }} className="fa fa-times" onClick={() => removeImage(i, 'slider')} />
                                <i style={{
                                    position: 'absolute',
                                    top: 10,
                                    left: 10,
                                    padding: 10,
                                    cursor: 'pointer',
                                }} className="fa fa-edit" onClick={() => addCaption(i)} />
                                <img src={DOMAIN_URL + e.image} className="img-responsive mt-2 mb-2" style={{ width: '100%' }} />
                                {e?.caption}
                            </div>)
                        }

                    </div>
                    <div className='row'>
                        <div className='col-12 mt-3 mb-3'>
                            {
                                form?.terms?.map((e, i) => <div key={i} className='row mt-2'>
                                    <div className='col-11'>
                                        <input type="text" className="form-control" value={form.terms[i]} placeholder="Enter term" onChange={e => {
                                            let data = _.cloneDeep(form);
                                            data.terms[i] = e.target.value;
                                            setForm(data);
                                        }} />
                                    </div>
                                    <div className='col-1 text-right'>
                                        {
                                            i == (form?.terms?.length - 1) ? <button type='button' className='btn btn-success' onClick={() => {
                                                let data = _.cloneDeep(form);
                                                data.terms.push('');
                                                setForm(data);
                                            }}>+</button> : <button type='button' className='btn btn-danger pl-3' onClick={() => {
                                                let data = _.cloneDeep(form);
                                                data.terms.splice(i, 1);
                                                setForm(data);
                                            }}>-</button>
                                        }
                                    </div>
                                </div>)
                            }
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-12 d-flex justify-content-end'>
                            <button type='button' className='btn btn-sm btn-secondary' onClick={() => resetForm()}>Cancel</button>
                            <button type='button' onClick={handleSave} className='btn btn-sm btn-success ml-1'>Save</button>
                        </div>
                    </div>
                    {
                        formLoading ? <div className='model-overlay'>
                            <ThreeCircles
                                height="100"
                                width="100"
                                color="#4fa94d"
                                wrapperStyle={{}}
                                wrapperClass=""
                                visible={true}
                                ariaLabel="three-circles-rotating"
                                outerCircleColor=""
                                innerCircleColor=""
                                middleCircleColor=""
                            />
                        </div> : null
                    }

                </Box>
            </Modal>
            <Modal
                open={imageModel ? true : false}
                onClose={() => {
                    setImageModel(null)
                }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={modelBoxStyle}>
                    <div className='row'>
                        <div className='col-12'>
                            <div className="form-group mt-3 mb-3 ">
                                <input type="text" className="form-control" value={imageModel?.caption ?? ''} placeholder="Enter caption" onChange={e => {
                                    let data = _.cloneDeep(imageModel);
                                    data.caption = e.target.value;
                                    setImageModel(data);
                                }} />
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-12 d-flex justify-content-center'>
                            <button type='button' className='btn btn-sm btn-secondary' onClick={() => {
                                setImageModel(null)
                            }}>Cancel</button>
                            <button type='button' onClick={() => {
                                let data = _.cloneDeep(form);
                                data.images[imageModel.index].caption = imageModel.caption;
                                setForm(data);
                                setImageModel(null)
                            }} className='btn btn-sm btn-success ml-1'>Save</button>
                        </div>
                    </div>
                </Box>
            </Modal>
            <Modal
                open={certModel ? true : false}
                // onClose={() => resetForm()}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={modelBoxStyle}>
                    <div className='row'>
                        <div className='col-12 mb-5'>
                            <h5>Judges Uploaded Result</h5>
                        </div>
                        <div className='col-12'>
                            {
                                certModel?.judgeDocument?.length ? <div>
                                    <div className='col-12'>
                                        <span>Show</span>
                                    </div>
                                    {
                                        certModel?.judgeDocument?.map((e, i) => <div key={i} className='row'>
                                            <div className='col-12 justify-content-between d-flex mt-2'>
                                                <div>
                                                    <input type='checkbox' checked={e?.show} className='ml-3 mr-3' onChange={(e) => {
                                                        let data = _.cloneDeep(certModel);
                                                        data.judgeDocument[i].show = e.target.checked;
                                                        setCertModel(data);
                                                    }} />
                                                    {getFileName(e.file)}
                                                </div>
                                                <a target='_blank' className='btn btn-info btn-sm' href={DOMAIN_URL + e.file}>View</a>
                                            </div>
                                        </div>)
                                    }
                                </div> : null
                            }
                        </div>
                        <div className='col-12 text-right mt-5'>
                            <button className='btn btn-success mr-2' onClick={completeEvent}>Save</button>
                            <button className='btn btn-primary' onClick={() => setCertModel(null)}>Close</button>
                        </div>
                    </div>
                </Box>
            </Modal>
            <Modal
                open={winnerModel ? true : false}
                onClose={() => {
                    getData();
                    setWinnerModel(null);
                }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={modelBoxStyle}>
                    <div className='row'>
                        <div className='col-12 mb-5'>
                            <h5>Choose Winners</h5>
                        </div>
                        <div className='col-12'>
                            {
                                winnerModel?.winners?.map((e, i) => <div key={i} className='row'>
                                    <div className='col-1'>
                                        <label>Position</label>
                                        <input type="text" className="form-control" value={e.position} min={1} placeholder="Position" onChange={e => setWinnerData(i, 'position', e.target.value)} />
                                    </div>
                                    <div className='col-5'>
                                        <label>Users</label>
                                        <Multiselect
                                            className="register-input"
                                            selectedValues={e.users}
                                            options={eventUsers}
                                            onSelect={(list) => setWinnerData(i, 'users', list)}
                                            onRemove={(list) => setWinnerData(i, 'users', list)}
                                            displayValue="name"
                                        />
                                    </div>
                                    <div className='col-5'>
                                        <label>Score</label>
                                        <input type="text" className="form-control" value={e.score} placeholder="Score" onChange={e => setWinnerData(i, 'score', e.target.value)} />
                                    </div>
                                    <div className='col-1 text-right d-flex align-items-center justify-content-end'>
                                        {
                                            i == (winnerModel?.winners?.length - 1) ? <button type='button' className='btn btn-success' onClick={() => {
                                                let data = _.cloneDeep(winnerModel);
                                                data.winners.push({
                                                    users: [],
                                                    score: '',
                                                    position: '1'
                                                });
                                                setWinnerModel(data);
                                            }}>+</button> : <button type='button' className='btn btn-danger pl-3' onClick={() => {
                                                let data = _.cloneDeep(winnerModel);
                                                data.winners.splice(i, 1);
                                                setWinnerModel(data);
                                            }}>-</button>
                                        }
                                    </div>
                                </div>)
                            }
                        </div>
                        <div className='col-12 text-right mt-5'>
                            <button className='btn btn-success mr-2' onClick={saveWinner}>Save</button>
                            <button className='btn btn-secondary' onClick={() => {
                                getData();
                                setWinnerModel(null);
                            }}>Close</button>
                        </div>
                    </div>
                </Box>
            </Modal>
        </>
    );
}

export default Event;
