import { useEffect, useState } from 'react';
import { ThreeCircles } from 'react-loader-spinner';
import { confirmAlert } from 'react-confirm-alert';
import DataTable from 'react-data-table-component';
import Language from '../../Component/Language';
import { Box, Modal } from '@mui/material';
import { toast } from 'react-toastify';
import _ from 'lodash';
import axios from "axios"

import { modelBoxStyle, DOMAIN_URL } from '../../Helpers/Constants';
import { API } from '../../Helpers/ApiService';
import { RoutesPath } from "../../Routes";
import { URL } from '../../Helpers/URL';


const Settings = () => {

    const [settings, setSettings] = useState({});
    const [uploading, setUploading] = useState(null);
    const [tickerLang, setTickerLang] = useState('en');

    useEffect(() => {
        getData();
    }, []);

    const getData = () => {
        API({
            url: URL.GET_SETTINGS,
            method: 'GET',
        }).then(res => {
            if (res?.code) {
                return;
            }
            res.glimpsesImages = res?.glimpsesImages ? JSON.parse(res.glimpsesImages) : [];
            res.glimpsesVideos = res?.glimpsesVideos ? JSON.parse(res.glimpsesVideos) : [];
            res.sliderImages = res?.sliderImages ? JSON.parse(res.sliderImages) : [];
            res.tickerArray = res?.tickerArray ? JSON.parse(res.tickerArray) : [];

            if (!res.tickerArray.length) {
                res.tickerArray.push({
                    text: {
                        es: "",
                        fr: "",
                        de: "",
                        it: "",
                        en: "",
                        hi: "",
                    },
                    link: ''
                });
            }
            setSettings(res);
        });
    }

    const handleSave = () => {
        API({
            url: URL.UPDATE_SETTINGS,
            method: 'POST',
            data: settings
        }).then(res => {
            if (res?.code) {
                toast.error(res.message)
                return;
            }
            toast.success('Setting saved successfully')
        });
    }

    const onChangeFile = (e) => {
        let files = e.target.files;
        if (!files.length) {
            return;
        }
        let payload = {};
        for (let index = 0; index < files.length; index++) {
            payload['file' + index] = files[index];
        }

        API({
            url: URL.UPLOAD,
            method: 'POST',
            data: payload,
            formData: true
        }).then(res => {
            e.target.value = null;
            if (res?.code) {
                toast.error(res.message);
                return;
            }
            var data = _.cloneDeep(settings);
            if (!data?.sliderImages?.length) {
                data['sliderImages'] = [];
            }
            res.map(e => {
                data['sliderImages'].push('uploads/' + e.filename)
            });
            setSettings(data);
        });
    }

    const removeImage = (index) => {
        var data = _.cloneDeep(settings);
        data.sliderImages.splice(index, 1);
        setSettings(data);
    }

    const uploadVideo = (e) => {
        const video = e.target.files[0]
        if (!video) {
            return;
        }
        const TOKEN = localStorage.getItem('token');
        let formData = new FormData();
        formData.append("file", video);

        var ajax = new XMLHttpRequest();
        ajax.upload.addEventListener("progress", (event) => {
            var percent = parseInt((event.loaded / event.total) * 100);
            setUploading({
                total: (event.total / 1000000).toFixed('2'),
                loaded: (event.loaded / 1000000).toFixed('2'),
                percentage: percent,
            })
        }, false);

        ajax.onreadystatechange = function () {
            e.target.value = null;
            setUploading(null)
            if (this.readyState == 4 && this.status == 201) {
                const response = JSON.parse(this.responseText);
                var data = _.cloneDeep(settings);
                data['teaserVideo'] = 'uploads/' + response[0].filename;
                setSettings(data);
            }
        };
        ajax.open("POST", DOMAIN_URL + 'v1/' + URL.UPLOAD);
        ajax.setRequestHeader("Authorization", 'Bearer ' + TOKEN);
        ajax.send(formData);
    }

    const onchangeSession = (name) => {
        API({
            url: URL.SESSION_UPDATE + name,
            method: 'GET',
        }).then(res => {
            if (res?.code) {
                return;
            }
            toast.success('Session updated successfully')
            getData();
        });
    }

    return (
        <>
            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="m-0">Settings</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><a href={RoutesPath.Admin.Dashboard}>Home</a></li>
                                <li className="breadcrumb-item active">Settings</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>

            <div className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className='row'>
                                        <div className='col-12'>
                                            <div className='d-flex'>
                                                <h4>Banners</h4>
                                                <div className="upload-btn-wrapper ml-3">
                                                    <button className="btn-fileUpload"><i className='fa fa-plus' ></i></button>
                                                    <input type="file" accept='image/png,image/jpg,image/jpeg' onChange={e => onChangeFile(e)} multiple />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-12'>
                                            <div className='row'>
                                                {
                                                    settings?.sliderImages?.length ? <>
                                                        {
                                                            settings.sliderImages.map((e, i) => <div key={i} className='col-4 mt-2'>
                                                                <i className='fa fa-times'
                                                                    onClick={() => removeImage(i)}
                                                                    style={{
                                                                        position: 'absolute',
                                                                        top: -7,
                                                                        left: 0,
                                                                        background: '#000',
                                                                        color: '#fff',
                                                                        fontWeight: '700',
                                                                        padding: '5px 8px',
                                                                        borderRadius: 100
                                                                    }}></i>
                                                                <img style={{ maxHeight: 200, width: 'auto', maxWidth: '100%' }} src={DOMAIN_URL + e} />
                                                            </div>)
                                                        }
                                                    </> : <>
                                                        <div className='col-12 pt-3 text-center'>
                                                            <p>Banners not found</p>
                                                        </div>
                                                    </>
                                                }
                                            </div>
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className='col-12 mt-5 d-flex align-items-center'>
                                            <h4 className='mr-3'>Youtube Live </h4>
                                            <div className="form-group mb-1">
                                                <div className="custom-control custom-switch">
                                                    <input type="checkbox" checked={settings?.isLiveChecked == 'true' ? true : false} className="custom-control-input" id="customSwitch1" onChange={(e) => {
                                                        const isChecked = settings?.isLiveChecked == 'true' ? 'false' : 'true';
                                                        var data = _.cloneDeep(settings);
                                                        data['isLiveChecked'] = isChecked;
                                                        setSettings(data);
                                                    }} />
                                                    <label className="custom-control-label" htmlFor="customSwitch1">Visible</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-10'>
                                            <div className="form-group">
                                                <input placeholder='https://www.youtube.com/embed/D0UnqGm_miA' className='form-control' type='url' value={settings?.live ?? ''} onChange={(e) => {
                                                    var data = _.cloneDeep(settings);
                                                    data['live'] = e.target.value;
                                                    setSettings(data);
                                                }} />
                                            </div>
                                        </div>
                                        <div className='col-2 align-items-center d-flex'>

                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-12 mt-5'>
                                            <h4>Support Video Link </h4>
                                        </div>
                                        <div className='col-10'>
                                            <div className="form-group">
                                                <input placeholder='https://www.youtube.com/embed/D0UnqGm_miA' className='form-control' type='url' value={settings?.supportVideo ?? ''} onChange={(e) => {
                                                    var data = _.cloneDeep(settings);
                                                    data['supportVideo'] = e.target.value;
                                                    setSettings(data);
                                                }} />
                                            </div>
                                        </div>
                                    </div>

                                    <div className='row mt-5'>
                                        <div className='col-6'>
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <div className='d-flex'>
                                                        <h4>Teaser Video</h4>
                                                        <div className="upload-btn-wrapper ml-3">
                                                            <button className="btn-fileUpload"><i className='fa fa-plus' ></i></button>
                                                            <input type="file" accept='video/mp4' onChange={e => uploadVideo(e)} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-12'>
                                                    {
                                                        uploading ? <>
                                                            <progress id="progressBar" value={uploading?.percentage ?? 0} max="100" style={{ width: '100%' }}></progress>
                                                            <b>Uploaded {uploading?.loaded ?? 0} MB of {uploading?.total ?? 0} MB</b><br />
                                                        </> : null
                                                    }
                                                    {
                                                        settings?.teaserVideo ? <>
                                                            <video width="100%" height="240" controls>
                                                                <source src={DOMAIN_URL + settings?.teaserVideo} type="video/mp4" />
                                                            </video>
                                                        </> : null
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-6'>
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <div className='d-flex'>
                                                        <h4>Contact Info</h4>
                                                    </div>
                                                </div>
                                                <div className='col-12 mt-3'>
                                                    <div className="form-group">
                                                        <label>Email</label>
                                                        <input placeholder='Email' className='form-control' type='email' value={settings?.email ?? ''} onChange={(e) => {
                                                            var data = _.cloneDeep(settings);
                                                            data['email'] = e.target.value;
                                                            setSettings(data);
                                                        }} />
                                                    </div>
                                                </div>
                                                <div className='col-12'>
                                                    <div className="form-group">
                                                        <label>Whatsapp Number</label>
                                                        <input placeholder='Whatsapp Number' className='form-control' type='text' value={settings?.whatsapp ?? ''} onChange={(e) => {
                                                            var data = _.cloneDeep(settings);
                                                            data['whatsapp'] = e.target.value;
                                                            setSettings(data);
                                                        }} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='row mt-5'>
                                        <div className='col-12'>
                                            <label> Ticker Text</label>
                                            <Language lang={tickerLang} onChange={lang => setTickerLang(lang)} />

                                            {
                                                settings.tickerArray?.map((e, i) => <div key={i} className='row'>
                                                    <div className='col-5'>
                                                        <div className="form-group mt-3 mb-3 ">
                                                            <input placeholder='Ticker Text' className='form-control' type='text' value={settings?.tickerArray[i].text[tickerLang] ?? ''} onChange={(e) => {
                                                                var data = _.cloneDeep(settings);
                                                                data.tickerArray[i].text[tickerLang] = e.target.value;
                                                                setSettings(data);
                                                            }} />
                                                        </div>
                                                    </div>
                                                    <div className='col-5'>
                                                        <div className="form-group mt-3 mb-3 ">
                                                            <input placeholder='url (optional)' className='form-control' type='url' value={settings?.tickerArray[i].link ?? ''} onChange={(e) => {
                                                                var data = _.cloneDeep(settings);
                                                                data.tickerArray[i].link = e.target.value;
                                                                setSettings(data);
                                                            }} />
                                                        </div>
                                                    </div>
                                                    <div className='col-2 pt-3 text-center'>
                                                        {
                                                            i == 0 ? <button className='btn btn-success' onClick={() => {
                                                                var data = _.cloneDeep(settings);
                                                                data.tickerArray.push({
                                                                    text: {
                                                                        es: "",
                                                                        fr: "",
                                                                        de: "",
                                                                        it: "",
                                                                        en: "",
                                                                        hi: "",
                                                                    },
                                                                    link: ''
                                                                });
                                                                setSettings(data);
                                                            }}>+</button> : <button className='btn btn-danger'>-</button>
                                                        }
                                                    </div>
                                                </div>)
                                            }

                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className='col-12 text-center pt-5'>
                                            <button className='btn btn-primary' type='button' onClick={handleSave}>Save</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </>
    );
}

export default Settings;
