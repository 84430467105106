import { useEffect, useState } from 'react';
import Multiselect from 'multiselect-react-dropdown';
import _ from 'lodash';

import { API } from '../../Helpers/ApiService';
import { RoutesPath } from "../../Routes";
import { URL } from '../../Helpers/URL';
import { toast } from 'react-toastify';


const EventSummaryReport = () => {

    const [data, setData] = useState(null);

    useEffect(() => {
        API({
            url: URL.EVENT_SUMMARY_REPORT,
            method: 'GET',
        }).then(res => {
            if (res?.code) {
                return;
            }
            console.log(res)
            setData(res);
        });
    }, []);

    return (
        <>
            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            {/* <h1 className="m-0">Event Summary Report</h1> */}
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><a href={RoutesPath.Admin.Dashboard}>Home</a></li>
                                <li className="breadcrumb-item active">Event Summary Report</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>

            <div className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12 text-center">
                            <img src="https://uicwsc23.in/eventlogo.png" width="300" />
                            <hr color='#000' />
                            <h1>Event Summary Report</h1>
                            <hr color='#000' />
                        </div>
                        <div className="col-lg-12">
                            <table>
                                <tbody>
                                    <tr>
                                        <td><b>Total registered user: </b></td>
                                        <td className='pl-3'>{data?.registeredUser ?? 0}</td>
                                    </tr>
                                    <tr>
                                        <td><b>Total approved user: </b></td>
                                        <td className='pl-3'>{data?.approvedUser ?? 0}</td>
                                    </tr>
                                    <tr>
                                        <td><b>Total feedback received: </b></td>
                                        <td className='pl-3'>{data?.totalFeedback ?? 0}</td>
                                    </tr>
                                    <tr>
                                        <td><b>Session 1 opinion poll clicks: </b></td>
                                        <td className='pl-3'>{data?.session1Clicks ?? 0}</td>
                                    </tr>
                                    <tr>
                                        <td><b>Session 2 opinion poll clicks: </b></td>
                                        <td className='pl-3'>{data?.session2Clicks ?? 0}</td>
                                    </tr>
                                    <tr>
                                        <td><b>Session 3 opinion poll clicks: </b></td>
                                        <td className='pl-3'>{data?.session3Clicks ?? 0}</td>
                                    </tr>
                                    <tr>
                                        <td><b>Session 4 opinion poll clicks: </b></td>
                                        <td className='pl-3'>{data?.session4Clicks ?? 0}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default EventSummaryReport;
