
const NotFound = () => {
    return (
        <>
            <p>Page Not Found</p>
        </>
    );
}

export default NotFound;
