import { useDispatch, useSelector } from "react-redux";
import { Outlet, useLocation, useSearchParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ScrollToTop from "react-scroll-to-top";
import { Link } from "react-router-dom";
import $ from "jquery";

// import LogoTop from '../assets/fe/images/uic.png';
import Logo from '../assets/fe/images/logoo.png';
import Logo2 from '../assets/fe/images/logo-2.png';
import RPFLOGO from '../assets/fe/images/RPFNEW.png';
// import UICSVGLOGO from '../assets/fe/images/uic.svg';
// import UICSVGLOGO2 from '../assets/fe/images/UIC-logo-2.png';
import LogoS from '../assets/fe/images/logo.png';
import UICLOGO from '../assets/fe/images/logoaipdm.png';
import G20LOGO from '../assets/fe/images/G20-logo.png';
import G20NEWLOGO from '../assets/fe/images/G20_White.png';
import WSC from '../assets/fe/images/logoaipdm.png';
import KOOAPP from '../assets/fe/images/koo-app-white.svg';
import TWITTER from '../assets/fe/images/x-twitter.svg';
import Artborad from '../assets/fe/images/Artboard_Final.png';

import { RoutesPath } from "../Routes";
import { logout, setLanguage, setSettings } from "../reducers/authReducer";
import { API } from "../Helpers/ApiService";
import { URL } from "../Helpers/URL";

const FrontLayout = (props) => {
    const location = useLocation();
    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();
    const { t, i18n } = useTranslation();
    const auth = useSelector(state => state.auth);

    const [isLoader, setIsLoader] = useState(true);
    const [langMenu, setLangMenu] = useState(false);
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    const [pages, setPages] = useState([]);
    const [openDropdown, setOpenDropdown] = useState('');
    const [aboutPage, setAboutPage] = useState(null);
    const [glimpsePage, setGlimpsePage] = useState(null);
    const [informationDeskPage, setInformationDeskPage] = useState(null);
    const [mediaCoveragePage, setMediaCoveragePage] = useState(null);
    const [feedbackLink, setFeedbackLink] = useState('');

    useEffect(() => {
        API({
            url: URL.GET_SETTINGS,
            method: 'GET',
        }).then(res => {
            if (res?.code) {
                return;
            }
            dispatch(setSettings(res));
        });

        API({
            url: URL.GET_PAGES,
            method: 'GET',
        }).then(res => {
            setTimeout(() => {
                setIsLoader(false);
            }, 1000);
            if (res?.code) {
                return;
            }
            res.map(e => {
                if (e.type == 'About') {
                    setAboutPage(e)
                }
                if (e.type == 'Glimpse') {
                    setGlimpsePage(e)
                }
                if (e.type == 'InformationDesk') {
                    setInformationDeskPage(e)
                }
                if (e.type == 'Media Coverage') {
                    setMediaCoveragePage(e)
                }

            });
            setPages(res);
        });

        if (!location.pathname.includes('backoffice')) {
            import('../assets/fe/css/bootstrap.min.css');
            import('../assets/fe/css/style.css');
            import('../assets/fe/css/custom.css');
            import('../assets/fe/css/responsive.css');
            import('../assets/fe/css/color-switcher-design.css');
        }
        setLang();
    }, []);

    useEffect(() => {
        if (mobileMenuOpen) {
            $('body').addClass('mobile-menu-visible');
        } else {
            $('body').removeClass('mobile-menu-visible');
        }
        $('.color-palate').hover(
            function () {
                setLangMenu(true)
                $(this).addClass("visible-palate");
            },
            function () {
                setLangMenu(false)
                $(this).removeClass("visible-palate");
            }
        );
        $('body').addClass('fixed-header');
    }, [mobileMenuOpen]);

    useEffect(() => {
        if (auth.settings?.whatsapp) {
            $('#whatsappbtn').attr('href', `https://wa.me/${auth.settings?.whatsapp}`);
        }
    }, [auth.settings]);

    useEffect(() => {
        setFeedbackLink('');
        if (auth.value?.extra?.feedbacks?.length) {
            auth.value?.extra?.feedbacks.map(e => {
                if (!e.filled) {
                    setFeedbackLink('/feedback/' + e.id)
                }
            })
        }
    }, [auth]);

    useEffect(() => {
        API({
            url: URL.VISITOR_COUNT,
            method: 'GET',
        })
    }, [location]);

    const chooseLanguage = async (lang) => {
        localStorage.setItem('lang', lang);
        dispatch(setLanguage(lang));
        i18n.changeLanguage(lang);
        setLangMenu(false);
    }

    const setLang = async () => {
        const gLang = searchParams.get('language')
        if (!gLang) {
            const lang = await localStorage.getItem('lang');
            if (['en', 'hi', 'es', 'fr', 'de', 'it'].includes(lang)) {
                dispatch(setLanguage(lang));
                i18n.changeLanguage(lang);
            }
        } else {
            dispatch(setLanguage(gLang));
            i18n.changeLanguage(gLang);
        }
    }

    return (
        <>
            <div className="page-wrapper" >
                {
                    isLoader && !searchParams.get('hidelayout') ? <div className="preloader" style={{
                        height: '100%',
                        width: '100%',
                        display: 'flex',
                        justifyContent: "center",
                        alignItems: "center",
                        position: 'fixed'
                    }}><div className="custom-loader"></div></div> : null
                }
                {
                    !searchParams.get('hidelayout') ? <>
                        <header className="main-header header-style-one">
                            <div className="header-lower">
                                <div className="auto-container" style={{ maxWidth: '100%', paddingLeft: 0 }}>
                                    <div className="main-box">
                                        <div className="logo-box">
                                            <div className="logo">
                                                <Link to={RoutesPath.Home.path}>
                                                    <img src={Artborad} style={{ width: 252 }} alt="" title="" />
                                                </Link>
                                            </div>
                                        </div>
                                        <div className="nav-outer">
                                            <nav className="main-menu navbar-expand-md">
                                                <div className="navbar-collapse collapse clearfix" id="navbarSupportedContent">
                                                    <ul className="navigation clearfix">
                                                        <li className={location.pathname === '/' ? 'current' : ''}><Link to={RoutesPath.Home.path}>{t('home')}</Link>
                                                        </li>
                                                        <li className={location.pathname.includes('about') ? "dropdown current" : "dropdown"}><a>{t('about')}</a>
                                                            <ul>
                                                                {
                                                                    pages.map((element, index) => {
                                                                        return element.type == 'About' ?
                                                                            <li key={'About' + index}>
                                                                                <Link to={'/about/' + element.id}>{element.pageTitle[auth.language]}</Link>
                                                                            </li> : null
                                                                    }
                                                                    )
                                                                }
                                                            </ul>
                                                        </li>
                                                        <li className={location.pathname.includes('agenda') ? "current" : ""}><Link to={RoutesPath.Schedule.path}>{t('agenda')}</Link>
                                                        </li>
                                                        <li className={location.pathname.includes('speakers') ? "current" : ""}><Link to={RoutesPath.Speakers.path}>{t('speakers')}</Link>
                                                        </li>
                                                        <li className={location.pathname.includes('support-service') ? "dropdown current" : "dropdown"}><a>{t('suport_service')}</a>
                                                            <ul>
                                                                {
                                                                    pages.map((element, index) => {
                                                                        return element.type == 'Support' ? <li key={'Support' + index}><Link to={'/support-service/' + element.id}>{element.pageTitle[auth.language]
                                                                        }</Link></li> : null
                                                                    }
                                                                    )
                                                                }
                                                            </ul>
                                                        </li>
                                                        <li className={location.pathname.includes('contact') ? "current" : ""}><Link to={RoutesPath.Contact.path}>{t('contact_us')}</Link></li>
                                                    </ul>
                                                </div>
                                            </nav>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="sticky-header">
                                <div className="auto-container home-header-container" >
                                    <div className="main-box">
                                        <div className="logo-box">
                                            <div className="logo">
                                                <Link to={RoutesPath.Home.path}>
                                                    <img src={Artborad} alt="" title="" />
                                                </Link>
                                            </div>
                                            <div className="upper-right" onClick={() => setMobileMenuOpen(true)}>
                                                <a className="mobile-nav-toggler navbar-trigger">
                                                    <i className="flaticon-menu"></i>
                                                </a>
                                            </div>
                                        </div>
                                        <nav className="main-menu navbar-expand-md d-none">

                                        </nav>
                                        <nav className="main-menu navbar-expand-md mr-5">
                                            <div className="navbar-collapse collapse clearfix" id="navbarSupportedContent">
                                                <ul className="navigation clearfix">
                                                    <li className={location.pathname === '/' ? 'current' : ''}><Link to={RoutesPath.Home.path}>{t('home')}</Link>
                                                    </li>
                                                    {
                                                        aboutPage ? (
                                                            <li className={location.pathname.includes('about') ? 'current' : ''}>
                                                                <Link to={'/about/' + aboutPage.id}>{t('about')}</Link>
                                                            </li>
                                                        ) : null
                                                    }
                                                    <li className={location.pathname.includes('schedule') ? "current" : ""}>
                                                        <Link to={RoutesPath.Schedule.path}>{t('agenda')}</Link>
                                                    </li>
                                                    <li className={location.pathname.includes('live') ? "current" : ""}><Link to={RoutesPath.LiveEvents.path}>{t('live_events')}</Link>
                                                    </li>
                                                    <li className={location.pathname.includes('result') ? "current" : ""}><Link to={RoutesPath.Result.path}>{t('result')}</Link>
                                                    </li>
                                                    {/* <li className={location.pathname.includes('past-state-records') ? "current" : ""}><Link to={RoutesPath.Speakers.path}>{t('speakers')}</Link>
                                                    </li> */}
                                                    {
                                                        glimpsePage ? (
                                                            <li className={location.pathname.includes('glimpse') ? 'current' : ''}>
                                                                <Link to={'/glimpse/' + glimpsePage.id}>{t('glp_aipdm')}</Link>
                                                            </li>
                                                        ) : null
                                                    }
                                                    {
                                                        mediaCoveragePage ? (
                                                            <li className={location.pathname.includes('media') ? 'current' : ''}>
                                                                <Link to={'/media-coverage/' + mediaCoveragePage.id}>{t('media_coverage')}</Link>
                                                            </li>
                                                        ) : null
                                                    }
                                                    {
                                                        informationDeskPage ? (
                                                            <li className={location.pathname.includes('information') ? 'current' : ''}>
                                                                <Link to={'/information-desk/' + informationDeskPage.id}>{t('information_desk')}</Link>
                                                            </li>
                                                        ) : null
                                                    }
                                                    <li className={location.pathname.includes('contact') ? "current" : ""}><Link to={RoutesPath.Contact.path}>{t('support')}</Link></li>
                                                </ul>
                                            </div>
                                        </nav>
                                        <div className="btn-box login-btn-header d-flex" >
                                            {
                                                auth?.value?.firstName ? (
                                                    <>
                                                        <span className="theme-btn btn-style-one" >
                                                            <span className="btn-title color-white" >
                                                                <Link to={RoutesPath.Account.path} style={{ color: '#fff' }}>
                                                                    <i className="flaticon-user" style={{ color: '#fff' }}></i > {t('my_account')}
                                                                </Link>
                                                            </span >
                                                        </span>
                                                        <span className="theme-btn btn-style-one" >
                                                            <span className="btn-title color-white" onClick={() => dispatch(logout())}>
                                                                <span style={{ color: '#fff' }}>
                                                                    {t('logout')}
                                                                </span>
                                                            </span >
                                                        </span>
                                                    </>
                                                ) : (
                                                    <Link to={RoutesPath.Login.path} className="theme-btn btn-style-one" >
                                                        <span className="btn-title" >
                                                            <i className="flaticon-user" style={{ color: '#fff' }} ></i > {t('login_register')}
                                                        </span >
                                                    </Link>
                                                )
                                            }

                                        </div >
                                    </div>
                                </div>
                            </div>
                            <div className="mobile-header">
                                <div className="logo">
                                    <Link to={RoutesPath.Home.path}>
                                        <img src={Artborad} alt="" title="" />
                                    </Link>
                                </div>
                                <div className="nav-outer clearfix">
                                    <div className="outer-box" onClick={() => setMobileMenuOpen(true)}>
                                        <a className="mobile-nav-toggler navbar-trigger">
                                            <i className="flaticon-menu"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="mobile-menu">
                                <div className="menu-backdrop"></div>
                                <nav className="menu-box">
                                    <div className="upper-box">
                                        <div className="nav-logo"><a><img src={Artborad} alt="" title="" /></a></div>
                                        <div className="close-btn" onClick={() => setMobileMenuOpen(false)}><i className="icon flaticon-close"></i></div>
                                    </div>
                                    <ul className="navigation clearfix">
                                        <li className={location.pathname === '/' ? 'current' : ''}>
                                            <Link onClick={() => setMobileMenuOpen(false)} to={RoutesPath.Home.path}>{t('home')}</Link>
                                        </li>
                                        {
                                            aboutPage ? (
                                                <li className={location.pathname.includes('about') ? 'current' : ''}>
                                                    <Link onClick={() => setMobileMenuOpen(false)} to={'/about/' + aboutPage.id}>{t('about')}</Link>
                                                </li>
                                            ) : null
                                        }
                                        <li className={location.pathname.includes('schedule') ? "current" : ""}><Link onClick={() => setMobileMenuOpen(false)} to={RoutesPath.Schedule.path}>{t('schedule')}</Link></li>

                                        <li className={location.pathname.includes('live') ? "current" : ""}><Link onClick={() => setMobileMenuOpen(false)} to={RoutesPath.LiveEvents.path}>{t('live_events')}</Link></li>

                                        <li className={location.pathname.includes('result') ? "current" : ""}><Link onClick={() => setMobileMenuOpen(false)} to={RoutesPath.Result.path}>{t('result')}</Link></li>

                                        {/* <li className={location.pathname.includes('past-state-records') ? "current" : ""}><Link onClick={() => setMobileMenuOpen(false)} to={RoutesPath.Speakers.path}>{t('speakers')}</Link></li> */}

                                        {
                                            glimpsePage ? (
                                                <li className={location.pathname.includes('glimpse') ? 'current' : ''}>
                                                    <Link onClick={() => setMobileMenuOpen(false)} to={'/glimpse/' + glimpsePage.id}>{t('glp_aipdm')}</Link>
                                                </li>
                                            ) : null
                                        }

                                        {
                                            mediaCoveragePage ? (
                                                <li className={location.pathname.includes('media') ? 'current' : ''}>
                                                    <Link onClick={() => setMobileMenuOpen(false)} to={'/media-coverage/' + mediaCoveragePage.id}>{t('media_coverage')}</Link>
                                                </li>
                                            ) : null
                                        }


                                        {
                                            informationDeskPage ? (
                                                <li className={location.pathname.includes('information') ? 'current' : ''}>
                                                    <Link onClick={() => setMobileMenuOpen(false)} to={'/information-desk/' + informationDeskPage.id}>{t('information_desk')}</Link>
                                                </li>
                                            ) : null
                                        }

                                        <li className={location.pathname.includes('contact') ? "current" : ""}><Link onClick={() => setMobileMenuOpen(false)} to={RoutesPath.Contact.path}>{t('support')}</Link></li>

                                        {
                                            auth?.value?.firstName ? (
                                                <li >
                                                    <Link onClick={() => setMobileMenuOpen(false)} to={RoutesPath.Account.path}>{t('my_account')}</Link>
                                                </li>
                                            ) : (
                                                <>
                                                    <li className={location.pathname.includes('login') ? 'current' : ''}>
                                                        <Link onClick={() => setMobileMenuOpen(false)} to={RoutesPath.Login.path}>{t('login')}</Link>
                                                    </li>
                                                    {/* <li className={location.pathname.includes('register') ? 'current' : ''}>
                                                        <Link onClick={() => setMobileMenuOpen(false)} to={RoutesPath.Register.path}>{t('register')}</Link>
                                                    </li> */}
                                                </>
                                            )
                                        }
                                    </ul>
                                </nav>
                            </div>
                        </header >
                    </> : null
                }
                <Outlet />
                {
                    !searchParams.get('hidelayout') ? <>
                        <footer className="main-footer">
                            <div className="auto-container">
                                <div className="row">
                                    <div className="col-md-2 col-sm-12 align-self-center">
                                        <div className="text-center">
                                            <div className="footer-logo text-left justify-content-around d-flex sm-text-center mb-1">
                                                <a href="https://rpf.indianrailways.gov.in/RPF/" target="_blank">
                                                    <img src={RPFLOGO} alt="RPF" style={{ height: 70, width: 70 }} />
                                                </a>
                                                {/* <div style={{
                                                    height: 70,
                                                    borderLeft: '1px solid #ffff'
                                                }}></div>
                                                <img src={UICSVGLOGO2} alt="UIC" style={{ height: 60, width: 90 }} /> */}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-sm-12 d-flex justify-content-center align-items-center">
                                        <ul className="contact-list-one align-items-baseline">
                                            <li className="mb-1">
                                                <a target="_blank" href="https://maps.google.com/?q=J.J.R. RPF Academy Centralized Training Institute of RPF Talkatora Rd, Manak Nagar, Alambagh, Lucknow, Uttar Pradesh 226011">
                                                    <i className="flaticon-location" style={{ marginTop: 15 }}></i>
                                                    <strong className="text-center">{t('address')}</strong>
                                                    <p style={{
                                                        lineHeight: 1.4,
                                                        color: '#fff',
                                                        fontSize: 14,
                                                        textAlign: "center"
                                                    }}>
                                                        JR RPF Academy <br />
                                                        Centralized Training Institute of RPF<br />
                                                        Alambagh, Lucknow, Uttar Pradesh<br />
                                                    </p>
                                                </a>
                                            </li>

                                        </ul>
                                    </div>
                                    <div className="col-md-4 col-sm-12 d-flex justify-content-center align-items-center">
                                        <ul className="contact-list-one align-items-baseline">
                                            <li className="mb-1">
                                                <a href={`mailto:${auth?.settings?.email ?? ''}`}>
                                                    <i className="flaticon-email-1" style={{ marginTop: 10 }}></i>
                                                    <strong className="text-center">{t('mail_us')}</strong>
                                                    {
                                                        auth.settings?.email ? <p style={{ color: '#fff' }}>{auth.settings?.email}</p> : null
                                                    }
                                                </a>
                                            </li>
                                        </ul>
                                        {/* <ul className="social-icon-two mb-0" style={{ justifyContent: "center" }}>
                                            <li >
                                                <a style={{ border: 0, fontSize: 25, marginTop: -10 }} href="https://uicwsc23.in/CCS-RPFUIC.apk" target={'_blank'}>
                                                    <span className="fab fa-android"></span>
                                                </a>
                                            </li>
                                            <li >
                                                <a style={{ border: 0, fontSize: 25, marginTop: -10 }} href="https://www.apple.com/app-store/" target={'_blank'}>
                                                    <span className="fab fa-apple"></span>
                                                </a>
                                            </li>
                                        </ul> */}
                                    </div>
                                    <div className="col-md-2 col-sm-12 align-self-center">
                                        <div className="text-center">
                                            <div className="footer-logo mb-1 d-flex sm-text-center justify-content-around ">
                                                <a href="https://aipscb.com/" target="_blank">
                                                    <img src={WSC} alt="WSC" style={{ height: 70, }} />
                                                </a>
                                                {/* <div style={{
                                                    height: 70,
                                                    borderLeft: '1px solid #ffff'
                                                }}></div>
                                                <img src={G20NEWLOGO} alt="G20" style={{ height: 70 }} /> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="footer-bottom">
                                <div className="auto-container d-flex justify-content-between footer-mobile">
                                    <div className="inner-container justify-content-center p-0">
                                        <div className="copyright-text">
                                            <p className="text-center" style={{ fontSize: 13 }}>{t('copyright')} | <Link to={RoutesPath.TermAndCondition.path}>Disclaimer</Link></p>
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-center align-items-center">
                                        <p style={{ fontWeight: '600', color: '#fff' }}>{t('visitors')} : {auth?.settings?.visitorCount}</p>
                                        {/* <p style={{ color: 'white' }} className="text-center">{t('download_register')}</p>
                                        <ul className="social-icon-two mb-0" style={{ justifyContent: "center" }}>
                                            <li >
                                                <a style={{ border: 0, fontSize: 25, marginTop: -10, height: 25, width: 35 }} href="#" target={'_blank'}>
                                                    <span className="fab fa-android"></span>
                                                </a>
                                            </li>
                                            <li >
                                                <a style={{ border: 0, fontSize: 25, marginTop: -10, height: 25, width: 35 }} href="#" target={'_blank'}>
                                                    <span className="fab fa-apple"></span>
                                                </a>
                                            </li>
                                        </ul> */}
                                    </div>
                                    <ul className="social-icon-two btm-socialicon">
                                        <li><a target="_blank" href="https://rpf.indianrailways.gov.in/RPF/"><span className="fa fa-globe"></span></a></li>
                                        <li><a target="_blank" href="https://www.youtube.com/@railwayprotectionforce-off6979/featured"><span className="fab fa-youtube"></span></a></li>
                                        <li><a target="_blank" href="https://www.kooapp.com/profile/RPF_INDIA"><img src={KOOAPP} width={20} /></a></li>
                                        <li><a target="_blank" href="https://twitter.com/RPF_INDIA"><img src={TWITTER} width={25} /></a></li>
                                        <li><a href="#"><span className="fab fa-instagram"></span></a></li>
                                        <li><a target="_blank" href="https://www.facebook.com/people/RPF-INDIA/100064795897796/"><span className="fab fa-facebook-f"></span></a></li>
                                    </ul>
                                </div>
                            </div>
                        </footer>
                    </> : null
                }

                <div className="scroll-to-top scroll-to-target" data-target="html"><span className="fa fa-angle-up"></span></div>
                {
                    !searchParams.get('hidelayout') ? <div className={`color-palate ${langMenu ? 'visible-palate' : ''}`}>
                        <div className="color-trigger" onClick={() => setLangMenu(!langMenu)}>
                            <i className="fa fa-language" onClick={() => setLangMenu(!langMenu)}></i>
                        </div>
                        <div className="color-palate-head">
                            <h6>{t('choose_lang')}</h6>
                        </div>
                        <ul className="rtl-version option-box">
                            <li style={auth?.language == 'en' ? { backgroundColor: '#ff7c00' } : { backgroundColor: '#001376' }} onClick={() => chooseLanguage('en')}>English</li>
                            <li style={auth?.language == 'hi' ? { backgroundColor: '#ff7c00' } : { backgroundColor: '#001376' }} onClick={() => chooseLanguage('hi')} className="box">Hindi</li>
                        </ul>
                        {/* <ul className="box-version option-box">
                            <li style={auth?.language == 'es' ? { backgroundColor: '#ff7c00' } : { backgroundColor: '#001376' }} onClick={() => chooseLanguage('es')}>Spanish</li>
                            <li style={auth?.language == 'fr' ? { backgroundColor: '#ff7c00' } : { backgroundColor: '#001376' }} onClick={() => chooseLanguage('fr')} className="box">French</li>
                        </ul>
                        <ul className="rtl-version option-box">
                            <li style={auth?.language == 'de' ? { backgroundColor: '#ff7c00' } : { backgroundColor: '#001376' }} onClick={() => chooseLanguage('de')}>German</li>
                            <li style={auth?.language == 'it' ? { backgroundColor: '#ff7c00' } : { backgroundColor: '#001376' }} onClick={() => chooseLanguage('it')} className="box">Italian</li>
                        </ul> */}
                    </div> : null
                }
                {/* {
                    auth?.settings?.visitorCount ? <div className={`visitor-count`} style={{ background: '#001376', position: 'fixed', bottom: 20, padding: 10 }}>
                        <p style={{ fontWeight: '600', color: '#fff' }}>{t('visitors')} : {auth?.settings?.visitorCount}</p>
                    </div> : null
                } */}


            </div >
            {
                feedbackLink ? <Link to={feedbackLink}><i className="fa fa-comments blinking-feedback" /></Link> : null
            }
            <ScrollToTop smooth />
        </>
    );
}
export default FrontLayout;