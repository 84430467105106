import { useEffect, useState } from 'react';
import Multiselect from 'multiselect-react-dropdown';
import _ from 'lodash';

import { API } from '../../Helpers/ApiService';
import { RoutesPath } from "../../Routes";
import { URL } from '../../Helpers/URL';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';


const FeedbackReport = () => {
    const navigate = useNavigate();
    const [report, setReport] = useState([]);

    useEffect(() => {
        API({
            url: URL.FEEDBACK_REPORT,
            method: 'GET',
        }).then(res => {
            if (res?.code) {
                return;
            }
            setReport(res)
        });
    }, []);

    return (
        <>
            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            {/* <h1 className="m-0">Feedback Report</h1> */}
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><a href={RoutesPath.Admin.Dashboard}>Home</a></li>
                                <li className="breadcrumb-item active">Feedback Report</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>

            <div className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12 text-center">
                            <img src="https://uicwsc23.in/eventlogo.png" width="300" />
                            <hr color='#000' />
                            <h1>Feedback Report</h1>
                        </div>
                        <div className="col-lg-12 text-center">
                            <table border={1} className="text-center" width={'100%'}>
                                <thead>
                                    <th>Sr. no.</th>
                                    <th>Name</th>
                                    <th>Topic</th>
                                    <th>Average Feedback</th>
                                    <th className='d-print-none'>Action</th>
                                </thead>
                                <tbody>
                                    {
                                        report.length ? <>
                                            {
                                                report.map((e, i) => <tr key={i}>
                                                    <td>{i + 1}</td>
                                                    <td>{e.name}<br />{e.designation}</td>
                                                    <td className='text-left'>
                                                        {e.title['en']}
                                                    </td>
                                                    <td>
                                                        Average Rating: {e.avgRating}<br />
                                                        Total rating received: {e.totalRating}
                                                    </td>
                                                    <td className='d-print-none'>
                                                        <i className='fa fa-arrow-right' style={{ cursor: 'pointer' }} onClick={() => {
                                                            navigate(`/backoffice/feedback-report-details/` + e.id);
                                                        }}></i>
                                                    </td>
                                                </tr>)
                                            }
                                        </> : <tr>
                                            <td colSpan={5} className="text-center">No report found!</td>
                                        </tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default FeedbackReport;
