import { useEffect, useState } from 'react';
import Multiselect from 'multiselect-react-dropdown';
import _ from 'lodash';

import { API } from '../../Helpers/ApiService';
import { RoutesPath } from "../../Routes";
import { URL } from '../../Helpers/URL';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';


const FeedbackReportDetail = () => {
    let { formId } = useParams();
    const [report, setReport] = useState([]);
    const [form, setForm] = useState(null);

    useEffect(() => {
        API({
            url: URL.FEEDBACK_REPORT_DETAILS + formId,
            method: 'GET',
        }).then(res => {
            if (res?.code) {
                return;
            }
            setReport(res.feedBacks)
            setForm(res.form)
        });
    }, []);

    return (
        <>
            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="m-0">Feedback Report Details</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><a href={RoutesPath.Admin.Dashboard}>Home</a></li>
                                <li className="breadcrumb-item active">Feedback Report Details</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>

            <div className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="col-lg-12 text-center">
                                <img src="https://uicwsc23.in/eventlogo.png" width="300" />
                                <hr color='#000' />
                                <h1>{form?.speakerObj.name} - {form?.topic['en']}</h1>
                                <hr color='#000' />
                            </div>
                            <div className="col-lg-12 text-center">
                                <table border={1} className="text-center" width={'100%'}>
                                    <thead>
                                        <th>Sr. no.</th>
                                        <th>Whether topic was relevant and useful</th>
                                        <th>Whether speaker/panel was knowledgeable and effective</th>
                                        <th>Whether content was presented in an interesting manner</th>
                                        <th>Whether session was well organized:	</th>
                                        <th>Comments</th>
                                    </thead>
                                    <tbody>
                                        {
                                            report.length ? <>
                                                {
                                                    report.map((e, i) => <tr key={i}>
                                                        <td>{i + 1}</td>
                                                        <td>{e.question1}</td>
                                                        <td>{e.question2}</td>
                                                        <td>{e.question3}</td>
                                                        <td>{e.question4}</td>
                                                        <td>{e.comments ?? '-'}</td>
                                                    </tr>)
                                                }
                                            </> : <tr>
                                                <td colSpan={5} className="text-center">No feedback found!</td>
                                            </tr>
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default FeedbackReportDetail;
