import React, { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useSearchParams } from "react-router-dom";

import BGImage11 from '../assets/fe/images/menubg.png';
import { logout, setUser } from "../reducers/authReducer";

import { BASE_URL, DOMAIN_URL, modelBoxStyle } from '../Helpers/Constants';
import { URL } from "../Helpers/URL";
import { API } from "../Helpers/ApiService";
import { Box, Modal } from "@mui/material";
import ReactPlayer from "react-player";
import { toast } from "react-toastify";
import moment from "moment";

const Account = () => {
    const { t, i18n } = useTranslation();
    const [searchParams] = useSearchParams();
    const auth = useSelector(state => state.auth);
    const dispatch = useDispatch();

    const [downloads, setDownloads] = useState([]);
    const [mediaModel, setMediaModel] = useState(null);
    const [activeTab, setActiveTab] = useState('certificate');
    const [feedbackForms, setFeedbackForms] = useState([]);
    const [notifications, setNotifications] = useState([]);
    const [feedback, setFeedback] = useState('');
    const [certificates, setCertificates] = useState({});

    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    useEffect(() => {
        API({
            url: URL.USER_DOWNLOADS,
            method: 'GET',
        }).then(res => {
            if (res?.code) {
                return;
            }
            setDownloads(res)
        });

        API({
            url: URL.FEEDBACK,
            method: 'GET',
        }).then(res => {
            if (res?.code) {
                return;
            }
            setFeedback(res.feedBack)
        });

        API({
            url: URL.MY_CERTIFICATES,
            method: 'GET',
        }).then(res => {
            if (res?.code) {
                return;
            }
            setCertificates(res)
        });

        const tabCheck = searchParams.get('tab');
        if (tabCheck == 'feedback') {
            setActiveTab('feedback')
        }
        if (tabCheck == 'password') {
            setActiveTab('changePassword')
        }
    }, []);

    useEffect(() => {
        if (auth?.value?.extra?.notifications?.length) {
            setNotifications(auth?.value?.extra?.notifications);
        }
        if (auth?.value?.extra?.feedbacks) {
            setFeedbackForms(auth?.value?.extra?.feedbacks);
        }
    }, [])

    const downloadMedia = (url) => {
        window.open(`${BASE_URL}${URL.DOWNLOAD_FILE}certificates/${url}`, "_blank");
    }

    const onSubmit = (e) => {
        e.preventDefault();
        if (!oldPassword || !newPassword || !confirmPassword) {
            toast.error(t('all_fields_required'))
            return;
        }
        if (newPassword != confirmPassword) {
            toast.error(t('password_not_match'))
            return;
        }
        const payload = {
            old_password: oldPassword,
            new_password: newPassword
        }
        API({
            url: URL.CHANGE_PASSWORD,
            method: 'POST',
            data: payload
        }).then(res => {
            if (res?.code) {
                toast.error(res.message)
                return;
            }
            toast.success(t('password_change_success'));
            setOldPassword('');
            setNewPassword('');
            setConfirmPassword('');
        })
    }

    return (
        <>
            <section className="page-title mb-5" style={searchParams.get('hidelayout') ? { backgroundImage: "url(" + BGImage11 + ")", marginTop: 0 } : { backgroundImage: "url(" + BGImage11 + ")" }}>
                <div className="anim-icons full-width">
                    <span className="icon icon-bull-eye"></span>
                    <span className="icon icon-dotted-circle"></span>
                </div>
                <div className="auto-container">
                    <div className="title-outer">
                        <h1>{t('my_account')}</h1>
                    </div>
                </div>
            </section>
            <section className=" pt-1 pb-3 mb-5 mt-5 ">
                <div className="auto-container pt-5" style={{ minHeight: '50vh' }}>
                    <div className='row ' >
                        <div className='col-12 mb-3'>
                            <ul className="nav my-account">
                                <li className={activeTab == 'certificate' ? 'nav-item active' : 'nav-item'} onClick={() => setActiveTab('certificate')}>
                                    <a className="nav-link"><b>Certificate</b></a>
                                </li>
                                <li className={activeTab == 'feedback' ? 'nav-item active' : 'nav-item'} onClick={() => setActiveTab('feedback')}>
                                    <a className="nav-link"><b>Feedback</b></a>
                                </li>
                                <li className={activeTab == 'notifications' ? 'nav-item active' : 'nav-item'} onClick={() => setActiveTab('notifications')}>
                                    <a className="nav-link"><b>Notifications</b></a>
                                </li>
                                <li className={activeTab == 'changePassword' ? 'nav-item active' : 'nav-item'} onClick={() => setActiveTab('changePassword')}>
                                    <a className="nav-link"><b>Change Password</b></a>
                                </li>
                            </ul>
                        </div>
                        {
                            activeTab == 'certificate' ? <div className='col-12 text-center' style={{ border: '1px solid #000', borderRadius: 10, }}>
                                {
                                    !certificates?.participationCertificates?.length && !certificates?.winnerCertificates?.length ? <p>Certificate not uploaded!</p> : null
                                }

                                {
                                    certificates?.participationCertificates?.length ? <>
                                        <h4 className='text-center'>Participation Certificate</h4>
                                        <div className='row'>
                                            {
                                                certificates?.participationCertificates?.map((e, i) => <div key={i} className='col-12 d-flex flex-column align-items-center'>
                                                    <img src={DOMAIN_URL + 'certificates/' + e.image} width={250} />
                                                    <button className='ml-1 btn btn-info btn-sm mt-1 mb-1' title='certificates' onClick={() => {
                                                        downloadMedia(e.image);
                                                    }} >
                                                        < i className='fa fa-download' style={{ color: '#fff' }} />
                                                    </button>
                                                </div>)
                                            }
                                        </div>
                                    </> : null
                                }
                                {
                                    certificates?.winnerCertificates?.length ? <>
                                        <h4 className='text-center mt-5'>Winner Certificate</h4>
                                        <div className='row'>
                                            {
                                                certificates?.winnerCertificates?.map((e, i) => <div key={i} className='col-12 d-flex flex-column align-items-center'>
                                                    <img src={DOMAIN_URL + 'certificates/' + e.image} width={250} />
                                                    <button className='ml-1 btn btn-info btn-sm mt-1 mb-1' title='certificates' onClick={() => {
                                                        downloadMedia(e.image);
                                                    }} >
                                                        < i className='fa fa-download' style={{ color: '#fff' }} />
                                                    </button>
                                                </div>)
                                            }
                                        </div>
                                    </> : null
                                }


                                {/* {
                                    auth?.value?.certificate ? <span className="btn btn-primary mb-3" onClick={() => downloadMedia(auth?.value?.certificate)} >Download</span> : null
                                } */}
                            </div> : null
                        }
                        {
                            activeTab == 'feedback' ? <div className='col-12 p-3' style={{ border: '1px solid #000', borderRadius: 10, }}>
                                <div className="form-group">
                                    <label>Feedback</label>
                                    <textarea value={feedback} onChange={e => setFeedback(e.target.value)} className="form-control" placeholder="Enter feedback" rows={5}></textarea>
                                </div>
                                <div className="form-group">
                                    <button type="button" onClick={() => {
                                        API({
                                            url: URL.FEEDBACK,
                                            method: 'POST',
                                            data: { feedback }
                                        }).then(res => {
                                            if (res?.code) {
                                                return;
                                            }
                                            toast.success('Feedback saved');
                                        });
                                    }} className="theme-btn btn-style-one"><span
                                        className="btn-title">Save</span></button>
                                </div>
                            </div> : null
                        }
                        {
                            activeTab == 'notifications' ? <div className='col-12 text-center' style={{ border: '1px solid #000', borderRadius: 10, }}>
                                <div className="row">
                                    {
                                        notifications?.length ? <>
                                            {
                                                notifications?.map((e, i) => <div className="col-12" key={i}>
                                                    <div style={{
                                                        border: '1px solid #c9c9c9',
                                                        margin: 10,
                                                        padding: 10,
                                                        borderRadius: 10
                                                    }}>
                                                        <h4>{e.title}</h4>
                                                        <p>{e.body}</p>
                                                        {
                                                            e.url ? <a href={e.url} target="_blank"> {e.url}</a> : null
                                                        }
                                                    </div>
                                                </div>)
                                            }
                                        </> : <p>Notification not available!</p>
                                    }
                                </div>
                            </div> : null
                        }
                        {
                            activeTab == 'changePassword' ? <div className='col-12 text-center' >
                                <div className="row">
                                    <div className="col-12 d-flex justify-content-center">
                                        <div className="styled-form register-form mt-2 pt-4 reg-form" style={{ width: '50%' }}>
                                            <form onSubmit={onSubmit}>
                                                <div className="form-group mt-3">
                                                    <span className="adon-icon">
                                                        <span className="fa fa-unlock-alt"></span>
                                                    </span>
                                                    <input type="text" placeholder={t('old_password')} value={oldPassword} style={{ backgroundColor: '#E2E9FF' }} onChange={e => setOldPassword(e.target.value)} />
                                                </div>
                                                <div className="form-group mt-3">
                                                    <span className="adon-icon">
                                                        <span className="fa fa-unlock-alt"></span>
                                                    </span>
                                                    <input type="password" placeholder={t('new_password')} value={newPassword} style={{ backgroundColor: '#E2E9FF' }} onChange={e => setNewPassword(e.target.value)} />
                                                </div>
                                                <div className="form-group mt-3">
                                                    <span className="adon-icon">
                                                        <span className="fa fa-unlock-alt"></span>
                                                    </span>
                                                    <input type="password" placeholder={t('confirm_password')} value={confirmPassword} style={{ backgroundColor: '#E2E9FF' }} onChange={e => setConfirmPassword(e.target.value)} />
                                                </div>
                                                <div className="clearfix mt-5">
                                                    <div className="form-group">
                                                        <button type="submit" className="theme-btn btn-style-one"><span
                                                            className="btn-title">{t('submit')}</span></button>
                                                    </div>

                                                </div>

                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div> : null
                        }
                    </div>
                </div>
            </section>
            <Modal
                open={mediaModel ? true : false}
                onClose={() => resetForm()}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={[modelBoxStyle, { minWidth: '50%' }]}>
                    <div className='row'>
                        <div className='col-6'>
                            <h5>{mediaModel?.title[auth.language] ? mediaModel?.title[auth.language] : mediaModel?.title['en']}</h5>
                        </div>
                        <div className='col-6'>
                            <i style={{
                                position: 'absolute',
                                top: -15,
                                right: -15,
                                cursor: 'pointer',
                                padding: 10,
                            }} className='fa fa-times' onClick={() => setMediaModel(null)} />
                        </div>
                    </div>
                    <div className='row'>
                        <div className="col-12 mt-3">
                            <video width="100%" controls autoPlay>
                                <source src={DOMAIN_URL + mediaModel?.file} type="video/mp4" />
                            </video>

                        </div>
                        {/* <div className="col-12 text-right">
                            <span className="theme-btn btn-style-one text-right" style={{ cursor: 'pointer' }} onClick={() => downloadMedia(mediaModel.file)}>
                                <span className="btn-title color-white" >
                                    Download
                                </span >
                            </span>
                        </div> */}
                        <div className="col-12 mt-3">
                            <p>{mediaModel?.description[auth.language] ? mediaModel?.description[auth.language] : mediaModel?.description['en']}</p>
                        </div>
                    </div>
                </Box>
            </Modal>
        </>
    );
}
export default Account;
