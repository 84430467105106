import { useEffect, useState } from 'react';
import Multiselect from 'multiselect-react-dropdown';
import DataTable from 'react-data-table-component';
import { Box, Modal } from '@mui/material';
import _ from 'lodash';

import { API } from '../../Helpers/ApiService';
import { RoutesPath } from "../../Routes";
import { URL } from '../../Helpers/URL';
import { toast } from 'react-toastify';
import { modelBoxStyle } from '../../Helpers/Constants';
import { useDispatch, useSelector } from 'react-redux';
import { setNotification, setNotificationCount } from '../../reducers/authReducer';


const Notification = () => {
    const columns = [
        {
            name: 'Title',
            center: true,
            selector: row => <div style={{ whiteSpace: 'normal', overflow: 'visible' }}>{row.title}</div>,
        },
        {
            name: 'Description',
            center: true,
            selector: row => <div style={{ whiteSpace: 'normal', overflow: 'visible' }}>{row.body}</div >,
        },
        {
            name: 'Link',
            center: true,
            selector: row => row?.url ? <a target={'_blank'} style={{ whiteSpace: 'normal', overflow: 'visible' }} href={row?.url}>{row?.url}</a> : null,
        },
        {
            name: 'Send By',
            center: true,
            selector: row => row.sendBy?.firstName + ' ' + row.sendBy?.surname + ' (' + row.sendBy?.role + ')',
        }
    ];

    const auth = useSelector(state => state.auth);
    const dispatch = useDispatch();

    const [sendType, setSendType] = useState('');
    const [title, setTitle] = useState('');
    const [message, setMessage] = useState('');
    const [url, setUrl] = useState('');
    const [category, setCategory] = useState('');
    const [event, setEvent] = useState('');
    const [users, setUsers] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [categories, setCategories] = useState([]);
    const [events, setEvents] = useState([]);

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [model, setModel] = useState(null);
    const [formLoading, setFormLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [language, setLanguage] = useState('en');

    useEffect(() => {
        getData();
    }, []);

    useEffect(() => {
        getData();
    }, [perPage]);

    const getData = (page = 1) => {

        setLoading(true);
        API({
            url: URL.MY_NOTIFICATION + '?page=' + page + '&limit=' + perPage,
            method: 'GET',
        }).then(res => {
            setLoading(false);
            if (res?.code) {
                return;
            }
            setTotalRows(res.totalResults);
            setData(res.results);
        });

        API({
            url: URL.USERS_ALL,
            method: 'GET',
        }).then(res => {
            if (res?.code) {
                return;
            }
            setUsers([]);
            const u = [];
            res.map(e => {
                u.push({
                    id: e.id,
                    name: e.firstName + ' ' + e.surname
                })
            })
            setUsers(u);
        });

        API({
            url: URL.CATEGORY_LIST,
            method: 'GET',
        }).then(res => {
            if (res?.length) {
                setCategory(res[0].id)
            }
            setCategories(res)
        });

        API({
            url: URL.GET_EVENTS,
            method: 'GET',
        }).then(res => {
            setEvents(res)
        });

        API({
            url: URL.READ_NOTIFICATION,
            method: 'GET',
        }).then(res => {
            dispatch(setNotificationCount(res.notificationsCount));
            dispatch(setNotification(res.notifications));
        });
    }

    const handleCreate = () => {
        setModel({
            mode: 'create',
        });
    }

    const handleSend = () => {
        if (!sendType) {
            toast.error('Please select type!');
            return;
        }
        if (sendType == 'selected' && !selectedUsers.length) {
            toast.error('Please select users!');
            return;
        }
        if (sendType == 'event_category' && !category) {
            toast.error('Please select category!');
            return;
        }
        if (sendType == 'event' && !event) {
            toast.error('Please select event!');
            return;
        }

        if (!title) {
            toast.error('Please enter title!');
            return;
        }
        if (!message) {
            toast.error('Please enter title!');
            return;
        }
        const payload = {
            sendType,
            title,
            url,
            message,
            category,
            event,
            users: selectedUsers.map(e => { return e.id })
        }
        API({
            url: URL.NOTIFICATION_SEND,
            method: 'POST',
            data: payload
        }).then(res => {
            if (res?.code) {
                return;
            }
            resetForm();
            toast.success('Notification sent successfully');
        });

    }

    const resetForm = () => {
        setModel(null);
        setSendType('');
        setTitle('');
        setMessage('');
        setUrl('');
        setUsers([]);
        setSelectedUsers([]);
        getData();
    }

    return (
        <>
            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="m-0">Notifications</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><a href={RoutesPath.Admin.Dashboard}>Home</a></li>
                                <li className="breadcrumb-item active">Notifications</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>

            <div className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card">
                                <div className="card-header">
                                    <div className='row'>
                                        <div className='col-6 d-flex align-items-center'>
                                            <h3 className="card-title">My Notifications</h3>
                                        </div>
                                        <div className='col-6 d-flex justify-content-end'>
                                            {
                                                ["admin", 'manager', 'judge'].includes(auth?.value?.role) ? <button className='btn btn-primary' onClick={handleCreate}>
                                                    Send
                                                </button> : null
                                            }

                                        </div>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className='row'>
                                        <div className='col-12'>
                                            <DataTable
                                                columns={columns}
                                                data={data}
                                                progressPending={loading}
                                                pagination
                                                paginationServer
                                                paginationRowsPerPageOptions={[10, 25, 50, 100, 200]}
                                                paginationTotalRows={totalRows}
                                                onChangeRowsPerPage={(newPerPage, page) => { setPerPage(newPerPage); getData(page); }}
                                                onChangePage={page => getData(page)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal
                open={model ? true : false}
                onClose={() => resetForm()}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={modelBoxStyle}>
                    <div className='row'>
                        <div className='col-6'>
                            <h5>{model?.mode == 'create' ? 'Create' : 'Edit'}</h5>
                        </div>
                        <div className='col-6'>
                            <i style={{
                                position: 'absolute',
                                top: -15,
                                right: -15,
                                cursor: 'pointer',
                                padding: 10,
                            }} className='fa fa-times' onClick={() => resetForm()} />
                        </div>
                    </div>
                    <div className='row'>
                        <div className="col-12">
                            <select className='form-control' defaultValue={sendType} onChange={e => setSendType(e.target.value)}>
                                <option value={''} selected hidden>Select type</option>
                                {
                                    auth?.value?.role == 'admin' || auth?.value?.role == 'manager' ? <option value={'all'}>All Users</option> : null
                                }
                                {
                                    auth?.value?.role == 'admin' ? <option value={'event_category'}>Event Category</option> : null
                                }
                                {
                                    auth?.value?.role == 'admin' || auth?.value?.role == 'judge' ? <option value={'event'}>Event</option> : null
                                }
                                {
                                    auth?.value?.role == 'admin' || auth?.value?.role == 'judge' ? <option value={'managers'}>All Managers</option> : null
                                }
                                {
                                    auth?.value?.role == 'admin' ? <option value={'support'}>All Support Desk</option> : null
                                }
                                {
                                    auth?.value?.role == 'admin' ? <option value={'judges'}>All Judges</option> : null
                                }
                                {
                                    auth?.value?.role == 'admin' || auth?.value?.role == 'manager' ? <option value={'selected'}>Selected Users</option> : null
                                }

                            </select>
                        </div>
                    </div>
                    {
                        sendType == 'selected' ? (
                            <div className='row'>
                                <div className='col-12'>
                                    <div className="form-group mt-3 mb-3 ">
                                        <Multiselect
                                            placeholder="Select Users"
                                            options={users}
                                            onSelect={(list) => setSelectedUsers(list)}
                                            onRemove={(list) => setSelectedUsers(list)}
                                            displayValue="name"
                                        />
                                    </div>
                                </div>
                            </div>
                        ) : null
                    }
                    {
                        sendType == 'event_category' ? (
                            <div className='row'>
                                <div className='col-12'>
                                    <div className="form-group mt-3 mb-3 ">
                                        <select className='form-control' onChange={e => setCategory(e.target.value)} value={category ?? ''}>
                                            {
                                                categories.map((e, i) => <option key={i} value={e.id}>{e.category?.en}</option>)
                                            }
                                        </select>
                                    </div>
                                </div>
                            </div>
                        ) : null
                    }
                    {
                        sendType == 'event' ? (
                            <div className='row'>
                                <div className='col-12'>
                                    <div className="form-group mt-3 mb-3 ">
                                        <select className='form-control' onChange={e => setEvent(e.target.value)} value={event ?? ''}>
                                            {
                                                events.map((e, i) => <option key={i} value={e.id}>{e.name?.en}</option>)
                                            }
                                        </select>
                                    </div>
                                </div>
                            </div>
                        ) : null
                    }
                    <div className='row'>
                        <div className='col-12'>
                            <div className="form-group mt-3 mb-3 ">
                                <input type="email" className="form-control" value={title} placeholder="Enter Title" onChange={e => setTitle(e.target.value)} />
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-12'>
                            <div className="form-group mt-3 mb-3 ">
                                <textarea value={message} rows="5" cols="50" name="description" placeholder='Enter Message' className="form-control" onChange={(e) => setMessage(e.target.value)} />
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-12'>
                            <div className="form-group mt-3 mb-3 ">
                                <input type="url" className="form-control" value={url} placeholder="Enter url (optional)" onChange={e => setUrl(e.target.value)} />
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-12 d-flex justify-content-end'>
                            <button type='button' className='btn btn-sm btn-secondary' onClick={() => resetForm()}>Cancel</button>
                            <button className='btn btn-sm btn-success ml-1' onClick={handleSend}>
                                Send
                            </button>
                        </div>
                    </div>

                    {
                        formLoading ? <div className='model-overlay'>
                            <ThreeCircles
                                height="100"
                                width="100"
                                color="#4fa94d"
                                wrapperStyle={{}}
                                wrapperClass=""
                                visible={true}
                                ariaLabel="three-circles-rotating"
                                outerCircleColor=""
                                innerCircleColor=""
                                middleCircleColor=""
                            />
                        </div> : null
                    }

                </Box>
            </Modal>
        </>
    );
}

export default Notification;
