import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import Multiselect from 'multiselect-react-dropdown';
import { useTranslation } from 'react-i18next';
import OwlCarousel from 'react-owl-carousel';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import _ from 'lodash';

import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import BGImage11 from '../assets/fe/images/menubg.png';

import { API } from '../Helpers/ApiService';
import { DOMAIN_URL } from '../Helpers/Constants';
import { URL } from '../Helpers/URL';
import { RoutesPath } from '../Routes';
import moment from 'moment';
import { setUser } from '../reducers/authReducer';

const FeedbackFormFront = () => {
    const { t, i18n } = useTranslation();
    let { id } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth);
    const [searchParams] = useSearchParams();

    const [data, setData] = useState(null);
    const [form, setForm] = useState({
        feedbackFormId: id,
        question1: '',
        question2: '',
        question3: '',
        question4: '',
        comments: '',
    });
    const [isNotFound, setIsNotFound] = useState(false);

    useEffect(() => {
        if (id) {
            setData(null);
            API({
                url: URL.FEEDBACK_FORM_GET + id,
                method: 'GET',
            }).then(res => {
                if (res?.code == 404) {
                    setIsNotFound(true)
                    return;
                }
                setData(res);
            });
        } else {
            setIsNotFound(true)
        }
    }, [id]);

    const submitFeedback = () => {
        API({
            url: URL.FEEDBACK_SEND,
            method: 'POST',
            data: form
        }).then(res => {
            if (res?.code == 404) {
                toast.error(res.message);
                return;
            }
            API({
                url: URL.ME,
                method: 'GET',
            }).then(resp => {
                if (resp?.code) {
                    return;
                }
                dispatch(setUser(resp));
                navigate(`/account?tab=feedback`);
            })
        });
    }

    return (
        <>
            <section className="page-title content-page-title" style={searchParams.get('hidelayout') ? { backgroundImage: "url(" + BGImage11 + ")", marginTop: 0 } : { backgroundImage: "url(" + BGImage11 + ")" }} >
                <div className="anim-icons full-width">
                    <span className="icon icon-bull-eye"></span>
                    <span className="icon icon-dotted-circle"></span>
                </div>
                <div className="auto-container">
                    <div className="title-outer">
                        <h1>Feedback</h1>
                    </div>
                </div>
            </section>
            <section className="about-section-two">
                <div className="auto-container">
                    <div className="row">
                        <div className="content-column col-lg-12 col-md-12 col-sm-12">
                            {
                                isNotFound ? <div className="row">
                                    <div className='col-12 text-center'>
                                        <h3>Form not found</h3>
                                    </div>
                                </div> : <div className="row" style={{ border: '1px solid #000', borderRadius: 10, padding: 10 }}>
                                    <div className="col-12 text-right">
                                        <span>Date:</span><span><u>{moment(data?.date).format('DD-MM-YYYY HH:mm a')}</u></span>
                                    </div>
                                    <div className="col-12 text-left">
                                        <span>Topic:</span><span><u>{data?.topic[auth.language]}</u></span>
                                    </div>
                                    <div className="col-12 text-left">
                                        <span>Speaker(s):</span><span><u>{data?.speakerObj?.name}</u></span>
                                    </div>
                                    <div className="col-12 text-left">
                                        <p className="mt-3">Please rate the following categories on the scale from 1 to 5 (check appropriate number)</p>

                                        <table width={'100%'}>
                                            <tbody>
                                                <tr>
                                                    <td width={'50%'}></td>
                                                    <td width={'20%'} colSpan={2}>Poor</td>
                                                    <td width={'20%'} colSpan={2}>Adequate</td>
                                                    <td width={'10%'}>Excellent</td>
                                                </tr>
                                                {
                                                    [
                                                        'Whether topic was relevant and useful:',
                                                        'Whether speaker/panel was knowledgeable and effective:',
                                                        'Whether content was presented in an interesting manner:',
                                                        'Whether session was well organized:'
                                                    ].map((e, i) => <tr key={i} >
                                                        <td><b>{e}</b></td>
                                                        <td
                                                            className="text-center cusror-pointer"
                                                            onClick={() => {
                                                                var data = _.cloneDeep(form)
                                                                data['question' + (i + 1)] = 1
                                                                setForm(data)
                                                            }}
                                                        >
                                                            <span style={form['question' + (i + 1)] == 1 ? {
                                                                background: '#001376',
                                                                color: '#fff',
                                                                borderRadius: 100,
                                                                padding: '2px 7px'
                                                            } : {}}>1</span>
                                                        </td>
                                                        <td className="text-center cusror-pointer" onClick={() => {
                                                            var data = _.cloneDeep(form)
                                                            data['question' + (i + 1)] = 2
                                                            setForm(data)
                                                        }}>
                                                            <span style={form['question' + (i + 1)] == 2 ? {
                                                                background: '#001376',
                                                                color: '#fff',
                                                                borderRadius: 100,
                                                                padding: '2px 7px'
                                                            } : {}}>2</span>
                                                        </td>
                                                        <td className="text-center cusror-pointer" onClick={() => {
                                                            var data = _.cloneDeep(form)
                                                            data['question' + (i + 1)] = 3
                                                            setForm(data)
                                                        }}>
                                                            <span style={form['question' + (i + 1)] == 3 ? {
                                                                background: '#001376',
                                                                color: '#fff',
                                                                borderRadius: 100,
                                                                padding: '2px 7px'
                                                            } : {}}>3</span>
                                                        </td>
                                                        <td className="text-center cusror-pointer" onClick={() => {
                                                            var data = _.cloneDeep(form)
                                                            data['question' + (i + 1)] = 4
                                                            setForm(data)
                                                        }}>
                                                            <span style={form['question' + (i + 1)] == 4 ? {
                                                                background: '#001376',
                                                                color: '#fff',
                                                                borderRadius: 100,
                                                                padding: '2px 7px'
                                                            } : {}}>4</span>
                                                        </td>
                                                        <td className="text-center cusror-pointer" onClick={() => {
                                                            var data = _.cloneDeep(form)
                                                            data['question' + (i + 1)] = 5
                                                            setForm(data)
                                                        }}>
                                                            <span style={form['question' + (i + 1)] == 5 ? {
                                                                background: '#001376',
                                                                color: '#fff',
                                                                borderRadius: 100,
                                                                padding: '2px 7px'
                                                            } : {}}>5</span>
                                                        </td>
                                                    </tr>)
                                                }
                                            </tbody>
                                        </table>

                                        <textarea rows="5" cols="50" value={form.comments} name="description" placeholder='Enter additional comments' className="form-control mb-4 mt-4" onChange={(e) => {
                                            var data = _.cloneDeep(form)
                                            data['comments'] = e.target.value
                                            setForm(data)
                                        }} />

                                    </div>
                                    {
                                        form.question1 && form.question2 && form.question3 && form.question4 ? <div className="col-12">
                                            <span className="theme-btn btn-style-one " onClick={submitFeedback} style={{ cursor: 'pointer' }} >
                                                <span className="btn-title color-white" >
                                                    Submit
                                                </span >
                                            </span>
                                        </div> : null
                                    }

                                </div>
                            }
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
export default FeedbackFormFront;
