import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import BackGround11 from '../assets/fe/images/background/11.jpg';
import Train from '../assets/fe/images/train.jpg';
import Artborad from '../assets/fe/images/Artboard_Final.png';

import { setUser } from "../reducers/authReducer";
import { API } from "../Helpers/ApiService";
import { RoutesPath } from "../Routes";
import { URL } from "../Helpers/URL";

const LoginNew = () => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth);

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    useEffect(() => {
        if (auth?.value?.firstName) {
            navigate(`${RoutesPath.Home.path}`);
        }
    }, []);

    const onSubmit = (e) => {
        e.preventDefault();
        const payload = {
            email: email,
            password: password
        }
        API({
            url: URL.LOGIN,
            method: 'POST',
            data: payload
        }).then(res => {
            if (res?.code) {
                toast.error(res.message)
                return;
            }
            dispatch(setUser(res.user));
            localStorage.setItem('token', res.tokens.access.token);
            toast.success('Logged in successfully!');

            if (res?.user?.role != 'user') {
                window.location.href = RoutesPath.Dashboard.path
            } else {
                if (!res.user?.isPasswordChanged) {
                    navigate(`/account?tab=password`);
                } else {
                    if (location.state?.redirect) {
                        navigate(location.state?.redirect)
                    } else {
                        navigate(`${RoutesPath.Home.path}`);
                    }
                }
            }

        })
    }

    return (
        <>
            <center>
                <section className="register-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-7 col-sm-12 d-flex justify-content-center align-items-center">
                                <div className="row mt-2 justify-content-center">
                                    <div className="col-12 justify-content-center d-flex">
                                        <img src={Artborad} width={150} />
                                    </div>
                                    {/* <div className="col-6 justify-content-center d-flex">
                                    <img src={require('../assets/fe/images/logoaipdm.png')} width={150} />
                                </div> */}
                                    <h2 className="mt-2">{t('login_now')}</h2>
                                    <div className="styled-form register-form mt-2 pt-4 reg-form" style={{ width: '80%' }}>
                                        <form onSubmit={onSubmit}>
                                            <div className="form-group">
                                                <span className="adon-icon">
                                                    <span className="fa fa-envelope"></span>
                                                </span>
                                                <input type="email" name="email" placeholder={t('email')}
                                                    style={{ backgroundColor: '#E2E9FF' }} onChange={e => setEmail(e.target.value)} />
                                            </div>
                                            <div className="form-group mt-3">
                                                <span className="adon-icon">
                                                    <span className="fa fa-unlock-alt"></span>
                                                </span>
                                                <input type="password" name="password" placeholder={t('password')}
                                                    style={{ backgroundColor: '#E2E9FF' }} onChange={e => setPassword(e.target.value)} />
                                            </div>
                                            <div className="clearfix">
                                                <div className="pull-left">
                                                    <input type="checkbox" id="remember-me" /><label className="remember-me"
                                                        htmlFor="remember-me">&nbsp; {t('remamber_me')}</label>
                                                </div>
                                                <div className="pull-right">
                                                    <Link to={RoutesPath.ForgotPassword.path}><u>{t('reset_password')}</u></Link>
                                                </div>
                                            </div><br />
                                            <div className="clearfix">
                                                <div className="form-group">
                                                    <button type="submit" className="theme-btn btn-style-one"><span
                                                        className="btn-title">{t('login')}</span></button>
                                                </div>

                                            </div>
                                            {/* <div className="form-group">
                                            ---------------------------- {t('or')} ----------------------------<br />
                                            <Link to={RoutesPath.Register.path}><u>{t('register_now')}</u></Link>
                                        </div> */}
                                            {/* <div className="form-group social-links-two">
                                            {t('download_register')}:<br />
                                            <a href="https://play.google.com/store/apps/details?id=com.ccs.uic" className="img-circle facebook" style={{ backgroundColor: '#000', fontSize: 25 }}>
                                                <span className="fab fa-android"></span></a>
                                            <a href="https://apps.apple.com/in/app/uic-wsc-2023/id1671784602" target={'_blank'} className="img-circle facebook" style={{ backgroundColor: '#000', fontSize: 25 }}><span
                                                className="fab fa-apple"></span></a>

                                        </div> */}
                                            {/* <div className="form-group social-links-two">
                                            {t('for_any_inqury_reach_us')}:<br />
                                            <a href="mailto:uic2023.rpf@gmail.com" className="img-circle facebook" style={{ backgroundColor: '#000' }}><span
                                                className="fa fa-envelope"></span></a>
                                            <a href="https://wa.me/919724094709" target={'_blank'} className="img-circle facebook" style={{ backgroundColor: '#000' }}><span
                                                className="fab fa-whatsapp"></span></a>

                                        </div> */}
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-5 col-sm-12 text-right d-flex align-items-center justify-content-end">
                                <img src={require('../assets/fe/images/register.png')} style={{ width: '72%' }} />
                            </div>
                        </div>
                    </div>
                </section>
            </center>
        </>
    );
}
export default LoginNew;
