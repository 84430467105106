import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams, useSearchParams } from "react-router-dom";

import BGImage11 from '../assets/fe/images/menubg.png';
import Result from '../assets/fe/images/result.jpg';

import { RoutesPath } from "../Routes";
import OwlCarousel from 'react-owl-carousel';
import { API } from "../Helpers/ApiService";
import { URL } from "../Helpers/URL";
import { useSelector } from "react-redux";
import moment from "moment";
import { DOMAIN_URL } from "../Helpers/Constants";


const EventsDetails = () => {
    let { id } = useParams();
    const auth = useSelector(state => state.auth);
    const { t, i18n } = useTranslation();
    const [searchParams] = useSearchParams();

    const [event, setEvent] = useState(null);
    const [users, setUsers] = useState([]);
    const [winners, setWinners] = useState([]);

    useEffect(() => {
        getEventDetail();
    }, [])

    const getEventDetail = () => {
        API({
            url: URL.EVENTS_DETAILS + id,
            method: 'GET',
        }).then(res => {
            setEvent(res)
        });

        API({
            url: URL.EVENT_USERS + id,
            method: 'GET',
        }).then(res => {
            setUsers(res)
        });
        API({
            url: URL.GET_WINNERS_EVENT + id,
            method: 'GET',
        }).then(res => {
            setWinners(res)
        });
    }

    const isDocVisible = () => {
        var flag = false;
        if (event?.judgeDocument?.length) {
            event?.judgeDocument?.map(e => {
                if (e.show) {
                    flag = true;
                }
            });
        }
        return flag;
    }

    const getFileName = (name) => {
        const parts = name.split('/');
        return parts[parts?.length - 1]
    }

    return (
        <>
            <section className="page-title" style={searchParams.get('hidelayout') ? { backgroundImage: "url(" + BGImage11 + ")", marginTop: 0 } : { backgroundImage: "url(" + BGImage11 + ")" }}>
                <div className="anim-icons full-width">
                    <span className="icon icon-bull-eye"></span>
                    <span className="icon icon-dotted-circle"></span>
                </div>
                <div className="auto-container">
                    <div className="title-outer">
                        {
                            event?.name ? <h1>{event?.name[auth.language]}</h1> : null
                        }
                    </div>
                </div>
            </section>
            <center>
                <section className="register-section" style={{ overflowY: 'auto', minHeight: '63vh' }}>
                    <div className="auto-container">
                        <div className="col-12 event-list">
                            <section className="about-section-two pt-lg-0">
                                <div className="auto-container">
                                    <div className="row">
                                        <div className="col-12 border-table">
                                            {
                                                event ? <>
                                                    <div className="text cms-text mb-5" dangerouslySetInnerHTML={{ __html: event?.description[auth.language] }}></div>

                                                    {winners?.length || isDocVisible() ? <h4 className="mt-5">{t('results')}</h4> : null}
                                                    {
                                                        winners?.length ? <>
                                                            {/* <h4 className="mt-5">{t('winners')}</h4> */}
                                                            <table width={'100%'} className="text-center mt-3" border={1}>
                                                                <tr className="color-blue">
                                                                    <th>{t('position')}</th>
                                                                    <th>{t('name')}</th>
                                                                    <th>{t('rank')}</th>
                                                                    <th>{t('organization')}</th>
                                                                    <th>{t('score')}</th>
                                                                </tr>
                                                                {
                                                                    winners?.map((e, i) => <tr key={i}>
                                                                        <td className="color-blue">{e.position}</td>
                                                                        <td>
                                                                            {
                                                                                e.users.map((user, i) => <span key={'user' + i}> {i != 0 ? ', ' : ''}{user.firstName + ' ' + user?.surname}</span>)
                                                                            }
                                                                        </td>
                                                                        <td>
                                                                            {
                                                                                e.users.map((user, i) => <span key={'rank' + i}> {i != 0 ? ', ' : ''}{user.rank.rank.en}</span>)
                                                                            }
                                                                        </td>
                                                                        <td>
                                                                            {
                                                                                e.users.map((user, i) => <span key={'state' + i}> {i != 0 ? ', ' : ''}{user.state.state.en}</span>)
                                                                            }
                                                                        </td>
                                                                        <td>{e.score}</td>
                                                                    </tr>)
                                                                }
                                                            </table>
                                                        </> : null
                                                    }
                                                    {
                                                        isDocVisible() ? <div className="mt-3">
                                                            {
                                                                event?.judgeDocument?.map((e, i) => <>
                                                                    {
                                                                        e.show ? <a target='_blank' href={DOMAIN_URL + e.file}>
                                                                            <div className="justify-content-between align-items-center d-flex mt-2"
                                                                                style={{
                                                                                    backgroundColor: '#001376',
                                                                                    color: '#fff',
                                                                                    fontWeight: '600',
                                                                                    padding: 10,
                                                                                    borderRadius: 5
                                                                                }}>
                                                                                <span>{getFileName(e.file)}</span>
                                                                                <i style={{ color: '#fff' }} className="fa fa-arrow-right"></i>
                                                                            </div>
                                                                        </a> : null
                                                                    }
                                                                </>)
                                                            }
                                                        </div> : null
                                                    }
                                                    <h4 className="mt-5">{t('candidates')}</h4>
                                                    {
                                                        users?.length ? <>
                                                            <table width={'100%'} className="text-center mt-3" border={1}>
                                                                <tr className="color-blue">
                                                                    <th>{t('name')}</th>
                                                                    <th>{t('rank')}</th>
                                                                    <th>{t('organization')}</th>
                                                                    {
                                                                        event?.status == 'Scheduled' ? <th>{t('main_reserve')}</th> : null
                                                                    }
                                                                    <th>{t('participated_not_participated')}</th>
                                                                </tr>
                                                                {
                                                                    users?.map((e, i) => <tr key={i} className={i % 2 != 0 ? 'background-grey' : ''}>
                                                                        <td>{e.name}</td>
                                                                        <td>{e.rank}</td>
                                                                        <td>{e.state}</td>
                                                                        {
                                                                            event?.status == 'Scheduled' ? <td>{e.candidateType ? t(e.candidateType) : '-'}</td> : null
                                                                        }
                                                                        <td>{e.att != '-' ? (e.att ? t('participated') : t('not_participated')) : '-'}</td>
                                                                    </tr>)
                                                                }
                                                            </table>
                                                        </> : null
                                                    }

                                                    <h4 className="mt-5">{t('glimpse_event')}{event?.name[auth.language]}</h4>
                                                    {
                                                        event?.images?.length ? <OwlCarousel
                                                            className='owl-carousel owl-theme content-slider'
                                                            loop
                                                            margin={10}
                                                            dots={true}
                                                            items={3}
                                                            nav={true}
                                                            autoplay
                                                            autoplayTimeout={4000}
                                                            smartSpeed={450}
                                                        >
                                                            {
                                                                event?.images.map((e, i) => <div key={i} className='item'>
                                                                    <img className='img-responsive' src={DOMAIN_URL + e.image} />
                                                                    {
                                                                        e?.caption ? <p className="text-center">{e.caption}</p> : null
                                                                    }
                                                                </div>)
                                                            }

                                                        </OwlCarousel> : null
                                                    }

                                                </> : null
                                            }
                                            {/* {
                                                event?.status == 'Completed' ? <div className="col-12">
                                                    <img src={Result} />
                                                </div> : null
                                            } */}
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </section>
            </center>
        </>
    );
}
export default EventsDetails;
