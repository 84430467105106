export const EMAIL_REGEX = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/

export const APP_NAME = 'Rent Buddy';
export const modelBoxStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    // width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    paddingTop: 2,
    paddingBottom: 2,
    paddingRight: 4,
    paddingLeft: 4,
};

const isHttps = () => {
    return location.protocol == 'https:' ? true : false;
}

const findServerUrl = () => {
    if (location.host.includes('localhost')) {
        return 'http://localhost:3333/';
    } else {
        return 'https://api.67thaipdm.org/';
    }
}

// BASE URL
export const DOMAIN_URL = findServerUrl();

export const BASE_URL = DOMAIN_URL + 'v1/'


export const availableLanguages = ['en', 'hi']