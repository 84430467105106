import { useEffect, useState } from 'react';
import { ThreeCircles } from 'react-loader-spinner';
import { confirmAlert } from 'react-confirm-alert';
import DataTable from 'react-data-table-component';
import { Box, Modal } from '@mui/material';
import { toast } from 'react-toastify';
import _ from 'lodash';

import { BASE_URL, DOMAIN_URL, modelBoxStyle } from '../../Helpers/Constants';
import { API } from '../../Helpers/ApiService';
import { RoutesPath } from "../../Routes";
import { URL } from '../../Helpers/URL';
import Multiselect from 'multiselect-react-dropdown';
import { useSelector } from 'react-redux';
import moment from 'moment';


const Users = () => {
    const auth = useSelector(state => state.auth);

    const fd = {
        firstName: "",
        surname: "",
        rank: "",
        state: "",
        email: "",
        mobile: "",
        events: [],
        role: "user",
        photo: null,
        officialIdProof: null,
        beltNumber: '',
        type: 'Human',
        animalName: '',
        sexOfAnimal: '',
        dobOfAnimal: '',
        breedOfAnimal: '',
        marking: '',
        chestNo: '',
        animalCertificate: null,
        pedigreePaper: null,
        food: 'Vegetarian',
        candidateType: 'Main',
        password: '',
    }

    const columns = [
        {
            name: 'Name',
            center: true,
            selector: row => row.firstName + ' ' + row.surname,
        },
        {
            name: 'Rank',
            center: true,
            selector: row => row?.rank?.rank?.en ?? '',
        },
        {
            name: 'Organization',
            center: true,
            selector: row => row?.state?.state?.en,
        },
        {
            name: 'Email',
            center: true,
            selector: row => row.email,
        },
        {
            name: 'Mobile',
            center: true,
            selector: row => row?.mobile ?? '',
        },
        {
            name: 'Action',
            center: true,
            cell: row => <ActionColumn row={row} />
        },
    ];
    const [createData, setCreateData] = useState(null);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [model, setModel] = useState(null);
    const [certModel, setCertModel] = useState(null);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);

    const [createModel, setCreateModel] = useState(null);
    const [form, setForm] = useState(fd);
    const [formLoading, setFormLoading] = useState(false);
    const [ranks, setRanks] = useState([]);
    const [states, setStates] = useState([]);
    const [events, setEvents] = useState([]);
    const [terms, setTerms] = useState([]);
    const [search, setSearch] = useState('');
    const [rank, setRank] = useState('');
    const [state, setState] = useState('');
    const [event, setEvent] = useState('');
    const [role, setRole] = useState('');
    const [type, setType] = useState('');
    const [food, setFood] = useState('');
    const [candidateType, setCandidateType] = useState('');

    useEffect(() => {
        getData();
    }, [perPage]);

    useEffect(() => {
        getData();
    }, [rank, state, search, event, role, type, food, candidateType]);

    useEffect(() => {
        if (form.role) {
            if ((form.role != 'user' || form.role != 'judge') && form?.candidateType == 'Main') {
                setFormData('Reserve', 'candidateType')
            }
        }
    }, [form.role]);

    useEffect(() => {
        getExtraData();
    }, []);

    useEffect(() => {
        setFormData('user', 'role')
        // setFormData([], 'events')
    }, [form.type]);

    const getData = (page = 1) => {
        setLoading(true);
        API({
            url: URL.USERS + '?page=' + page + '&limit=' + perPage + '&search=' + search + '&rank=' + rank + '&state=' + state + '&event=' + event + '&role=' + role + '&type=' + type + '&food=' + food + '&candidateType=' + candidateType,
            method: 'GET',
        }).then(res => {
            setLoading(false);
            if (res?.code) {
                return;
            }
            setTotalRows(res.totalResults);
            setData(res.results);
        });

    }

    const getExtraData = () => {
        API({
            url: URL.GET_RANKS,
            method: 'GET',
        }).then(res => {
            if (res?.code) {
                toast.error(res.message);
                return;
            }
            setRanks(res);
        });
        API({
            url: URL.GET_STATES,
            method: 'GET',
        }).then(res => {
            if (res?.code) {
                toast.error(res.message);
                return;
            }
            setStates(res);
        });
        API({
            url: URL.GET_EVENTS,
            method: 'GET',
        }).then(res => {
            if (res?.code) {
                toast.error(res.message);
                return;
            }
            const tempEvent = [];
            res.map(e => {
                tempEvent.push({
                    name: e.name.en + ' (' + e.category.category.en + ')',
                    id: e.id,
                    terms: e.terms ?? []
                })
            })
            setEvents(tempEvent);
        });
    }

    const handleCreate = () => {
        setCreateModel({
            mode: 'create',
        });
    }

    const handleEdit = (data) => {
        API({
            url: URL.USERS + '/' + data?.id,
            method: 'GET',
        }).then(res => {
            if (res?.code) {
                toast.error(res.message)
                return;
            }
            var tempFd = _.cloneDeep(fd);
            tempFd.firstName = res.firstName ?? '';
            tempFd.surname = res.surname ?? '';
            tempFd.email = res.email ?? '';
            tempFd.rank = res.rank ?? '';
            tempFd.state = res.state ?? null;
            tempFd.mobile = res.mobile ?? '';
            tempFd.role = res.role ?? '';
            tempFd.photo = null;
            tempFd.officialIdProof = null;
            tempFd.beltNumber = res.beltNumber ?? '';
            tempFd.events = events.filter(e => res.events.includes(e.id));
            tempFd.type = res.type ?? '';
            tempFd.animalName = res.animalName ?? '';
            tempFd.chestNo = res.chestNo ?? '';
            tempFd.sexOfAnimal = res.sexOfAnimal ?? '';
            tempFd.dobOfAnimal = moment(res.dobOfAnimal).format('YYYY-MM-DD') ?? '';
            tempFd.breedOfAnimal = res.breedOfAnimal ?? '';
            tempFd.marking = res.marking ?? '';
            tempFd.candidateType = res.candidateType ?? 'Main';
            tempFd.animalCertificate = null;
            tempFd.pedigreePaper = null;

            setForm(tempFd);
            setCreateModel({
                mode: 'edit',
                editId: data.id
            });
        });
    }

    const handleSave = () => {
        var payload = _.cloneDeep(form);

        if (createModel?.mode == 'edit' && auth?.value?.role == 'manager') {
            if (!confirm('Are you sure want to update?')) {
                return;
            }
        }
        let isUnchecked = false;
        terms.map(e => {
            e.terms.map(term => {
                if (!term.checked && !isUnchecked) {
                    isUnchecked = true;
                }
            });
        });

        if (isUnchecked && payload.role == 'user') {
            toast.error('Please check all terms!');
            return;
        }

        payload.events = payload.events.map(e => { return e.id });
        if (payload.role != 'user') {
            payload.events = [];
        }
        if (payload.type == 'Animal') {
            delete payload?.mobile;
            delete payload?.photo;
            delete payload?.beltNumber;
        } else {
            delete payload?.animalName;
            delete payload?.sexOfAnimal;
            delete payload?.dobOfAnimal;
            delete payload?.breedOfAnimal;
            delete payload?.marking;
            delete payload?.chestNo;
            delete payload?.animalCertificate;
            delete payload?.pedigreePaper;
        }

        if (createModel?.mode != 'edit' || !payload.password) {
            delete payload.password;
        }

        setFormLoading(true);
        API({
            url: createModel?.mode == 'edit' ? URL.USERS + '/' + createModel?.editId : URL.USERS,
            method: createModel?.mode == 'edit' ? 'PATCH' : 'POST',
            data: payload,
            formData: true
        }).then(res => {
            setFormLoading(false);
            if (res?.code) {
                toast.error(res.message)
                return;
            }
            resetForm();
        });

    }

    const resetForm = () => {
        setCreateModel(null);
        setForm(fd);
        getData();
    }

    const setFormData = (value, name) => {
        let data = _.cloneDeep(form);
        data[name] = value;
        setForm(data);
    }

    const ActionColumn = ({ row }) => {
        const handleView = () => {
            let index = data.findIndex(e => e.id == row.id);
            if (index != -1) {
                setModel({
                    index: index,
                });
            }
        }
        const handleAccept = () => {
            API({
                url: URL.USERS + '/' + row.id,
                method: 'PATCH',
                data: { isAccepted: true }
            }).then(res => {
                if (res?.code) {
                    toast.error(res.message)
                    return;
                }
                toast.success('User approved successfully!')
                getData();
            });
        }
        const handleDelete = () => {
            if (!confirm('Are you sure want to delete?')) {
                return;
            }
            API({
                url: URL.USERS + '/' + row.id,
                method: 'DELETE',
            }).then(res => {
                if (res?.code) {
                    toast.error(res.message)
                    return;
                }
                toast.success('User deleted successfully!')
                getData();
            });
        }
        return (
            <>
                {
                    auth?.value?.role == "admin" && (row?.participationCertificates?.length || row?.winnerCertificates?.length) ? <button className='ml-1 btn btn-info btn-sm' title='certificates' onClick={() => setCertModel(row)}>< i className='fa fa-certificate' /></button> : null
                }

                <button className='ml-1 btn btn-info btn-sm' onClick={handleView}>< i className='fa fa-eye' /></button>
                {
                    auth?.value?.role == "admin" ? <>
                        <button className='btn btn-info btn-sm ml-1' onClick={() => handleEdit(row)}>< i className='fa fa-edit' /></button>
                        <button className='btn btn-danger btn-sm ml-1' onClick={() => handleDelete(row)}>< i className='fa fa-trash' /></button>
                    </> : null
                }
                {
                    !row?.isAccepted && auth?.value?.role != "manager" ? <button className='ml-1 btn btn-success btn-sm' onClick={handleAccept}>< i className='fa fa-check' /></button> : null
                }

            </>
        );
    }

    const exportUser = () => {
        API({
            url: URL.EXPORT_USER,
            method: 'GET',
        }).then(res => {
            if (res?.code) {
                toast.error(res.message)
                return;
            }
            window.open(`${BASE_URL}${URL.DOWNLOAD_FILE}excel/${res.file}`, "_blank");
        });
    }

    const uploadCert = (e) => {
        let files = e.target.files;
        if (!files.length) {
            return;
        }
        let payload = { file: files[0] };
        API({
            url: URL.UPLOAD,
            method: 'POST',
            data: payload,
            formData: true
        }).then(res => {
            e.target.value = null;
            if (res?.code) {
                toast.error(res.message);
                return;
            }
            API({
                url: URL.USER_CERT_UPDATE,
                method: 'POST',
                data: {
                    id: certModel.id,
                    cert: 'uploads/' + res[0].filename
                },
                formData: true
            }).then(res1 => {
                if (res1?.code) {
                    toast.error(res.message);
                    return;
                }
                getData();
                setCertModel({ fileUrl: DOMAIN_URL + 'uploads/' + res[0].filename });
            });
        });
    }

    const onChangeEvents = (list) => {
        setFormData(list, 'events')
        let tempTerms = [];
        list.map(e => {
            if (e.terms?.length) {
                tempTerms.push({
                    name: e.name,
                    terms: e.terms.map(e => { return { term: e, checked: false } }),
                })
            }
        });
        setTerms(tempTerms);
    }

    const ExpandableRowComponent = ({ data }) => {
        return (
            <div className='exp-row'>
                <table >
                    <tr>
                        <th>Candidate Type</th>
                        <th>Registration Type</th>
                        <th>Role</th>
                    </tr>
                    <tr>
                        <td>{data.candidateType}</td>
                        <td>{data.type}</td>
                        <td>{data.role}</td>
                    </tr>
                </table>
            </div>
        );
    };

    return (
        <>
            <div className="content-header">
                <div className="container-fluid">
                    <div className='row'>
                        <div className="col-10">
                            <div className='row'>
                                <div className="col-3">
                                    <div className="form-group mt-1 mb-3 ">
                                        <label>Search</label>
                                        <input type="text" className="form-control" value={search} placeholder="Search" onChange={e => setSearch(e.target.value)} />
                                    </div>
                                </div>
                                <div className='col-3'>
                                    <div className="form-group mt-1 mb-3 ">
                                        <label>Rank</label>
                                        <select onChange={(e) => setRank(e.target.value)} value={rank} className="form-control">
                                            <option value="" disabled hidden>Select rank</option>
                                            {
                                                ranks?.map((element, index) => <option key={index} value={element.id}>{element.rank['en']}</option>)
                                            }
                                        </select>
                                    </div>
                                </div>
                                <div className='col-3'>
                                    <div className="form-group mt-1 mb-0 ">
                                        <label>Organization</label>
                                        <select onChange={(e) => setState(e.target.value)} value={state} className="form-control">
                                            <option value="" disabled hidden>Select Organization</option>
                                            {
                                                states?.map((element, index) => <option key={index} value={element.id}>{element.state['en']}</option>)
                                            }
                                        </select>
                                    </div>
                                </div>
                                <div className='col-3'>
                                    <div className="form-group mt-1 mb-0 ">
                                        <label>Event</label>
                                        <select onChange={(e) => setEvent(e.target.value)} value={event} className="form-control">
                                            <option value="" disabled hidden>Select Event</option>
                                            {
                                                events?.map((element, index) => <option key={index} value={element.id}>{element.name}</option>)
                                            }
                                        </select>
                                    </div>
                                </div>
                                <div className='col-3'>
                                    <div className="form-group mt-1 mb-0 ">
                                        <label>Role</label>
                                        <select onChange={(e) => setRole(e.target.value)} value={role} className="form-control">
                                            <option value="" disabled hidden>Select Role</option>
                                            <option value={'manager'}>Manager</option>
                                            <option value={'judge'}>Judge</option>
                                            <option value={'supportDesk'}>Support Desk</option>
                                            <option value={'user'}>User</option>
                                        </select>
                                    </div>
                                </div>
                                <div className='col-3'>
                                    <div className="form-group mt-1 mb-0 ">
                                        <label>Type</label>
                                        <select onChange={(e) => setType(e.target.value)} value={type} className="form-control">
                                            <option value="" disabled hidden>Select Type</option>
                                            <option value={'Animal'}>Animal</option>
                                            <option value={'Human'}>Human</option>
                                        </select>
                                    </div>
                                </div>
                                <div className='col-3'>
                                    <div className="form-group mt-1 mb-0 ">
                                        <label>Food</label>
                                        <select onChange={(e) => setFood(e.target.value)} value={food} className="form-control">
                                            <option value="" disabled hidden>Select Food</option>
                                            <option value={'Vegetarian'}>Vegetarian</option>
                                            <option value={'Non-Vegetarian'}>Non Vegetarian</option>
                                        </select>
                                    </div>
                                </div>
                                <div className='col-3'>
                                    <div className="form-group mt-1 mb-0 ">
                                        <label>Candidate Type</label>
                                        <select onChange={(e) => setCandidateType(e.target.value)} value={candidateType} className="form-control">
                                            <option value="" disabled hidden>Select Food</option>
                                            <option value={'Main'}>Main</option>
                                            <option value={'Reserve'}>Reserve</option>
                                            <option value={'Other'}>Other</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-2 d-flex align-items-center">
                            <button className='btn btn-info mt-4' onClick={() => {
                                setRank('')
                                setState('')
                                setSearch('')
                                setEvent('')
                                setRole('')
                                setType('')
                                setFood('')
                                setCandidateType('')
                            }}>Clear Filter</button>
                        </div>
                    </div>
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="m-0">Users</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><a href={RoutesPath.Admin.Dashboard}>Home</a></li>
                                <li className="breadcrumb-item active">Users</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>

            <div className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card">
                                <div className="card-header">
                                    <div className='row'>
                                        <div className='col-6 d-flex align-items-center'>
                                            <h3 className="card-title">Users Listing</h3>
                                        </div>
                                        <div className='col-6 d-flex justify-content-end'>
                                            <button className='btn btn-secondary' onClick={exportUser}>
                                                Export Excel
                                            </button>
                                            {
                                                auth?.value?.role == "admin" ? <button className='btn btn-primary ml-2' onClick={handleCreate}>
                                                    Register
                                                </button> : null
                                            }

                                        </div>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className='row'>
                                        <div className='col-12'>
                                            <DataTable
                                                columns={columns}
                                                data={data}
                                                progressPending={loading}
                                                pagination
                                                paginationServer
                                                paginationRowsPerPageOptions={[10, 25, 50, 100, 200]}
                                                paginationTotalRows={totalRows}
                                                onChangeRowsPerPage={(newPerPage, page) => { setPerPage(newPerPage); }}
                                                onChangePage={page => getData(page)}
                                                expandableRows
                                                expandableRowsComponent={ExpandableRowComponent}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Modal
                open={model ? true : false}
                onClose={() => setModel(null)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={modelBoxStyle}>
                    <div className='row'>
                        <div className='col-6'>
                            <h5>User Details</h5>
                        </div>
                        <div className='col-6'>
                            <i style={{
                                position: 'absolute',
                                top: -15,
                                right: -15,
                                cursor: 'pointer',
                                padding: 10,
                            }} className='fa fa-times' onClick={() => setModel(null)} />
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-12'>
                            {
                                data[model?.index]?.type == 'Animal' ? <table border={0} width={500}>
                                    <tr>
                                        <td>Handler Name:</td>
                                        <td>{data[model?.index]?.firstName + ' ' + data[model?.index]?.surname}</td>
                                    </tr>
                                    <tr>
                                        <td>Handler Rank:</td>
                                        <td>{data[model?.index]?.rank?.rank?.en}</td>
                                    </tr>
                                    <tr>
                                        <td>Handler Email:</td>
                                        <td>{data[model?.index]?.email}</td>
                                    </tr>
                                    <tr>
                                        <td>Organization:</td>
                                        <td>{data[model?.index]?.state?.state?.en}</td>
                                    </tr>
                                    <tr>
                                        <td>Animal Name:</td>
                                        <td>{data[model?.index]?.animalName}</td>
                                    </tr>
                                    <tr>
                                        <td>Sex Of Animal:</td>
                                        <td>{data[model?.index]?.sexOfAnimal}</td>
                                    </tr>
                                    <tr>
                                        <td>DOB Of Animal:</td>
                                        <td>{moment(data[model?.index]?.dobOfAnimal).format('DD-MM-YYYY')}</td>
                                    </tr>
                                    <tr>
                                        <td>Breed Of Animal:</td>
                                        <td>{data[model?.index]?.breedOfAnimal}</td>
                                    </tr>
                                    <tr>
                                        <td>Marking & Color:</td>
                                        <td>{data[model?.index]?.marking}</td>
                                    </tr>
                                    <tr>
                                        <td>Chest No.:</td>
                                        <td>{data[model?.index]?.chestNo}</td>
                                    </tr>
                                    <tr>
                                        <td>Food:</td>
                                        <td>{data[model?.index]?.food}</td>
                                    </tr>

                                    <tr>
                                        <td>Candidate Type:</td>
                                        <td>{data[model?.index]?.candidateType}</td>
                                    </tr>
                                    {
                                        data[model?.index]?.events?.length ? <tr>
                                            <td>Events:</td>
                                            <td>{
                                                data[model?.index]?.events?.map((e, i) => <span key={i}> {i != 0 ? ', ' : ''}{e.name.en} ({e.category.category.en})</span>)
                                            }</td>
                                        </tr> : null
                                    }


                                    {
                                        data[model?.index]?.photo ? (
                                            <tr>
                                                <td>Photograph of the Candidate:</td>
                                                <td>
                                                    <img src={DOMAIN_URL + data[model?.index]?.photo} width="200" />
                                                </td>
                                            </tr>
                                        ) : null
                                    }
                                    {
                                        data[model?.index]?.officialIdProof ? (
                                            <tr>
                                                <td>Official I'd Proof:</td>
                                                <td>
                                                    <img src={DOMAIN_URL + data[model?.index]?.officialIdProof} width="200" />
                                                </td>
                                            </tr>
                                        ) : null
                                    }
                                </table> : <table border={0} width={500}>
                                    <tr>
                                        <td>Name:</td>
                                        <td>{data[model?.index]?.firstName + ' ' + data[model?.index]?.surname}</td>
                                    </tr>
                                    <tr>
                                        <td>Rank:</td>
                                        <td>{data[model?.index]?.rank?.rank?.en}</td>
                                    </tr>
                                    <tr>
                                        <td>Organization:</td>
                                        <td>{data[model?.index]?.state?.state?.en}</td>
                                    </tr>
                                    <tr>
                                        <td>Email:</td>
                                        <td>{data[model?.index]?.email}</td>
                                    </tr>
                                    <tr>
                                        <td>Mobile:</td>
                                        <td>{data[model?.index]?.mobile}</td>
                                    </tr>
                                    <tr>
                                        <td>Official I'd No.:</td>
                                        <td>{data[model?.index]?.beltNumber}</td>
                                    </tr>
                                    <tr>
                                        <td>Food:</td>
                                        <td>{data[model?.index]?.food}</td>
                                    </tr>
                                    <tr>
                                        <td>Candidate Type:</td>
                                        <td>{data[model?.index]?.candidateType}</td>
                                    </tr>

                                    {
                                        data[model?.index]?.events?.length ? <tr>
                                            <td>Events:</td>
                                            <td>{
                                                data[model?.index]?.events?.map((e, i) => <span key={i}> {i != 0 ? ', ' : ''}{e.name.en} ({e.category.category.en})</span>)
                                            }</td>
                                        </tr> : null
                                    }

                                    {
                                        data[model?.index]?.photo ? (
                                            <tr>
                                                <td>Photograph of the Candidate:</td>
                                                <td>
                                                    <img src={DOMAIN_URL + data[model?.index]?.photo} width="200" />
                                                </td>
                                            </tr>
                                        ) : null
                                    }
                                    {
                                        data[model?.index]?.officialIdProof ? (
                                            <tr>
                                                <td>Official I'd Proof:</td>
                                                <td>
                                                    <img src={DOMAIN_URL + data[model?.index]?.officialIdProof} width="200" />
                                                </td>
                                            </tr>
                                        ) : null
                                    }
                                </table>
                            }
                        </div>
                    </div>
                </Box>
            </Modal>

            <Modal
                open={certModel ? true : false}
                // onClose={() => resetForm()}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={modelBoxStyle}>
                    <div className='row'>
                        <div className='col-12'>

                            {
                                certModel?.participationCertificates?.length ? <>
                                    <h4 className='text-center'>Participation Certificate</h4>
                                    <div className='row'>
                                        {
                                            certModel?.participationCertificates?.map((e, i) => <div key={i} className='col-12 d-flex flex-column align-items-center'>
                                                <img src={DOMAIN_URL + 'certificates/' + e.image} width={250} />
                                                <button className='ml-1 btn btn-info btn-sm mt-3' title='certificates' onClick={() => {
                                                    window.open(DOMAIN_URL + 'certificates/' + e.image, "_blank")
                                                }} >
                                                    < i className='fa fa-eye' />
                                                </button>
                                            </div>)
                                        }
                                    </div>
                                </> : null
                            }
                            {
                                certModel?.winnerCertificates?.length ? <>
                                    <h4 className='text-center mt-5'>Winner Certificate</h4>
                                    <div className='row'>
                                        {
                                            certModel?.winnerCertificates?.map((e, i) => <div key={i} className='col-12 d-flex flex-column align-items-center'>
                                                <img src={DOMAIN_URL + 'certificates/' + e.image} width={250} />
                                                <button className='ml-1 btn btn-info btn-sm mt-3' title='certificates' onClick={() => {
                                                    window.open(DOMAIN_URL + 'certificates/' + e.image, "_blank")
                                                }} >
                                                    < i className='fa fa-eye' />
                                                </button>
                                            </div>)
                                        }
                                    </div>
                                </> : null
                            }
                        </div>
                        <div className='col-12 text-right mt-4'>
                            <button className='btn btn-primary' onClick={() => setCertModel(null)}>Close</button>
                            {
                                !certModel?.isCertificatesApproved ? <button className='btn btn-success ml-2' onClick={() => {
                                    API({
                                        url: URL.APPROVE_CERTIFICATES + certModel.id,
                                        method: 'GET',
                                    }).then(res => {
                                        if (res?.code) {
                                            toast.error(res.message)
                                            return;
                                        }
                                        toast.success('Certificates approved successfully!')
                                        setCertModel(null);
                                        getData();
                                    });
                                }}>Approve</button> : null
                            }
                        </div>
                    </div>
                </Box>
            </Modal>

            <Modal
                open={createModel ? true : false}
                onClose={() => resetForm()}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={[modelBoxStyle, { width: '60%' }]}>
                    <div className='row'>
                        <div className='col-6'>
                            <h5>{createModel?.mode == 'create' ? 'Register' : 'Edit'}</h5>
                        </div>
                        <div className='col-6'>
                            <i style={{
                                position: 'absolute',
                                top: -15,
                                right: -15,
                                cursor: 'pointer',
                                padding: 10,
                            }} className='fa fa-times' onClick={() => resetForm()} />
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-6'>
                            <div className="form-group mt-3 mb-3 ">
                                <input type="radio" name='type' value={'Human'} checked={form.type == 'Human'} onChange={e => {
                                    if (e.target.checked) {
                                        setFormData('Human', 'type')
                                    }
                                }} /> Human
                            </div>
                        </div>
                        <div className='col-6'>
                            <div className="form-group mt-3 mb-3 ">
                                <input type="radio" name='type' value={'Animal'} checked={form.type == 'Animal'} onChange={e => {
                                    if (e.target.checked) {
                                        setFormData('Animal', 'type')
                                    }
                                }} /> Animal
                            </div>
                        </div>
                    </div>
                    <div className='row'>

                        <div className='col-6'>
                            <div className="form-group mt-1 mb-0 ">
                                <label>{form?.type == 'Animal' ? 'Handler' : ''} Firstname</label>
                                <input type="text" className="form-control" value={form.firstName} placeholder="Firstname" onChange={e => setFormData(e.target.value, 'firstName')} />
                            </div>
                        </div>
                        <div className='col-6'>
                            <div className="form-group mt-1 mb-0 ">
                                <label>{form?.type == 'Animal' ? 'Handler' : ''} Surname</label>
                                <input type="text" className="form-control" value={form.surname} placeholder="Surname" onChange={e => setFormData(e.target.value, 'surname')} />
                            </div>
                        </div>
                        <div className='col-6'>
                            <div className="form-group mt-1 mb-0 ">
                                <label>{form?.type == 'Animal' ? 'Handler' : ''} Email</label>
                                <input type="email" className="form-control" value={form.email} placeholder="Email" onChange={e => setFormData(e.target.value, 'email')} />
                            </div>
                        </div>
                        {
                            form.type == 'Human' ? <div className='col-6'>
                                <div className="form-group mt-1 mb-0 ">
                                    <label>Mobile</label>
                                    <input type="text" className="form-control" value={form.mobile} placeholder="Mobile" onChange={e => setFormData(e.target.value, 'mobile')} />
                                </div>
                            </div> : null
                        }
                        {
                            form.type == 'Human' ? <div className='col-6'>
                                <div className="form-group mt-1 mb-0 ">
                                    <label>Official I'd No.</label>
                                    <input type="text" className="form-control" value={form.beltNumber} placeholder="Official I'd No." onChange={e => setFormData(e.target.value, 'beltNumber')} />
                                </div>
                            </div> : null
                        }


                        <div className='col-6'>
                            <div className="form-group mt-1 mb-0 ">
                                <label>{form?.type == 'Animal' ? 'Handler' : ''} Rank</label>
                                <select onChange={(e) => setFormData(e.target.value, 'rank')} defaultValue={form.rank} className="form-control">
                                    <option value="" disabled hidden>Select rank</option>
                                    {
                                        ranks?.map((element, index) => <option key={index} value={element.id}>{element.rank['en']}</option>)
                                    }
                                </select>
                            </div>
                        </div>
                        <div className='col-6'>
                            <div className="form-group mt-1 mb-0 ">
                                <label>Organization</label>
                                <select onChange={(e) => setFormData(e.target.value, 'state')} defaultValue={form.state} className="form-control">
                                    <option value="" disabled hidden>Select Organization</option>
                                    {
                                        states?.map((element, index) => <option key={index} value={element.id}>{element.state['en']}</option>)
                                    }
                                </select>
                            </div>
                        </div>
                        {
                            form.role == 'user' ? <div className='col-6'>
                                <div className="form-group mt-1 mb-0 ">
                                    <label>Events</label>
                                    <Multiselect
                                        singleSelect={form.type == 'Animal'}
                                        className="register-input"
                                        selectedValues={form.events}
                                        options={events}
                                        onSelect={(list) => onChangeEvents(list)}
                                        onRemove={(list) => onChangeEvents(list)}
                                        displayValue="name"
                                    />
                                </div>
                            </div> : null
                        }


                        {
                            auth?.value?.role == "admin" && form.type == 'Human' ? <div className='col-6'>
                                <div className="form-group mt-1 mb-0 ">
                                    <label>Role</label>
                                    <select onChange={(e) => setFormData(e.target.value, 'role')} defaultValue={form.role} className="form-control" >
                                        <option value="" disabled hidden>Select role</option>
                                        <option value="user">User</option>
                                        <option value="manager">Manager</option>
                                        <option value="supportDesk">Support Desk</option>
                                        <option value="judge">Judge</option>
                                    </select>
                                </div>
                            </div> : null
                        }
                        {
                            form.type == 'Animal' ? <div className='col-6'>
                                <div className="form-group mt-1 mb-0 ">
                                    <label>Animal Name</label>
                                    <input type="text" className="form-control" value={form.animalName} placeholder="Animal Name" onChange={e => setFormData(e.target.value, 'animalName')} />
                                </div>
                            </div> : null
                        }
                        {
                            form.type == 'Animal' ? <div className='col-6'>
                                <div className="form-group mt-1 mb-0 ">
                                    <label>Animal Sex</label>
                                    <input type="text" className="form-control" value={form.sexOfAnimal} placeholder="Animal Sex" onChange={e => setFormData(e.target.value, 'sexOfAnimal')} />
                                </div>
                            </div> : null
                        }
                        {
                            form.type == 'Animal' ? <div className='col-6'>
                                <div className="form-group mt-1 mb-0 ">
                                    <label>Animal DOB</label>
                                    <input type="date" className="form-control" value={form.dobOfAnimal} placeholder="Select Date" onChange={e => setFormData(e.target.value, 'dobOfAnimal')} />
                                </div>
                            </div> : null
                        }
                        {
                            form.type == 'Animal' ? <div className='col-6'>
                                <div className="form-group mt-1 mb-0 ">
                                    <label>Animal Breed</label>
                                    <input type="text" className="form-control" value={form.breedOfAnimal} placeholder="Animal Breed" onChange={e => setFormData(e.target.value, 'breedOfAnimal')} />
                                </div>
                            </div> : null
                        }
                        {
                            form.type == 'Animal' ? <div className='col-6'>
                                <div className="form-group mt-1 mb-0 ">
                                    <label>Animal Marking & Color</label>
                                    <input type="text" className="form-control" value={form.marking} placeholder="Animal Marking & Color" onChange={e => setFormData(e.target.value, 'marking')} />
                                </div>
                            </div> : null
                        }
                        {
                            form.type == 'Animal' ? <div className='col-6'>
                                <div className="form-group mt-1 mb-0 ">
                                    <label>Chest No.</label>
                                    <input type="text" className="form-control" value={form.chestNo} placeholder="Chest No." onChange={e => setFormData(e.target.value, 'chestNo')} />
                                </div>
                            </div> : null
                        }
                        {
                            form.type == 'Animal' ? <div className='col-6'>
                                <div className="form-group  ">
                                    <label >Animal registration certificate</label><br />
                                    <input accept="image/*" type="file" onChange={(e) => setFormData(e.target.files[0], 'animalCertificate')} />
                                </div>
                            </div> : null
                        }
                        {
                            form.type == 'Animal' ? <div className='col-6'>
                                <div className="form-group  ">
                                    <label >Pedigree Paper</label><br />
                                    <input accept="image/*" type="file" onChange={(e) => setFormData(e.target.files[0], 'pedigreePaper')} />
                                </div>
                            </div> : null
                        }

                        {
                            createModel?.mode == 'edit' ? <div className='col-6'>
                                <div className="form-group mt-1 mb-0 ">
                                    <label>Password</label>
                                    <input type="text" className="form-control" value={form.password} placeholder="Password" onChange={e => setFormData(e.target.value, 'password')} />
                                </div>
                            </div> : null
                        }

                        {
                            form.type == 'Human' ? <div className='col-6'>
                                <div className="form-group  ">
                                    <label >Photograph of the Candidate</label><br />
                                    <input accept="image/*" type="file" onChange={(e) => setFormData(e.target.files[0], 'photo')} />
                                </div>
                            </div> : null
                        }

                        <div className='col-6'>
                            <div className="form-group  ">
                                <label >Official I'd Proof</label><br />
                                <input accept="image/*" type="file" onChange={(e) => setFormData(e.target.files[0], 'officialIdProof')} />
                            </div>
                        </div>

                    </div>
                    <div className='row'>
                        <div className='col-6'>
                            <div className="form-group mt-2 mb-2 ">
                                <label>Food</label><br />
                                <input type="radio" name='food' value={'Vegetarian'} checked={form.food == 'Vegetarian'} onChange={e => {
                                    if (e.target.checked) {
                                        setFormData('Vegetarian', 'food')
                                    }
                                }} /> Vegetarian
                                <input type="radio" className='ml-5' name='food' value={'Non-Vegetarian'} checked={form.food == 'Non-Vegetarian'} onChange={e => {
                                    if (e.target.checked) {
                                        setFormData('Non-Vegetarian', 'food')
                                    }
                                }} /> Non-Vegetarian
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-6'>
                            <div className="form-group mt-2 mb-2 ">
                                <label>Candidate type</label><br />
                                <input type="radio" name='candidateType' value={'Main'} checked={form.candidateType == 'Main'} onChange={e => {
                                    if (e.target.checked) {
                                        setFormData('Main', 'candidateType')
                                    }
                                }} disabled={!['user', 'judge'].includes(form.role)} /> Main
                                <input type="radio" className='ml-5' name='candidateType' value={'Reserve'} checked={form.candidateType == 'Reserve'} onChange={e => {
                                    if (e.target.checked) {
                                        setFormData('Reserve', 'candidateType')
                                    }
                                }} /> Reserve
                                <input type="radio" className='ml-5' name='candidateType' value={'Other'} checked={form.candidateType == 'Other'} onChange={e => {
                                    if (e.target.checked) {
                                        setFormData('Other', 'candidateType')
                                    }
                                }} /> Other
                            </div>
                        </div>
                    </div>
                    {
                        form.role == 'user' ? <div className='row'>
                            <div className='col-12'>
                                {
                                    terms.map((e, i) => <div key={i} className='row'>
                                        <div className='col-12'><b><p className='mb-0 mt-3'>{i + 1}. {e.name}</p></b></div>
                                        {
                                            e.terms.map((term, index) => <div className='col-12 pl-4 d-flex' key={'inner' + index}>
                                                <input type="checkbox" checked={term?.checked} onChange={(e) => {
                                                    let data = _.cloneDeep(terms);
                                                    data[i].terms[index].checked = e.target.checked;
                                                    setTerms(data);
                                                }} />
                                                <p className='ml-2 mb-0'>{index + 1}. {term.term}</p>
                                            </div>)
                                        }
                                    </div>)
                                }
                            </div>
                        </div> : null
                    }

                    <div className='row'>
                        <div className='col-12 d-flex justify-content-end'>
                            <button type='button' className='btn btn-sm btn-secondary' onClick={() => resetForm()}>Cancel</button>
                            <button type='button' onClick={handleSave} className='btn btn-sm btn-success ml-1'>Save</button>
                        </div>
                    </div>
                    {
                        formLoading ? <div className='model-overlay'>
                            <ThreeCircles
                                height="100"
                                width="100"
                                color="#4fa94d"
                                wrapperStyle={{}}
                                wrapperClass=""
                                visible={true}
                                ariaLabel="three-circles-rotating"
                                outerCircleColor=""
                                innerCircleColor=""
                                middleCircleColor=""
                            />
                        </div> : null
                    }

                </Box>
            </Modal>
        </>
    );
}

export default Users;
