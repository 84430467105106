import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useSearchParams } from "react-router-dom";

import BGImage11 from '../assets/fe/images/menubg.png';

import { RoutesPath } from "../Routes";
import { API } from "../Helpers/ApiService";
import { URL } from "../Helpers/URL";
import { useSelector } from "react-redux";
import moment from "moment";


const Events = () => {
    const url = window.location.pathname;
    const navigate = useNavigate();
    const auth = useSelector(state => state.auth);
    const { t, i18n } = useTranslation();
    const [searchParams] = useSearchParams();

    const [page, setPage] = useState('');
    const [eventType, setEventType] = useState('');
    const [category, setCategory] = useState([]);
    const [activeTab, setActiveTab] = useState('');
    const [events, setEvents] = useState([]);

    useEffect(() => {
        if (url.includes('schedule')) {
            setPage('schedule');
            setEventType('schedule');
        }
        if (url.includes('live')) {
            setPage('live_events');
            setEventType('live');
        }
        if (url.includes('result')) {
            setPage('result');
            setEventType('result');
        }
    }, [url])

    useEffect(() => {
        if (eventType) {
            getEvents();
        }
        getCategory();
    }, [eventType])

    const getCategory = async () => {
        API({
            url: URL.CATEGORY_LIST,
            method: 'GET',
        }).then(res => {
            setCategory(res);
            if (res?.length) {
                setActiveTab(res[0]?.id);
            }
        });
    }

    const getEvents = async () => {
        API({
            url: URL.GET_EVENTS + eventType,
            method: 'GET',
        }).then(res => {
            setEvents(res)
        });
    }

    const getEventsByCategory = (catId) => {
        const tempList = [];
        events.map(e => {
            if (catId == e.category.id) {
                tempList.push(e);
            }
        })
        return tempList;
    }

    return (
        <>
            <section className="page-title" style={searchParams.get('hidelayout') ? { backgroundImage: "url(" + BGImage11 + ")", marginTop: 0 } : { backgroundImage: "url(" + BGImage11 + ")" }}>
                <div className="anim-icons full-width">
                    <span className="icon icon-bull-eye"></span>
                    <span className="icon icon-dotted-circle"></span>
                </div>
                <div className="auto-container">
                    <div className="title-outer">
                        <h1>{t(page)}</h1>
                    </div>
                </div>
            </section>
            <center>
                <section className="register-section" style={{ overflowY: 'auto', minHeight: '63vh', paddingTop: 10 }}>
                    <div className="auto-container">
                        <div className="col-12 event-list">
                            <div className="tab">
                                {
                                    category?.map((e, i) => <button key={i} className={activeTab == e.id ? "tablinks active" : 'tablinks'} onClick={() => setActiveTab(e.id)}>{e.category[auth.language]}</button>)
                                }
                            </div>


                            {
                                category?.map((e, i) => {
                                    return (
                                        <div key={i} className="tabcontent" style={{ display: activeTab == e.id ? 'block' : 'none' }}>
                                            <table width={'100%'} className="text-center">
                                                <thead>
                                                    <tr>
                                                        <th className="color-blue">#</th>
                                                        <th className="color-blue">{t('name')}</th>
                                                        <th className="color-blue">{t('category')}</th>
                                                        <th className="color-blue">{t('date')}</th>
                                                        <th className="color-blue">{t('action')}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        !getEventsByCategory(e.id)?.length ? <tr>
                                                            <td colSpan={5}>No events found!</td>
                                                        </tr> : null
                                                    }
                                                    {
                                                        getEventsByCategory(e.id).map((event, i) => <tr key={'evi-' + i} className={i % 2 != 0 ? 'background-grey' : ''}>
                                                            <td className="color-blue">{i + 1}</td>
                                                            <td>
                                                                {event.name[auth.language]}
                                                            </td>
                                                            <td>
                                                                {event.category.category[auth.language]}
                                                            </td>
                                                            <td>{moment(event?.date).format('DD/MM/YYYY')}</td>
                                                            <td onClick={() => {
                                                                navigate(`/event/${event.id}`);
                                                            }}><i className="fa fa-arrow-right"></i></td>
                                                        </tr>)
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </section>
            </center>
        </>
    );
}
export default Events;
