import { useEffect, useState } from 'react';
import { ThreeCircles } from 'react-loader-spinner';
import { confirmAlert } from 'react-confirm-alert';
import DataTable from 'react-data-table-component';
import { Box, Modal } from '@mui/material';
import { toast } from 'react-toastify';
import _ from 'lodash';

import { modelBoxStyle } from '../../Helpers/Constants';
import { API } from '../../Helpers/ApiService';
import { RoutesPath } from "../../Routes";
import { URL } from '../../Helpers/URL';
import Language from '../../Component/Language';


const Designation = () => {
    const fd = {
        es: "",
        fr: "",
        de: "",
        it: "",
        en: "",
        hi: "",
    }
    const columns = [
        {
            name: 'Designation',
            center: true,
            selector: row => row.designation['en'],
        },
        {
            name: 'Status',
            center: true,
            selector: row => row.isActive,
            cell: row => <StatusColumn row={row} />
        },
        {
            name: 'Action',
            center: true,
            cell: row => <ActionColumn row={row} />
        },
    ];
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [model, setModel] = useState(null);
    const [form, setForm] = useState(fd);
    const [formLoading, setFormLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [language, setLanguage] = useState('en');

    useEffect(() => {
        getData();
    }, []);

    const getData = (page = 1) => {
        setLoading(true);
        API({
            url: URL.DESIGNATION + '?page=' + page + '&limit=' + perPage,
            method: 'GET',
        }).then(res => {
            setLoading(false);
            if (res?.code) {
                return;
            }
            setTotalRows(res.totalResults);
            setData(res.results);
        });
    }

    const handleCreate = () => {
        setModel({
            mode: 'create',
        });
    }

    const handleEdit = (data) => {
        API({
            url: URL.DESIGNATION + '/' + data?.id,
            method: 'GET',
        }).then(res => {
            if (res?.code) {
                toast.error(res.message)
                return;
            }
            setForm(res.designation);
            setModel({
                mode: 'edit',
                editId: data.id
            });
        });
    }

    const handleSave = () => {
        setFormLoading(true);
        API({
            url: model?.mode == 'edit' ? URL.DESIGNATION + '/' + model?.editId : URL.DESIGNATION,
            method: model?.mode == 'edit' ? 'PATCH' : 'POST',
            data: { designation: form }
        }).then(res => {
            setFormLoading(false);
            if (res?.code) {
                toast.error(res.message)
                return;
            }
            resetForm();
        });

    }

    const StatusColumn = ({ row }) => {
        const handleStatusChange = (e) => {
            API({
                url: URL.DESIGNATION + '/' + row.id,
                method: 'PATCH',
                data: { isActive: e.target.checked ? 'Active' : 'InActive' }
            }).then(res => {
                if (res?.code) {
                    toast.error(res.message)
                    return;
                }
                getData();
            });
        }
        return (
            <div className="form-group">
                <div className="custom-control custom-switch">
                    <input type="checkbox" className="custom-control-input" checked={row.isActive == 'Active' ? true : false} id={row.id} onChange={handleStatusChange} />
                    <label className="custom-control-label" htmlFor={row.id}></label>
                </div>
            </div>
        );
    }

    const ActionColumn = ({ row }) => {
        const removeItem = () => {
            confirmAlert({
                title: 'Are you sure?',
                message: 'Want to delete item.',
                buttons: [
                    {
                        label: 'Yes',
                        onClick: () => API({
                            url: URL.DESIGNATION + '/' + row?.id,
                            method: 'DELETE',
                        }).then(res => {
                            if (res?.code) {
                                toast.error(res.message)
                                return;
                            }
                            toast.success('Designation deleted succesfully');
                            getData();
                        })
                    },
                    { label: 'No', }
                ]
            });

        }
        return (
            <>
                <button className='btn btn-info btn-sm' onClick={() => handleEdit(row)}>< i className='fa fa-edit' /></button>
                <button className='ml-1 btn btn-danger btn-sm' onClick={removeItem}>< i className='fa fa-trash' /></button>
            </>
        );
    }

    const setFormData = (value) => {
        let data = _.cloneDeep(form);
        data[language] = value;
        setForm(data);
    }

    const resetForm = () => {
        setModel(null);
        setForm(fd);
        setLanguage('en');
        getData();
    }

    return (
        <>
            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="m-0">Designation</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><a href={RoutesPath.Admin.Dashboard}>Home</a></li>
                                <li className="breadcrumb-item active">Designation</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>

            <div className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card">
                                <div className="card-header">
                                    <div className='row'>
                                        <div className='col-6 d-flex align-items-center'>
                                            <h3 className="card-title">Designation Listing</h3>
                                        </div>
                                        <div className='col-6 d-flex justify-content-end'>
                                            <button className='btn btn-primary' onClick={handleCreate}>
                                                Create
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className='row'>
                                        <div className='col-12'>
                                            <DataTable
                                                columns={columns}
                                                data={data}
                                                progressPending={loading}
                                                pagination
                                                paginationServer
                                                paginationTotalRows={totalRows}
                                                onChangeRowsPerPage={(newPerPage, page) => { setPerPage(newPerPage); getData(page); }}
                                                onChangePage={page => getData(page)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal
                open={model ? true : false}
                onClose={() => resetForm()}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={modelBoxStyle}>
                    <div className='row'>
                        <div className='col-6'>
                            <h5>{model?.mode == 'create' ? 'Create' : 'Edit'}</h5>
                        </div>
                        <div className='col-6'>
                            <i style={{
                                position: 'absolute',
                                top: -15,
                                right: -15,
                                cursor: 'pointer',
                                padding: 10,
                            }} className='fa fa-times' onClick={() => resetForm()} />
                        </div>
                    </div>
                    <Language lang={language} onChange={lang => setLanguage(lang)} />
                    <div className='row'>
                        <div className='col-12'>
                            <div className="form-group mt-3 mb-3 ">
                                <input type="email" className="form-control" value={form[language]} placeholder="Enter Designation" onChange={e => setFormData(e.target.value)} />
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-12 d-flex justify-content-end'>
                            <button type='button' className='btn btn-sm btn-secondary' onClick={() => resetForm()}>Cancel</button>
                            <button type='button' onClick={handleSave} className='btn btn-sm btn-success ml-1'>Save</button>
                        </div>
                    </div>
                    {
                        formLoading ? <div className='model-overlay'>
                            <ThreeCircles
                                height="100"
                                width="100"
                                color="#4fa94d"
                                wrapperStyle={{}}
                                wrapperClass=""
                                visible={true}
                                ariaLabel="three-circles-rotating"
                                outerCircleColor=""
                                innerCircleColor=""
                                middleCircleColor=""
                            />
                        </div> : null
                    }

                </Box>
            </Modal>
        </>
    );
}

export default Designation;
