import { useEffect, useState } from 'react';
import { ThreeCircles } from 'react-loader-spinner';
import { confirmAlert } from 'react-confirm-alert';
import DataTable from 'react-data-table-component';
import { Box, Modal } from '@mui/material';
import { toast } from 'react-toastify';
import _ from 'lodash';

import { DOMAIN_URL, modelBoxStyle } from '../../Helpers/Constants';
import { API } from '../../Helpers/ApiService';
import { RoutesPath } from "../../Routes";
import { URL } from '../../Helpers/URL';
import Language from '../../Component/Language';
import { useSelector } from 'react-redux';


const Upload = () => {
    const fd = {
        remarks: '',
        file: '',
        type: '',
        supportDesk: null,
    }
    const columns = [
        {
            name: 'User',
            center: true,
            selector: row => row.user.firstName + ' ' + row.user.surname,
        },
        {
            name: 'File',
            center: true,
            selector: row => <div style={{ whiteSpace: 'normal', overflow: 'visible' }}>{row.file.replace('uploads/', '')}</div>,
        },
        {
            name: 'Type',
            center: true,
            selector: row => <div>
                {
                    row.type == 'Category' ? <span>{row?.eventCategory?.category?.en}</span> : (row.type == 'Support Desk' ? 'Support Desk (' + row.supportDesk?.name?.en + ')' : row.type)
                }
            </div>,
        },
        {
            name: 'Remarks',
            center: true,
            selector: row => <div style={{ whiteSpace: 'normal', overflow: 'visible' }}>{row.remarks}</div>,
        },
        {
            name: 'Action',
            center: true,
            cell: row => <ActionColumn row={row} />
        },
    ];

    const auth = useSelector(state => state.auth);

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [model, setModel] = useState(null);
    const [form, setForm] = useState(fd);
    const [formLoading, setFormLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [language, setLanguage] = useState('en');
    const [categories, setCategories] = useState([]);
    const [managers, setManagers] = useState([]);
    const [supports, setSupports] = useState([]);
    const [manager, setManager] = useState('');
    const [type, setType] = useState('');
    const [support, setSupport] = useState('');

    useEffect(() => {
        getData();
    }, [manager, type, support]);

    useEffect(() => {
        getExtraData();
    }, []);

    useEffect(() => {
        getData();
    }, [perPage]);

    const getData = (page = 1) => {
        setLoading(true);
        API({
            url: URL.FILE_UPLOAD + '?page=' + page + '&limit=' + perPage + '&manager=' + manager + '&type=' + type + '&supportDesk=' + support,
            method: 'GET',
        }).then(res => {
            setLoading(false);
            if (res?.code) {
                return;
            }
            setTotalRows(res.totalResults);
            setData(res.results);
        });
    }

    const getExtraData = () => {
        API({
            url: URL.CATEGORY_LIST,
            method: 'GET',
        }).then(res => {
            if (res?.code) {
                return;
            }
            setCategories(res);
        });

        API({
            url: URL.GET_MANAGERS,
            method: 'GET',
        }).then(res => {
            if (res?.code) {
                return;
            }
            setManagers(res);
        });

        API({
            url: URL.GET_SUPPORT_LIST_DRP,
            method: 'GET',
        }).then(res => {
            if (res?.code) {
                return;
            }
            setSupports(res);
        });
    }

    const handleCreate = () => {
        setModel({
            mode: 'create',
        });
    }

    const handleEdit = (data) => {
        API({
            url: URL.FILE_UPLOAD + '/' + data?.id,
            method: 'GET',
        }).then(res => {
            if (res?.code) {
                toast.error(res.message)
                return;
            }
            setForm(res);
            setModel({
                mode: 'edit',
                editId: data.id
            });
        });
    }

    const handleSave = () => {
        setFormLoading(true);
        API({
            url: model?.mode == 'edit' ? URL.FILE_UPLOAD + '/' + model?.editId : URL.FILE_UPLOAD,
            method: model?.mode == 'edit' ? 'PATCH' : 'POST',
            data: form
        }).then(res => {
            setFormLoading(false);
            if (res?.code) {
                toast.error(res.message)
                return;
            }
            resetForm();
        });

    }

    const ActionColumn = ({ row }) => {
        const removeItem = () => {
            confirmAlert({
                title: 'Are you sure?',
                message: 'Want to delete item.',
                buttons: [
                    {
                        label: 'Yes',
                        onClick: () => API({
                            url: URL.FILE_UPLOAD + '/' + row?.id,
                            method: 'DELETE',
                        }).then(res => {
                            if (res?.code) {
                                toast.error(res.message)
                                return;
                            }
                            toast.success('File deleted succesfully');
                            getData();
                        })
                    },
                    { label: 'No', }
                ]
            });

        }
        return (
            <>
                <button className='btn btn-download btn-sm' onClick={() => {
                    window.open(DOMAIN_URL + row.file, '_blank')
                }}>< i className='fa fa-download' /></button>
            </>
        );
    }

    const setFormData = (name, value) => {
        let data = _.cloneDeep(form);
        data[name] = value;
        setForm(data);
    }

    const resetForm = () => {
        setModel(null);
        setForm(fd);
        setLanguage('en');
        getData();
    }

    const onChangeFile = (e, name) => {
        let files = e.target.files;
        if (!files.length) {
            return;
        }
        setFormLoading(true);
        let payload = {};
        for (let index = 0; index < files.length; index++) {
            payload['file' + index] = files[index];
        }
        API({
            url: URL.UPLOAD,
            method: 'POST',
            data: payload,
            formData: true
        }).then(res => {
            e.target.value = null;
            setFormLoading(false);
            if (res?.code) {
                toast.error(res.message);
                return;
            }
            setFormData('file', 'uploads/' + res[0].filename)
        });
    }

    return (
        <>
            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="m-0">Upload</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><a href={RoutesPath.Admin.Dashboard}>Home</a></li>
                                <li className="breadcrumb-item active">Upload</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>

            <div className="content">
                <div className="container-fluid">
                    <div className="row">
                        {
                            auth.value.role == 'admin' ? <div className="col-lg-12">
                                <div className='row'>
                                    <div className="col-lg-3">
                                        <div className="form-group mt-3 mb-3 ">
                                            <label>Manager</label>
                                            <select className='form-control' defaultValue={manager} onChange={e => setManager(e.target.value)}>
                                                <option value={''}>Select manager</option>
                                                {
                                                    managers?.map((e, i) => <option key={i} value={e.id}>{e.name}</option>)
                                                }
                                            </select>
                                        </div>

                                    </div>
                                    <div className="col-lg-3">
                                        <div className="form-group mt-3 mb-3 ">
                                            <label>Type</label>
                                            <select className='form-control' defaultValue={type} onChange={e => setType(e.target.value)}>
                                                <option value={''}>Select type</option>
                                                <option value={'indemnity form'}>indemnity form</option>
                                                <option value={'Nominal Form'}> Nominal Form</option>
                                                <option value={'Photographs of Team Members'}>Photographs of Team Members</option>
                                                <option value={'Support Desk'}>Support Desk</option>
                                                {
                                                    categories?.map((e, i) => <option key={i} value={e.id}>Entry Form - {e.category.en}</option>)
                                                }
                                                <option value={'Other'}>Other</option>
                                            </select>
                                        </div>
                                    </div>
                                    {
                                        type == 'Support Desk' ? <div className='col-lg-3'>
                                            <div className="form-group mt-3 mb-3 ">
                                                <label>Support Desk</label>
                                                <select className='form-control' defaultValue={support} onChange={e => setSupport(e.target.value)}>
                                                    <option value={''}>Select Support Desk</option>
                                                    {
                                                        supports?.map((e, i) => <option key={i} value={e.id}>{e.name.en}</option>)
                                                    }
                                                </select>
                                            </div>
                                        </div> : null
                                    }
                                    <div className="col-lg-3 d-flex align-items-center">
                                        <button className='btn btn-info mt-4' onClick={() => {
                                            setManager('')
                                            setType('')
                                        }}>Clear Filter</button>
                                    </div>
                                </div>
                            </div> : null
                        }

                        <div className="col-lg-12">
                            <div className="card">
                                <div className="card-header">
                                    <div className='row'>
                                        <div className='col-6 d-flex align-items-center'>
                                            <h3 className="card-title">Uploads Listing</h3>
                                        </div>
                                        <div className='col-6 d-flex justify-content-end'>
                                            {
                                                auth?.value?.role == "manager" ? <button className='btn btn-primary' onClick={handleCreate}>
                                                    Upload
                                                </button> : null
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className='row'>
                                        <div className='col-12'>
                                            <DataTable
                                                columns={columns}
                                                data={data}
                                                progressPending={loading}
                                                pagination
                                                paginationServer
                                                paginationRowsPerPageOptions={[10, 25, 50, 100, 200]}
                                                paginationTotalRows={totalRows}
                                                onChangeRowsPerPage={(newPerPage, page) => { setPerPage(newPerPage); getData(page); }}
                                                onChangePage={page => getData(page)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal
                open={model ? true : false}
                onClose={() => resetForm()}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={modelBoxStyle}>
                    <div className='row'>
                        <div className='col-6'>
                            <h5>{model?.mode == 'create' ? 'Upload' : 'Edit'}</h5>
                        </div>
                        <div className='col-6'>
                            <i style={{
                                position: 'absolute',
                                top: -15,
                                right: -15,
                                cursor: 'pointer',
                                padding: 10,
                            }} className='fa fa-times' onClick={() => resetForm()} />
                        </div>
                    </div>
                    {/* <Language lang={language} onChange={lang => setLanguage(lang)} /> */}
                    <div className='row'>
                        <div className='col-12'>
                            <div className="form-group mt-3 mb-3 ">
                                <input type="file" accept='image/*, application/pdf' onChange={e => onChangeFile(e)} />
                            </div>
                        </div>
                        <div className='col-12'>
                            <div className="form-group mt-3 mb-3 ">
                                <input type="text" className="form-control" value={form?.remarks} placeholder="Enter Remarks" onChange={e => setFormData('remarks', e.target.value)} />
                            </div>
                        </div>
                        <div className='col-12'>
                            <div className="form-group mt-3 mb-3 ">
                                <select className='form-control' defaultValue={form.type} onChange={e => setFormData('type', e.target.value)}>
                                    <option value={''}>Select type</option>
                                    <option value={'Indemnity form'}>Indemnity form</option>
                                    <option value={'Nominal Form'}> Nominal Form</option>
                                    <option value={'Photographs of Team Members'}>Photographs of Team Members</option>
                                    <option value={'Support Desk'}>Support Desk</option>
                                    {
                                        categories?.map((e, i) => <option key={i} value={e.id}>Entry Form - {e.category.en}</option>)
                                    }
                                    <option value={'Other'}>Other</option>
                                </select>
                            </div>
                        </div>

                        {
                            form.type == 'Support Desk' ? <div className='col-12'>
                                <div className="form-group mt-3 mb-3 ">
                                    <select className='form-control' defaultValue={form.supportDesk} onChange={e => setFormData('supportDesk', e.target.value)}>
                                        <option value={''}>Select Support Desk</option>
                                        {
                                            supports?.map((e, i) => <option key={i} value={e.id}>{e.name.en}</option>)
                                        }
                                    </select>
                                </div>
                            </div> : null
                        }
                    </div>
                    <div className='row'>
                        <div className='col-12 d-flex justify-content-end'>
                            <button type='button' className='btn btn-sm btn-secondary' onClick={() => resetForm()}>Cancel</button>
                            <button type='button' onClick={handleSave} className='btn btn-sm btn-success ml-1'>Upload</button>
                        </div>
                    </div>
                    {
                        formLoading ? <div className='model-overlay'>
                            <ThreeCircles
                                height="100"
                                width="100"
                                color="#4fa94d"
                                wrapperStyle={{}}
                                wrapperClass=""
                                visible={true}
                                ariaLabel="three-circles-rotating"
                                outerCircleColor=""
                                innerCircleColor=""
                                middleCircleColor=""
                            />
                        </div> : null
                    }

                </Box>
            </Modal>
        </>
    );
}

export default Upload;
