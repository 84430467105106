import { useEffect, useState } from "react";
import { API } from "../../Helpers/ApiService";
import { URL } from "../../Helpers/URL";
import { useSelector } from "react-redux";
import { BASE_URL, DOMAIN_URL, modelBoxStyle } from "../../Helpers/Constants";
import DataTable from "react-data-table-component";
import { Box, Modal } from '@mui/material';
import { toast } from "react-toastify";
import moment from "moment";

const Dashboard = () => {
    const auth = useSelector(state => state.auth)
    const [counts, setCounts] = useState(null);

    const [model, setModel] = useState(null);

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);

    const [epData, setEpData] = useState([]);
    const [epLoading, setEpLoading] = useState(true);
    const [epTotalRows, setEpTotalRows] = useState(0);
    const [epPerPage, setEpPerPage] = useState(10);

    const [orgData, setOrgData] = useState([]);
    const [orgLoading, setOrgLoading] = useState(true);
    const [orgTotalRows, setOrgTotalRows] = useState(0);
    const [orgPerPage, setOrgPerPage] = useState(10);

    const [pData, setPData] = useState([]);
    const [pLoading, setPLoading] = useState(true);
    const [pTotalRows, setPTotalRows] = useState(0);
    const [pPerPage, setPPerPage] = useState(10);

    const participantsColumns = [
        {
            name: 'Sr No.',
            center: true,
            selector: (row, index) => index + 1,
        },
        {
            name: 'Category',
            center: true,
            selector: row => row?.events?.map((e, i) => <div key={i}><span >{e.category.category.en}{i != (row?.events?.length - 1) ? ', ' : ''}</span></div>),
        },
        {
            name: 'Events',
            center: true,
            selector: row => row?.events?.map((e, i) => <div key={i}><span>{e.name.en}{i != (row?.events?.length - 1) ? ', ' : ''}</span></div>),
        },
        {
            name: 'Participant Name',
            center: true,
            selector: row => row?.firstName + ' ' + row?.surname,
        },
        {
            name: 'Rank',
            center: true,
            selector: row => row?.rank?.rank?.en,
        },
        {
            name: 'Official ID',
            center: true,
            selector: row => row?.officialIdProof ? <img width={50} src={DOMAIN_URL + row?.officialIdProof} /> : null
        },
    ];

    const eventParticipantsCountColumns = [
        {
            name: 'Category',
            center: true,
            selector: (row, index) => row?.category?.category?.en,
        },
        {
            name: 'Event Name',
            center: true,
            selector: row => row?.name?.en,
        },
        {
            name: 'Total Participants',
            center: true,
            selector: row => row?.participantsCount,
        },
    ];

    const orgParticipantsCountColumns = [
        {
            name: 'Organization',
            center: true,
            selector: (row, index) => row?.state?.en,
        },
        {
            name: 'Total Participants',
            center: true,
            selector: row => row?.participantsCount,
        },
    ];

    const pColumns = [
        {
            name: 'Organization',
            center: true,
            selector: row => row?.state?.state?.en,
        },
        {
            name: 'Category',
            center: true,
            selector: row => row?.events?.map((e, i) => <div><span key={i}>{e.category.category.en}{i != (row?.events?.length - 1) ? ', ' : ''}</span></div>),
        },
        {
            name: 'Events',
            center: true,
            selector: row => row?.events?.map((e, i) => <div><span key={i}>{e.name.en}{i != (row?.events?.length - 1) ? ', ' : ''}</span></div>),
        },
        {
            name: 'Participant Name',
            center: true,
            selector: row => row?.firstName + ' ' + row?.surname,
        },
        {
            name: 'Rank',
            center: true,
            selector: row => row?.rank?.rank?.en,
        },
        {
            name: 'Official ID',
            center: true,
            selector: row => row?.officialIdProof ? <img width={50} src={DOMAIN_URL + row?.officialIdProof} /> : null
        },
        {
            name: 'Action',
            center: true,
            cell: row => <ActionColumn row={row} />
        },
    ];

    useEffect(() => {
        getData();
        getEpData();
        getOrgData();
        getPData();
    }, [auth?.value?.role]);

    const getData = (page = 1) => {
        if (['admin', 'manager'].includes(auth?.value?.role)) {
            API({
                url: URL.GET_DASHBOARD_COUNT,
                method: 'GET'
            }).then(res => {
                setCounts(res)
            });
        }

        if (auth.value.role == 'manager') {
            setLoading(true);
            API({
                url: URL.GET_MY_PARTICIPANTS + '?page=' + page + '&limit=' + perPage,
                method: 'GET',
            }).then(res => {
                setLoading(false);
                if (res?.code) {
                    return;
                }
                setTotalRows(res.totalResults);
                setData(res.results);
            });
        }
    }

    const getEpData = (page = 1) => {
        if (auth.value.role == 'superAdmin' || auth.value.role == 'admin') {
            setEpLoading(true);
            API({
                url: URL.EVENT_PARTICIPANTS_COUNT + '?page=' + page + '&limit=' + epPerPage,
                method: 'GET',
            }).then(res => {
                setEpLoading(false);
                if (res?.code) {
                    return;
                }
                setEpTotalRows(res.totalResults);
                setEpData(res.results);
            });
        }
    }

    const getOrgData = (page = 1) => {
        if (auth.value.role == 'superAdmin' || auth.value.role == 'admin') {
            setOrgLoading(true);
            API({
                url: URL.ORG_PARTICIPANTS_COUNT + '?page=' + page + '&limit=' + orgPerPage,
                method: 'GET',
            }).then(res => {
                setOrgLoading(false);
                if (res?.code) {
                    return;
                }
                setOrgTotalRows(res.totalResults);
                setOrgData(res.results);
            });
        }
    }

    const getPData = (page = 1) => {
        if (auth.value.role == 'superAdmin' || auth.value.role == 'admin') {
            setPLoading(true);
            API({
                url: URL.PARTICIPANTS_COUNT + '?page=' + page + '&limit=' + pPerPage,
                method: 'GET',
            }).then(res => {
                setPLoading(false);
                if (res?.code) {
                    return;
                }
                setPTotalRows(res.totalResults);
                setPData(res.results);
            });
        }
    }


    const ActionColumn = ({ row }) => {
        const handleView = () => {
            let index = pData.findIndex(e => e.id == row.id);
            if (index != -1) {
                setModel({
                    index: index,
                });
            }
        }
        return (
            <>
                <button className='ml-1 btn btn-info btn-sm' onClick={handleView}>< i className='fa fa-eye' /></button>
            </>
        );
    }

    const exportEpData = () => {
        API({
            url: URL.EXPORT_EVENT_PARTICIPANTS_COUNT,
            method: 'GET',
        }).then(res => {
            if (res?.code) {
                toast.error(res.message)
                return;
            }
            window.open(`${BASE_URL}${URL.DOWNLOAD_FILE}excel/${res.name}`, "_blank");
        });
    }
    const exportOrgData = () => {
        API({
            url: URL.EXPORT_ORG_PARTICIPANTS_COUNT,
            method: 'GET',
        }).then(res => {
            if (res?.code) {
                toast.error(res.message)
                return;
            }
            window.open(`${BASE_URL}${URL.DOWNLOAD_FILE}excel/${res.name}`, "_blank");
        });
    }
    const exportPData = () => {
        API({
            url: URL.EXPORT_PARTICIPANTS_COUNT,
            method: 'GET',
        }).then(res => {
            if (res?.code) {
                toast.error(res.message)
                return;
            }
            window.open(`${BASE_URL}${URL.DOWNLOAD_FILE}excel/${res.name}`, "_blank");
        });
    }

    return (
        <>
            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="m-0">Dashboard</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><a href="#">Home</a></li>
                                <li className="breadcrumb-item active">Dashboard</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>

            <div className="content">
                <div className="container-fluid">
                    <div className="row">
                        {
                            ['admin', 'manager'].includes(auth?.value?.role) ? <>
                                <div className="col-12 col-sm-6 col-md-3">
                                    <div className="info-box">
                                        <span className="info-box-icon bg-info elevation-1"><i className="fas fa-cog"></i></span>
                                        <div className="info-box-content">
                                            <span className="info-box-text">Registered Participants</span>
                                            <span className="info-box-number">
                                                {counts?.usersCount ?? '0'}
                                                {/* <small>%</small> */}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-6 col-md-3">
                                    <div className="info-box">
                                        <span className="info-box-icon bg-info elevation-1"><i className="fas fa-cog"></i></span>
                                        <div className="info-box-content">
                                            <span className="info-box-text">Events Participated</span>
                                            <span className="info-box-number">
                                                {counts?.participationCount}
                                                {/* <small>%</small> */}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </> : null
                        }
                        {
                            auth.value.role == 'manager' ? <div className="col-12">
                                <DataTable
                                    columns={participantsColumns}
                                    data={data}
                                    progressPending={loading}
                                    pagination
                                    paginationServer
                                    paginationTotalRows={totalRows}
                                    onChangeRowsPerPage={(newPerPage, page) => { setPerPage(newPerPage); getData(page); }}
                                    onChangePage={page => getData(page)}
                                />
                            </div> : null
                        }
                        {
                            auth.value.role == 'superAdmin' || auth.value.role == 'admin' ? <>
                                <div className="col-lg-12">
                                    <div className="card">
                                        <div className="card-header">
                                            <div className='row'>
                                                <div className='col-6 d-flex align-items-center'>
                                                    <h3 className="card-title">Consolidated summary of participation</h3>
                                                </div>
                                                <div className='col-6 d-flex justify-content-end'>
                                                    <button className='btn btn-primary' onClick={exportEpData}>
                                                        Export Pdf
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <DataTable
                                                        columns={eventParticipantsCountColumns}
                                                        data={epData}
                                                        progressPending={epLoading}
                                                        pagination
                                                        paginationServer
                                                        paginationRowsPerPageOptions={[10, 25, 50, 100, 200]}
                                                        paginationTotalRows={epTotalRows}
                                                        onChangeRowsPerPage={(newPerPage, page) => { setEpPerPage(newPerPage); getEpData(page); }}
                                                        onChangePage={page => getEpData(page)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="card">
                                        <div className="card-header">
                                            <div className='row'>
                                                <div className='col-6 d-flex align-items-center'>
                                                    <h3 className="card-title">Organization wise participation</h3>
                                                </div>
                                                <div className='col-6 d-flex justify-content-end'>
                                                    <button className='btn btn-primary' onClick={exportOrgData}>
                                                        Export Pdf
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <DataTable
                                                        columns={orgParticipantsCountColumns}
                                                        data={orgData}
                                                        progressPending={orgLoading}
                                                        pagination
                                                        paginationServer
                                                        paginationRowsPerPageOptions={[10, 25, 50, 100, 200]}
                                                        paginationTotalRows={orgTotalRows}
                                                        onChangeRowsPerPage={(newPerPage, page) => { setOrgPerPage(newPerPage); getOrgData(page); }}
                                                        onChangePage={page => getOrgData(page)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="card">
                                        <div className="card-header">
                                            <div className='row'>
                                                <div className='col-6 d-flex align-items-center'>
                                                    <h3 className="card-title">Detailed list of participants</h3>
                                                </div>
                                                <div className='col-6 d-flex justify-content-end'>
                                                    <button className='btn btn-primary' onClick={exportPData}>
                                                        Export Pdf
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <DataTable
                                                        columns={pColumns}
                                                        data={pData}
                                                        progressPending={pLoading}
                                                        pagination
                                                        paginationServer
                                                        paginationRowsPerPageOptions={[10, 25, 50, 100, 200]}
                                                        paginationTotalRows={pTotalRows}
                                                        onChangeRowsPerPage={(newPerPage, page) => { setPPerPage(newPerPage); getPData(page); }}
                                                        onChangePage={page => getPData(page)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </> : null
                        }
                    </div>
                </div>
            </div>
            <Modal
                open={model ? true : false}
                onClose={() => setModel(null)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={modelBoxStyle}>
                    <div className='row'>
                        <div className='col-6'>
                            <h5>User Details</h5>
                        </div>
                        <div className='col-6'>
                            <i style={{
                                position: 'absolute',
                                top: -15,
                                right: -15,
                                cursor: 'pointer',
                                padding: 10,
                            }} className='fa fa-times' onClick={() => setModel(null)} />
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-12'>
                            {
                                pData[model?.index]?.type == 'Animal' ? <table border={0} width={500}>
                                    <tr>
                                        <td>Handler Name:</td>
                                        <td>{pData[model?.index]?.firstName + ' ' + pData[model?.index]?.surname}</td>
                                    </tr>
                                    <tr>
                                        <td>Handler Rank:</td>
                                        <td>{pData[model?.index]?.rank?.rank?.en}</td>
                                    </tr>
                                    <tr>
                                        <td>Handler Email:</td>
                                        <td>{pData[model?.index]?.email}</td>
                                    </tr>
                                    <tr>
                                        <td>Organization:</td>
                                        <td>{pData[model?.index]?.state?.state?.en}</td>
                                    </tr>
                                    <tr>
                                        <td>Animal Name:</td>
                                        <td>{pData[model?.index]?.animalName}</td>
                                    </tr>
                                    <tr>
                                        <td>Sex Of Animal:</td>
                                        <td>{pData[model?.index]?.sexOfAnimal}</td>
                                    </tr>
                                    <tr>
                                        <td>DOB Of Animal:</td>
                                        <td>{moment(pData[model?.index]?.dobOfAnimal).format('DD-MM-YYYY')}</td>
                                    </tr>
                                    <tr>
                                        <td>Breed Of Animal:</td>
                                        <td>{pData[model?.index]?.breedOfAnimal}</td>
                                    </tr>
                                    <tr>
                                        <td>Marking & Color:</td>
                                        <td>{pData[model?.index]?.marking}</td>
                                    </tr>
                                    <tr>
                                        <td>Chest No.:</td>
                                        <td>{pData[model?.index]?.chestNo}</td>
                                    </tr>
                                    <tr>
                                        <td>Food:</td>
                                        <td>{pData[model?.index]?.food}</td>
                                    </tr>

                                    <tr>
                                        <td>Candidate Type:</td>
                                        <td>{pData[model?.index]?.candidateType}</td>
                                    </tr>
                                    {
                                        pData[model?.index]?.events?.length ? <tr>
                                            <td>Events:</td>
                                            <td>{
                                                pData[model?.index]?.events?.map((e, i) => <span key={i}> {i != 0 ? ', ' : ''}{e.name.en} ({e.category.category.en})</span>)
                                            }</td>
                                        </tr> : null
                                    }


                                    {
                                        pData[model?.index]?.photo ? (
                                            <tr>
                                                <td>Photograph of the Candidate:</td>
                                                <td>
                                                    <img src={DOMAIN_URL + pData[model?.index]?.photo} width="200" />
                                                </td>
                                            </tr>
                                        ) : null
                                    }
                                    {
                                        pData[model?.index]?.officialIdProof ? (
                                            <tr>
                                                <td>Official I'd Proof:</td>
                                                <td>
                                                    <img src={DOMAIN_URL + pData[model?.index]?.officialIdProof} width="200" />
                                                </td>
                                            </tr>
                                        ) : null
                                    }
                                </table> : <table border={0} width={500}>
                                    <tr>
                                        <td>Name:</td>
                                        <td>{pData[model?.index]?.firstName + ' ' + pData[model?.index]?.surname}</td>
                                    </tr>
                                    <tr>
                                        <td>Rank:</td>
                                        <td>{pData[model?.index]?.rank?.rank?.en}</td>
                                    </tr>
                                    <tr>
                                        <td>Organization:</td>
                                        <td>{pData[model?.index]?.state?.state?.en}</td>
                                    </tr>
                                    <tr>
                                        <td>Email:</td>
                                        <td>{pData[model?.index]?.email}</td>
                                    </tr>
                                    <tr>
                                        <td>Mobile:</td>
                                        <td>{pData[model?.index]?.mobile}</td>
                                    </tr>
                                    <tr>
                                        <td>Official I'd No.:</td>
                                        <td>{pData[model?.index]?.beltNumber}</td>
                                    </tr>
                                    <tr>
                                        <td>Food:</td>
                                        <td>{pData[model?.index]?.food}</td>
                                    </tr>
                                    <tr>
                                        <td>Candidate Type:</td>
                                        <td>{pData[model?.index]?.candidateType}</td>
                                    </tr>

                                    {
                                        pData[model?.index]?.events?.length ? <tr>
                                            <td>Events:</td>
                                            <td>{
                                                pData[model?.index]?.events?.map((e, i) => <span key={i}> {i != 0 ? ', ' : ''}{e.name.en} ({e.category.category.en})</span>)
                                            }</td>
                                        </tr> : null
                                    }

                                    {
                                        pData[model?.index]?.photo ? (
                                            <tr>
                                                <td>Photograph of the Candidate:</td>
                                                <td>
                                                    <img src={DOMAIN_URL + pData[model?.index]?.photo} width="200" />
                                                </td>
                                            </tr>
                                        ) : null
                                    }
                                    {
                                        pData[model?.index]?.officialIdProof ? (
                                            <tr>
                                                <td>Official I'd Proof:</td>
                                                <td>
                                                    <img src={DOMAIN_URL + pData[model?.index]?.officialIdProof} width="200" />
                                                </td>
                                            </tr>
                                        ) : null
                                    }
                                </table>
                            }
                        </div>
                    </div>
                </Box>
            </Modal>
        </>
    );
}

export default Dashboard;
