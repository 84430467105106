import { useEffect, useState } from 'react';
import { ThreeCircles } from 'react-loader-spinner';
import { confirmAlert } from 'react-confirm-alert';
import DataTable from 'react-data-table-component';
import { Box, Modal } from '@mui/material';
import { toast } from 'react-toastify';
import _ from 'lodash';

import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import { BASE_URL, DOMAIN_URL, availableLanguages, modelBoxStyle } from '../../Helpers/Constants';
import { API } from '../../Helpers/ApiService';
import { RoutesPath } from "../../Routes";
import { URL } from '../../Helpers/URL';
import Language from '../../Component/Language';
import { ContentState, EditorState, convertFromHTML, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { Editor } from 'react-draft-wysiwyg';
import moment from 'moment';
import Multiselect from 'multiselect-react-dropdown';
import { useSelector } from 'react-redux';


const JudgesEvent = () => {

    const columns = [
        {
            name: 'Category',
            center: true,
            selector: row => row?.category?.category[availableLanguages[0]],
        },
        {
            name: 'Name',
            center: true,
            selector: row => row?.name[availableLanguages[0]],
        },
        {
            name: 'Fee',
            center: true,
            selector: row => row.fee,
        },
        {
            name: 'Status',
            center: true,
            selector: row => row.status,
        },
        {
            name: 'Action',
            center: true,
            cell: row => <ActionColumn row={row} />
        },
    ];

    const auth = useSelector(state => state.auth);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [model, setModel] = useState(null);
    const [certModel, setCertModel] = useState(null);
    const [usersModel, setUsersModel] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [users, setUsers] = useState([]);
    const [eventUserList, setEventUserUsers] = useState([]);

    useEffect(() => {
        getData();
    }, []);

    const getData = (page = 1) => {
        setLoading(true);
        API({
            url: URL.EVENT + '?page=' + page + '&limit=' + perPage,
            method: 'GET',
        }).then(res => {
            setLoading(false);
            if (res?.code) {
                return;
            }
            setTotalRows(res.totalResults);
            setData(res.results);
        });
    }

    const showUsers = (row) => {
        API({
            url: URL.GET_EVENT_ATTENDANCE + row.id,
            method: 'GET',
        }).then(res => {
            setModel(row.id);
            setUsers(res)
        });
    }

    const handleSaveAtt = () => {
        const tempData = _.cloneDeep(users);
        var payload = [];
        tempData.map(e => {
            payload = [...payload, ...e.users];
        });

        API({
            url: URL.SUBMIT_EVENT_ATTENDANCE + model,
            method: 'POST',
            data: payload
        }).then(res => {
            if (res?.code) {
                toast.error(res.message)
                return;
            }
            setModel(null)
            toast.success('Attendance saved successfully!')
        });
    }

    const ActionColumn = ({ row }) => {

        return (
            <>
                <button className='btn btn-info btn-sm mr-2' onClick={() => {
                    API({
                        url: URL.EXPORT_EVENT_USER + row.id,
                        method: 'GET',
                    }).then(res => {
                        if (res?.code) {
                            toast.error(res.message)
                            return;
                        }
                        window.open(`${BASE_URL}${URL.DOWNLOAD_FILE}excel/${res.file}`, "_blank");
                    });
                }}>< i className='fa fa-file-export' /></button>
                {/* <button className='btn btn-info btn-sm' onClick={() => {
                    API({
                        url: URL.EVENT_USERS + row.id,
                        method: 'GET',
                    }).then(res => {
                        setUsersModel(true);
                        setEventUserUsers(res)
                    });
                }}>< i className='fa fa-users' /></button> */}
                <button className='btn btn-info btn-sm' onClick={() => {
                    API({
                        url: URL.EXPORT_EVENT_ATTENDANCE + row.id,
                        method: 'GET',
                    }).then(res => {
                        if (res?.code) {
                            toast.error(res.message)
                            return;
                        }
                        setModel(null)
                        window.open(`${BASE_URL}${URL.DOWNLOAD_FILE}excel/${res.file}`, "_blank");
                        toast.success('Attendance saved successfully!')
                    });
                }}>< i className='fa fa-download' /></button>
                {
                    auth?.value?.role == "judge" ? <button className='ml-1 btn btn-info btn-sm mr-2' title='animalCupload' onClick={() => setCertModel(row)}>< i className='fa fa-upload' /></button> : null
                }
                <button className='btn btn-info btn-sm' onClick={() => showUsers(row)}>< i className='fa fa-list' /></button>
            </>
        );
    }

    const uploadCert = (e) => {
        let files = e.target.files;
        if (!files.length) {
            return;
        }
        let payload = { file: files[0] };
        API({
            url: URL.UPLOAD,
            method: 'POST',
            data: payload,
            formData: true
        }).then(res => {
            e.target.value = null;
            if (res?.code) {
                toast.error(res.message);
                return;
            }

            const fileObj = {
                file: 'uploads/' + res[0].filename,
                show: false,
            }

            var certData = _.cloneDeep(certModel);
            if (certData?.judgeDocument?.length) {
                certData.judgeDocument.push(fileObj);
            } else {
                certData.judgeDocument = [fileObj];
            }

            API({
                url: URL.UPLOAD_EVENT_DOCUMENT + certModel.id,
                method: 'POST',
                data: certData.judgeDocument,
            }).then(res1 => {
                if (res1?.code) {
                    toast.error(res.message);
                    return;
                }
                setCertModel(certData);
                getData();
            });
        });
    }

    const getFileName = (name) => {
        const parts = name?.split('/');
        return parts[parts?.length - 1]
    }

    return (
        <>
            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="m-0">Event</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><a href={RoutesPath.Admin.Dashboard}>Home</a></li>
                                <li className="breadcrumb-item active">Event</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>

            <div className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card">
                                <div className="card-header">
                                    <div className='row'>
                                        <div className='col-6 d-flex align-items-center'>
                                            <h3 className="card-title">Event Listing</h3>
                                        </div>
                                        <div className='col-6 d-flex justify-content-end'>
                                            {/* <button className='btn btn-primary' onClick={handleCreate}>
                                                Create
                                            </button> */}
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className='row'>
                                        <div className='col-12'>
                                            <DataTable
                                                columns={columns}
                                                data={data}
                                                progressPending={loading}
                                                pagination
                                                paginationServer
                                                paginationTotalRows={totalRows}
                                                onChangeRowsPerPage={(newPerPage, page) => { setPerPage(newPerPage); getData(page); }}
                                                onChangePage={page => getData(page)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal
                open={model ? true : false}
                onClose={() => {
                    setModel(false);
                    setUsers([]);
                }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={[modelBoxStyle, { width: '60%' }]}>
                    <h3>Manage Attendance</h3>
                    <div className='row mt-5'>
                        <div className='col-12'>
                            {
                                users.map((e, i) => <div className="row mb-4" key={i + 'state'}>
                                    <div className='col-12'><b>Organization :</b> {e.state.state.en}</div>
                                    <div className='col-12'>
                                        <div className='row'>
                                            <table width={'100%'} border={1} className='text-center'>
                                                <thead>
                                                    <tr>
                                                        <th>Sr No.</th>
                                                        <th>Official Id Proof</th>
                                                        <th>Photograph of the Candidate</th>
                                                        <th>Name</th>
                                                        <th>Candidate Type</th>
                                                        <th>Absent/Present</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        !e?.users?.length ? <tr>
                                                            <td colSpan={6}> No users found!</td>
                                                        </tr> : null
                                                    }
                                                    {
                                                        e?.users?.length ?
                                                            e?.users?.map((ue, index) => <tr key={index} >
                                                                <td>{index + 1}</td>
                                                                <td>
                                                                    {ue.officialIdProof ? <img src={DOMAIN_URL + ue.officialIdProof} width={70} /> : 'N/A'}
                                                                </td>
                                                                <td>
                                                                    {ue.photo ? <img src={DOMAIN_URL + ue.photo} width={70} /> : 'N/A'}
                                                                </td>
                                                                <td>
                                                                    {ue.name}
                                                                </td>
                                                                <td>
                                                                    {ue.candidateType}
                                                                </td>
                                                                <td>
                                                                    <input type="checkbox" checked={ue.status} id={ue.id} onChange={(event) => {
                                                                        let data = _.cloneDeep(users);
                                                                        data[i].users[index].status = event.target.checked;
                                                                        setUsers(data);
                                                                    }} />
                                                                </td>
                                                            </tr>)
                                                            : null
                                                    }
                                                </tbody>
                                            </table>

                                            {/* {
                                                e.users.map((ue, ui) => <div key={'user' + ui} className='col-3 mt-1 d-flex'>
                                                    <input type="checkbox" checked={ue.status} id={ue.id} onChange={(e) => {
                                                        let data = _.cloneDeep(users);
                                                        data[i].users[ui].status = e.target.checked;
                                                        setUsers(data);
                                                    }} />
                                                    <p htmlFor={ue.id} className='ml-2 mb-0'>{i + 1}. {ue.name}</p>
                                                </div>)
                                            } */}
                                        </div>
                                    </div>
                                </div>)
                            }
                        </div>
                    </div>
                    <div className='row mt-5'>
                        <div className='col-12 d-flex justify-content-end'>
                            <button type='button' className='btn btn-sm btn-secondary' onClick={() => {
                                setModel(false);
                                setUsers([]);
                            }}>Cancel</button>
                            <button type='button' onClick={handleSaveAtt} className='btn btn-sm btn-success ml-1'>Save</button>
                        </div>
                    </div>
                </Box>
            </Modal>

            <Modal
                open={certModel ? true : false}
                // onClose={() => resetForm()}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={modelBoxStyle}>
                    <div className='row'>
                        <div className='col-12 mb-5'>
                            <h5>Upload Result</h5>
                        </div>
                        <div className='form-group'>
                            <input type="file" className="form-control" accept='image/*,application/pdf' onChange={uploadCert} />
                        </div>
                        <div className='col-12 '>
                            {
                                certModel?.judgeDocument?.length ? <>
                                    {
                                        certModel?.judgeDocument?.map((e, i) => <div key={i} className='row'>
                                            <div className='col-12 justify-content-between d-flex mt-2'>
                                                {getFileName(e.file)} <a target='_blank' className='btn btn-info btn-sm' href={DOMAIN_URL + e.file}>View</a>
                                            </div>
                                        </div>)
                                    }
                                </> : null
                            }
                        </div>
                        <div className='col-12 text-right mt-4'>
                            <button className='btn btn-primary' onClick={() => setCertModel(null)}>Close</button>
                        </div>
                    </div>
                </Box>
            </Modal>

            <Modal
                open={usersModel}
                onClose={() => {
                    setUsersModel(false);
                    setEventUserUsers([]);
                }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={modelBoxStyle}>
                    <div className='row'>
                        <div className='col-12 mb-5'>
                            <h5>Event Users</h5>
                        </div>
                        <div className='col-12 '>
                            <table width={'100%'} border={1} className='text-center'>
                                <tr>
                                    <th>Official Id Proof</th>
                                    <th>Photograph of the Candidate</th>
                                    <th>Name</th>
                                    <th>Candidate Type</th>
                                </tr>
                                {
                                    !eventUserList?.length ? <tr>
                                        <td colSpan={3}> No users Register!</td>
                                    </tr> : null
                                }
                                {
                                    eventUserList?.length ?

                                        eventUserList?.map((e, i) => <tr key={i} >
                                            <td>
                                                {e.officialIdProof ? <img src={DOMAIN_URL + e.officialIdProof} width={200} /> : 'N/A'}
                                            </td>
                                            <td>
                                                {e.photo ? <img src={DOMAIN_URL + e.photo} width={200} /> : 'N/A'}
                                            </td>
                                            <td>
                                                {e.name}
                                            </td>
                                            <td>
                                                {e.candidateType}
                                            </td>
                                        </tr>)

                                        : null
                                }
                            </table>
                        </div>
                        <div className='col-12 text-right mt-4'>
                            <button className='btn btn-primary' onClick={() => {
                                setUsersModel(false);
                                setEventUserUsers([]);
                            }}>Close</button>
                        </div>
                    </div>
                </Box>
            </Modal>
        </>
    );
}

export default JudgesEvent;
