import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { API } from "../Helpers/ApiService";
import { RoutesPath } from "../Routes";
import { URL } from "../Helpers/URL";

const ResetPassword = () => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    let { token } = useParams();

    const [password, setPassword] = useState('');
    const [cpassword, setCPassword] = useState('');

    const onSubmit = (e) => {
        e.preventDefault();
        if (cpassword != password) {
            toast.error('Password does not match!')
            return;
        }
        API({
            url: URL.RESET_PASSWORD + token,
            method: 'POST',
            data: { password }
        }).then(res => {
            if (res?.code) {
                toast.error(res.message)
                return;
            }
            toast.success('Password reset successfully!');
            navigate(`${RoutesPath.Login.path}`);
        })
    }

    return (
        <>
            <center>
                <section className="register-section">
                    <div className="row">
                        <div className="col-md-7 col-sm-12 mt-5">
                            <div className="row justify-content-center mt-5 pt-5">
                                <h2 className="mt-5">{t('reset_password')}</h2>
                                <div className="styled-form register-form mt-2 pt-4 reg-form" style={{ width: '80%' }}>
                                    <form onSubmit={onSubmit}>
                                        <div className="form-group mt-3">
                                            <span className="adon-icon">
                                                <span className="fa fa-unlock-alt"></span>
                                            </span>
                                            <input type="password" name="password" placeholder={t('password')}
                                                style={{ backgroundColor: '#E2E9FF' }} onChange={e => setPassword(e.target.value)} />
                                        </div>
                                        <div className="form-group mt-3">
                                            <span className="adon-icon">
                                                <span className="fa fa-unlock-alt"></span>
                                            </span>
                                            <input type="password" name="password" placeholder={t('confirm_password')}
                                                style={{ backgroundColor: '#E2E9FF' }} onChange={e => setCPassword(e.target.value)} />
                                        </div>
                                        <div className="clearfix">
                                            <div className="form-group">
                                                <button type="submit" className="theme-btn btn-style-one"><span
                                                    className="btn-title">{t('set_password')}</span></button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-5 col-sm-12">
                            <img src={require('../assets/fe/images/register.png')} style={{ width: '95%' }} />
                        </div>
                    </div>
                </section>
            </center>
        </>
    );
}
export default ResetPassword;
