import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { logout, setNotificationCount, setNotification } from "../reducers/authReducer";

import { RoutesPath } from "../Routes";
import { API } from "../Helpers/ApiService";
import { URL } from "../Helpers/URL";

const AdminLayout = (props) => {
    const auth = useSelector(state => state.auth);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const [openNotification, setOpenNotification] = useState(false);
    const [nList, setNList] = useState([]);
    const [nCount, setNCount] = useState(0);
    const [settings, setSettings] = useState(null);

    useEffect(() => {
        if (location.pathname.includes('backoffice')) {
            import('../assets/plugins/fontawesome-free/css/all.min.css');
            import('../assets/plugins/icheck-bootstrap/icheck-bootstrap.min.css');
            import('../assets/css/adminlte.min.css');
            import('../assets/css/custom.css');
        }
        API({
            url: URL.NOTIFICATION_COUNT,
            method: 'GET'
        }).then(res => {
            dispatch(setNotificationCount(res.notificationsCount));
            dispatch(setNotification(res.notifications));
        });
        API({
            url: URL.GET_SETTINGS,
            method: 'GET'
        }).then(res => {
            setSettings(res)
        });
    }, []);

    useEffect(() => {
        if (auth) {
            setNList(auth.notification)
            setNCount(auth.notificationCount)
        }
    }, [auth]);

    return (
        <div className="hold-transition sidebar-mini">
            <div className="wrapper">
                <nav className="main-header navbar navbar-expand navbar-white navbar-light">
                    <ul className="navbar-nav ml-auto">
                        {
                            settings?.supportVideo ? <li className={'nav-item dropdown'}>
                                <a className="nav-link" data-toggle="dropdown" href={settings?.supportVideo} target="_blank">
                                    <i className="fa fa-life-ring " style={{ color: '#00FF00' }}></i>
                                </a>
                            </li> : null
                        }

                        <li className={openNotification ? "nav-item dropdown show" : 'nav-item dropdown'}>
                            <a className="nav-link" data-toggle="dropdown" href="#" onClick={() => { nCount ? setOpenNotification(!openNotification) : null }}>
                                <i className="far fa-bell"></i>
                                {nCount ? <span className="badge badge-warning navbar-badge">{nCount}</span> : null}
                            </a>
                            <div className={openNotification ? 'dropdown-menu dropdown-menu-lg dropdown-menu-right show' : 'dropdown-menu dropdown-menu-lg dropdown-menu-right'}>
                                {nCount ? <>
                                    <span className="dropdown-header">{nCount} Notifications</span>
                                    <div className="dropdown-divider"></div>
                                </> : null}
                                {
                                    nList?.map((e, i) => <div key={i}>
                                        <a href="#" className="dropdown-item">
                                            {e.title}
                                            <p>{e.body}</p>
                                        </a>
                                        <div className="dropdown-divider"></div>
                                    </div>)
                                }

                                <a onClick={() => {
                                    setOpenNotification(!openNotification)
                                    navigate(RoutesPath.Notification.path)
                                }} className="dropdown-item dropdown-footer">See All Notifications</a>
                            </div>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" data-widget="fullscreen" href="#" role="button">
                                <i className="fas fa-expand-arrows-alt"></i>
                            </a>
                        </li>
                        <li className="nav-item">
                            <span className="nav-link" data-widget="fullscreen" onClick={() => dispatch(logout())} role="button">
                                <i className="fas fa-sign-out-alt"></i>
                            </span>
                        </li>
                    </ul>
                </nav>

                <aside className="main-sidebar sidebar-dark-primary elevation-4">
                    <a className="brand-link text-center">
                        <b><span className="brand-text font-weight-bold">AIPDM</span></b>
                    </a>
                    <div className="sidebar">
                        <nav className="mt-2">
                            <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                                <li className="nav-item">
                                    <Link to={RoutesPath.Dashboard.path} className={`nav-link ${location.pathname == RoutesPath.Dashboard.path ? 'active' : ''}`}>
                                        <i className="nav-icon fas fa-th"></i>
                                        <p>
                                            Dashboard
                                            {/* <span className="right badge badge-danger">New</span> */}
                                        </p>
                                    </Link>
                                </li>
                                {
                                    auth?.value?.role == "admin" ? <>
                                        {/* <li className="nav-item">
                                            <Link to={RoutesPath.Country.path} className={`nav-link ${location.pathname == RoutesPath.Country.path ? 'active' : ''}`}>
                                                <i className="nav-icon fas fa-th"></i>
                                                <p>
                                                    Country
                                                </p>
                                            </Link>
                                        </li> */}
                                        <li className="nav-item">
                                            <Link to={RoutesPath.Rank.path} className={`nav-link ${location.pathname == RoutesPath.Rank.path ? 'active' : ''}`}>
                                                <i className="nav-icon fas fa-th"></i>
                                                <p>
                                                    Rank
                                                </p>
                                            </Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link to={RoutesPath.State.path} className={`nav-link ${location.pathname == RoutesPath.State.path ? 'active' : ''}`}>
                                                <i className="nav-icon fas fa-th"></i>
                                                <p>
                                                    Organization
                                                </p>
                                            </Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link to={RoutesPath.Category.path} className={`nav-link ${location.pathname == RoutesPath.Category.path ? 'active' : ''}`}>
                                                <i className="nav-icon fas fa-th"></i>
                                                <p>
                                                    Category
                                                </p>
                                            </Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link to={RoutesPath.Event.path} className={`nav-link ${location.pathname == RoutesPath.Event.path ? 'active' : ''}`}>
                                                <i className="nav-icon fas fa-th"></i>
                                                <p>
                                                    Event
                                                </p>
                                            </Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link to={RoutesPath.SupportDesk.path} className={`nav-link ${location.pathname == RoutesPath.SupportDesk.path ? 'active' : ''}`}>
                                                <i className="nav-icon fas fa-th"></i>
                                                <p>
                                                    Support Desk
                                                </p>
                                            </Link>
                                        </li>
                                        {/* <li className="nav-item">
                                            <Link to={RoutesPath.Language.path} className={`nav-link ${location.pathname == RoutesPath.Language.path ? 'active' : ''}`}>
                                                <i className="nav-icon fas fa-th"></i>
                                                <p>
                                                    Language
                                                </p>
                                            </Link>
                                        </li> */}
                                        <li className="nav-item">
                                            <Link to={RoutesPath.CMSPages.path} className={`nav-link ${location.pathname == RoutesPath.CMSPages.path ? 'active' : ''}`}>
                                                <i className="nav-icon fas fa-th"></i>
                                                <p>
                                                    CMS Pages
                                                </p>
                                            </Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link to={RoutesPath.Speaker.path} className={`nav-link ${location.pathname == RoutesPath.Speaker.path ? 'active' : ''}`}>
                                                <i className="nav-icon fas fa-th"></i>
                                                <p>
                                                    Awards & Winners
                                                </p>
                                            </Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link to={RoutesPath.Users.path} className={`nav-link ${location.pathname == RoutesPath.Users.path ? 'active' : ''}`}>
                                                <i className="nav-icon fas fa-th"></i>
                                                <p>
                                                    Users
                                                </p>
                                            </Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link to={RoutesPath.Notification.path} className={`nav-link ${location.pathname == RoutesPath.Notification.path ? 'active' : ''}`}>
                                                <i className="nav-icon fas fa-th"></i>
                                                <p>
                                                    Notification
                                                </p>
                                            </Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link to={RoutesPath.Feedbacks.path} className={`nav-link ${location.pathname == RoutesPath.Feedbacks.path ? 'active' : ''}`}>
                                                <i className="nav-icon fas fa-th"></i>
                                                <p>
                                                    Feedbacks
                                                </p>
                                            </Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link to={RoutesPath.Glimpses.path} className={`nav-link ${location.pathname == RoutesPath.Glimpses.path ? 'active' : ''}`}>
                                                <i className="nav-icon fas fa-th"></i>
                                                <p>
                                                    Glimpses
                                                </p>
                                            </Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link to={RoutesPath.Download.path} className={`nav-link ${location.pathname == RoutesPath.Download.path ? 'active' : ''}`}>
                                                <i className="nav-icon fas fa-th"></i>
                                                <p>
                                                    Downloads
                                                </p>
                                            </Link>
                                        </li>
                                        {/* <li className="nav-item">
                                            <Link to={RoutesPath.FeedbackReport.path} className={`nav-link ${location.pathname == RoutesPath.FeedbackReport.path || location.pathname == RoutesPath.FeedbackReportDetails.path ? 'active' : ''}`}>
                                                <i className="nav-icon fas fa-th"></i>
                                                <p>
                                                    Feedback Report
                                                </p>
                                            </Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link to={RoutesPath.EventSummaryReport.path} className={`nav-link ${location.pathname == RoutesPath.EventSummaryReport.path ? 'active' : ''}`}>
                                                <i className="nav-icon fas fa-th"></i>
                                                <p>
                                                    Event Summary Report
                                                </p>
                                            </Link>
                                        </li> */}
                                        <li className="nav-item">
                                            <Link to={RoutesPath.PaymentReceipt.path} className={`nav-link ${location.pathname == RoutesPath.PaymentReceipt.path ? 'active' : ''}`}>
                                                <i className="nav-icon fas fa-th"></i>
                                                <p>
                                                    Payment Receipt
                                                </p>
                                            </Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link to={RoutesPath.Upload.path} className={`nav-link ${location.pathname == RoutesPath.Upload.path ? 'active' : ''}`}>
                                                <i className="nav-icon fas fa-th"></i>
                                                <p>
                                                    Uploads
                                                </p>
                                            </Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link to={RoutesPath.Setting.path} className={`nav-link ${location.pathname == RoutesPath.Setting.path ? 'active' : ''}`}>
                                                <i className="nav-icon fas fa-th"></i>
                                                <p>
                                                    Settings
                                                </p>
                                            </Link>
                                        </li>
                                    </> : null
                                }
                                {
                                    auth?.value?.role == "manager" ? <>
                                        <li className="nav-item">
                                            <Link to={RoutesPath.Users.path} className={`nav-link ${location.pathname == RoutesPath.Users.path ? 'active' : ''}`}>
                                                <i className="nav-icon fas fa-th"></i>
                                                <p>
                                                    Users
                                                </p>
                                            </Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link to={RoutesPath.PaymentReceipt.path} className={`nav-link ${location.pathname == RoutesPath.PaymentReceipt.path ? 'active' : ''}`}>
                                                <i className="nav-icon fas fa-th"></i>
                                                <p>
                                                    Payment Receipt
                                                </p>
                                            </Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link to={RoutesPath.Upload.path} className={`nav-link ${location.pathname == RoutesPath.Upload.path ? 'active' : ''}`}>
                                                <i className="nav-icon fas fa-th"></i>
                                                <p>
                                                    Uploads
                                                </p>
                                            </Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link to={RoutesPath.Notification.path} className={`nav-link ${location.pathname == RoutesPath.Notification.path ? 'active' : ''}`}>
                                                <i className="nav-icon fas fa-th"></i>
                                                <p>
                                                    Notification
                                                </p>
                                            </Link>
                                        </li>
                                    </> : null
                                }
                                {
                                    auth?.value?.role == "supportDesk" ? <>
                                        <li className="nav-item">
                                            <Link to={RoutesPath.UserSupportDesk.path} className={`nav-link ${location.pathname == RoutesPath.UserSupportDesk.path ? 'active' : ''}`}>
                                                <i className="nav-icon fas fa-th"></i>
                                                <p>
                                                    Support Desk
                                                </p>
                                            </Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link to={RoutesPath.Notification.path} className={`nav-link ${location.pathname == RoutesPath.Notification.path ? 'active' : ''}`}>
                                                <i className="nav-icon fas fa-th"></i>
                                                <p>
                                                    Notification
                                                </p>
                                            </Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link to={RoutesPath.Upload.path} className={`nav-link ${location.pathname == RoutesPath.Upload.path ? 'active' : ''}`}>
                                                <i className="nav-icon fas fa-th"></i>
                                                <p>
                                                    Uploads
                                                </p>
                                            </Link>
                                        </li>
                                    </> : null
                                }
                                {
                                    auth?.value?.role == "judge" ? <>
                                        <li className="nav-item">
                                            <Link to={RoutesPath.Notification.path} className={`nav-link ${location.pathname == RoutesPath.Notification.path ? 'active' : ''}`}>
                                                <i className="nav-icon fas fa-th"></i>
                                                <p>
                                                    Notification
                                                </p>
                                            </Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link to={RoutesPath.JudgesEvent.path} className={`nav-link ${location.pathname == RoutesPath.JudgesEvent.path ? 'active' : ''}`}>
                                                <i className="nav-icon fas fa-th"></i>
                                                <p>
                                                    Events
                                                </p>
                                            </Link>
                                        </li>
                                    </> : null
                                }
                                {
                                    auth?.value?.role == "superAdmin" ? <>
                                        <li className="nav-item">
                                            <Link to={RoutesPath.PaymentReceipt.path} className={`nav-link ${location.pathname == RoutesPath.PaymentReceipt.path ? 'active' : ''}`}>
                                                <i className="nav-icon fas fa-th"></i>
                                                <p>
                                                    Payment Receipt
                                                </p>
                                            </Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link to={RoutesPath.Upload.path} className={`nav-link ${location.pathname == RoutesPath.Upload.path ? 'active' : ''}`}>
                                                <i className="nav-icon fas fa-th"></i>
                                                <p>
                                                    Uploads
                                                </p>
                                            </Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link to={RoutesPath.Notification.path} className={`nav-link ${location.pathname == RoutesPath.Notification.path ? 'active' : ''}`}>
                                                <i className="nav-icon fas fa-th"></i>
                                                <p>
                                                    Notification
                                                </p>
                                            </Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link to={RoutesPath.SupportDesk.path} className={`nav-link ${location.pathname == RoutesPath.SupportDesk.path ? 'active' : ''}`}>
                                                <i className="nav-icon fas fa-th"></i>
                                                <p>
                                                    Support Desk
                                                </p>
                                            </Link>
                                        </li>
                                    </> : null
                                }
                            </ul>
                        </nav>

                    </div>
                </aside>

                <div className="content-wrapper">
                    <Outlet />
                </div>

                <footer className="main-footer d-print-none">
                    <strong>Copyright &copy; 2022 <a href="https://compughost.com">CCS</a>.</strong> All rights
                    reserved.
                </footer>
            </div>
        </div>
    );
}
export default AdminLayout;