import React, { useState } from "react";;
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";


import { setUser } from "../reducers/authReducer";
import { API } from "../Helpers/ApiService";
import { RoutesPath } from "../Routes";
import { URL } from "../Helpers/URL";

const ForgotPassword = () => {
    const { t, i18n } = useTranslation();

    const [email, setEmail] = useState('');

    const onSubmit = (e) => {
        e.preventDefault();
        API({
            url: URL.FORGOT_PASSWORD,
            method: 'POST',
            data: {
                email: email,
            }
        }).then(res => {
            if (res?.code) {
                toast.error(res.message)
                return;
            }
            setEmail('');
            toast.success('Password reset link has sent to your mail!');
        })
    }

    return (
        <>
            <center>
                <section className="register-section">
                    <div className="row">
                        <div className="col-md-7 col-sm-12 mt-5">
                            <div className="row justify-content-center mt-5 pt-5">
                                <h2 className="mt-5">{t('forgot_password')}</h2>
                                <div className="styled-form register-form mt-2 pt-4 reg-form" style={{ width: '80%' }}>
                                    <form onSubmit={onSubmit}>
                                        <div className="form-group">
                                            <span className="adon-icon">
                                                <span className="fa fa-envelope"></span>
                                            </span>
                                            <input type="email" name="email" value={email} placeholder={t('email')}
                                                style={{ backgroundColor: '#E2E9FF' }} onChange={e => setEmail(e.target.value)} />
                                        </div>
                                        <div className="clearfix">
                                            <div className="form-group">
                                                <button type="submit" className="theme-btn btn-style-one"><span
                                                    className="btn-title">{t('send')}</span></button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-5 col-sm-12">
                            <img src={require('../assets/fe/images/register.png')} style={{ width: '95%' }} />
                        </div>
                    </div>
                </section>
            </center>
        </>
    );
}
export default ForgotPassword;
