import { useEffect, useState } from 'react';
import { ThreeCircles } from 'react-loader-spinner';
import { confirmAlert } from 'react-confirm-alert';
import DataTable from 'react-data-table-component';
import { Editor } from 'react-draft-wysiwyg';
import { Box, Modal } from '@mui/material';
import { toast } from 'react-toastify';
import _ from 'lodash';
import { EditorState, convertToRaw, ContentState, convertFromHTML } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import { modelBoxStyle, DOMAIN_URL } from '../../Helpers/Constants';
import { API } from '../../Helpers/ApiService';
import { RoutesPath } from "../../Routes";
import { URL } from '../../Helpers/URL';
import Language from '../../Component/Language';


const CMSPages = () => {
    const fd = {
        type: '',
        pageTitle: {
            es: "",
            fr: "",
            de: "",
            it: "",
            en: "",
            hi: "",
        },
        title: {
            es: "",
            fr: "",
            de: "",
            it: "",
            en: "",
            hi: "",
        },
        image: '',
        content: {
            es: "",
            fr: "",
            de: "",
            it: "",
            en: "",
            hi: "",
        },
        sliderImages: []
    }
    const columns = [
        {
            name: 'Title',
            center: true,
            selector: row => row.title['en'],
        },
        {
            name: 'Type',
            center: true,
            selector: row => row.type,
        },
        {
            name: 'Status',
            center: true,
            selector: row => row.isActive,
            cell: row => <StatusColumn row={row} />
        },
        {
            name: 'Action',
            center: true,
            cell: row => <ActionColumn row={row} />
        },
    ];
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [model, setModel] = useState(null);
    const [form, setForm] = useState(fd);
    const [formLoading, setFormLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [language, setLanguage] = useState('en');
    const [editor, setEditor] = useState(EditorState.createEmpty());

    useEffect(() => {
        getData();
    }, []);

    useEffect(() => {
        setFormData(draftToHtml(convertToRaw(editor.getCurrentContent())), 'content');
    }, [editor]);

    useEffect(() => {
        setEditor(EditorState.createWithContent(
            ContentState.createFromBlockArray(
                convertFromHTML(form.content[language])
            )
        ));
    }, [language]);

    useEffect(() => {
        getData();
    }, [perPage]);

    const getData = (page = 1) => {
        setLoading(true);
        API({
            url: URL.CMS_PAGES + '?page=' + page + '&limit=' + perPage,
            method: 'GET',
        }).then(res => {
            setLoading(false);
            if (res?.code) {
                return;
            }
            setTotalRows(res.totalResults);
            setData(res.results);
        });
    }

    const handleCreate = () => {
        setModel({
            mode: 'create',
        });
    }

    const resetForm = () => {
        setModel(null);
        setForm(fd);
        setLanguage('en');
        getData();
    }

    const handleEdit = (data) => {
        API({
            url: URL.CMS_PAGES + '/' + data?.id,
            method: 'GET',
        }).then(res => {
            if (res?.code) {
                toast.error(res.message)
                return;
            }
            delete res.id
            delete res.isDeleted
            setEditor(EditorState.createWithContent(
                ContentState.createFromBlockArray(
                    convertFromHTML(res.content[language])
                )
            ));
            setForm(res);
            setModel({
                mode: 'edit',
                editId: data.id
            });
        });
    }

    const handleSave = () => {
        setFormLoading(true);
        API({
            url: model?.mode == 'edit' ? URL.CMS_PAGES + '/' + model?.editId : URL.CMS_PAGES,
            method: model?.mode == 'edit' ? 'PATCH' : 'POST',
            data: form
        }).then(res => {
            setFormLoading(false);
            if (res?.code) {
                toast.error(res.message)
                return;
            }
            resetForm();
        });
    }

    const StatusColumn = ({ row }) => {
        const handleStatusChange = (e) => {
            API({
                url: URL.CMS_PAGES + '/' + row.id,
                method: 'PATCH',
                data: { isActive: e.target.checked ? 'Active' : 'InActive' }
            }).then(res => {
                if (res?.code) {
                    toast.error(res.message)
                    return;
                }
                getData();
            });
        }
        return (
            <div className="form-group">
                <div className="custom-control custom-switch">
                    <input type="checkbox" className="custom-control-input" checked={row.isActive == 'Active' ? true : false} id={row.id} onChange={handleStatusChange} />
                    <label className="custom-control-label" htmlFor={row.id}></label>
                </div>
            </div>
        );
    }

    const ActionColumn = ({ row }) => {
        const removeItem = () => {
            confirmAlert({
                title: 'Are you sure?',
                message: 'Want to delete item.',
                buttons: [
                    {
                        label: 'Yes',
                        onClick: () => API({
                            url: URL.CMS_PAGES + '/' + row?.id,
                            method: 'DELETE',
                        }).then(res => {
                            if (res?.code) {
                                toast.error(res.message)
                                return;
                            }
                            toast.success('Page deleted succesfully');
                            getData();
                        })
                    },
                    { label: 'No', }
                ]
            });

        }
        return (
            <>
                <button className='btn btn-info btn-sm' onClick={() => handleEdit(row)}>< i className='fa fa-edit' /></button>
                {/* <button className='ml-1 btn btn-danger btn-sm' onClick={removeItem}>< i className='fa fa-trash' /></button> */}
            </>
        );
    }

    const setFormData = (value, name) => {
        let data = _.cloneDeep(form);
        if (name == 'pageTitle' || name == 'title' || name == 'content') {
            data[name][language] = value;
        } else {
            data[name] = value;
        }
        setForm(data);
    }

    const onChangeFile = (e, name) => {
        let files = e.target.files;
        if (!files.length) {
            return;
        }
        setFormLoading(true);
        let payload = {};
        if (name == 'image') {
            payload['file'] = files[0];
        } else {
            for (let index = 0; index < files.length; index++) {
                payload['file' + index] = files[index];
            }
        }
        API({
            url: URL.UPLOAD,
            method: 'POST',
            data: payload,
            formData: true
        }).then(res => {
            e.target.value = null;
            setFormLoading(false);
            if (res?.code) {
                toast.error(res.message);
                return;
            }
            if (name == 'image') {
                setFormData('uploads/' + res[0].filename, name);
            } else {
                let data = _.cloneDeep(form);
                res.map(e => data['sliderImages'].push('uploads/' + e.filename));
                setForm(data);
            }
        });
    }

    const removeImage = (index, name) => {
        let data = _.cloneDeep(form);
        if (name == 'slider') {
            data.sliderImages.splice(index, 1);
        } else {
            data.image = '';
        }
        setForm(data);
    }

    const uploadCallback = (file, callback) => {
        return new Promise((resolve, reject) => {
            const reader = new window.FileReader();
            reader.onloadend = async () => {
                const response = await API({
                    url: URL.UPLOAD,
                    method: 'POST',
                    data: { file },
                    formData: true
                });
                resolve({ data: { link: DOMAIN_URL + 'uploads/' + response[0].filename } });
            };
            reader.readAsDataURL(file);
        });
    }

    return (
        <>
            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="m-0">CMS Pages</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><a href={RoutesPath.Admin.Dashboard}>Home</a></li>
                                <li className="breadcrumb-item active">CMS Pages</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>

            <div className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card">
                                <div className="card-header">
                                    <div className='row'>
                                        <div className='col-6 d-flex align-items-center'>
                                            <h3 className="card-title">CMS Pages Listing</h3>
                                        </div>
                                        <div className='col-6 d-flex justify-content-end'>
                                            {/* <button className='btn btn-primary' onClick={handleCreate}>
                                                Create
                                            </button> */}
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className='row'>
                                        <div className='col-12'>
                                            <DataTable
                                                columns={columns}
                                                data={data}
                                                progressPending={loading}
                                                pagination
                                                paginationServer
                                                paginationRowsPerPageOptions={[10, 25, 50, 100, 200]}
                                                paginationTotalRows={totalRows}
                                                onChangeRowsPerPage={(newPerPage, page) => { setPerPage(newPerPage); getData(page); }}
                                                onChangePage={page => getData(page)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal
                open={model ? true : false}
                onClose={() => resetForm()}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={[modelBoxStyle, { width: '60%' }]}>
                    <div className='row'>
                        <div className='col-6'>
                            <h5>{model?.mode == 'create' ? 'Create' : 'Edit'}</h5>
                        </div>
                        <div className='col-6'>
                            <i style={{
                                position: 'absolute',
                                top: -15,
                                right: -15,
                                cursor: 'pointer',
                                padding: 10,
                            }} className='fa fa-times' onClick={() => resetForm()} />
                        </div>
                    </div>
                    <Language lang={language} onChange={lang => setLanguage(lang)} />
                    <div className='row'>
                        <div className='col-6'>
                            <div className="form-group mt-3 mb-3 ">
                                <select className="form-control" defaultValue={form?.type} onChange={e => setFormData(e.target.value, "type")}>
                                    <option value={''} disabled hidden>Select page type</option>
                                    <option value={'About'}>About</option>
                                    <option value={'Media Coverage'}>Media Coverage</option>
                                    <option value={'Glimpse'}>Glimpse of AIPDM</option>
                                    <option value={'InformationDesk'}>Information Desk</option>
                                </select>
                            </div>
                        </div>
                        <div className='col-6'>
                            <div className="form-group mt-3 mb-3 ">
                                <input type="email" className="form-control" value={form.pageTitle[language]} placeholder="Enter page title" onChange={e => setFormData(e.target.value, 'pageTitle')} />
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-6'>
                            <div className="form-group mt-3 mb-3 ">
                                <input type="email" className="form-control" value={form.title[language]} placeholder="Enter title" onChange={e => setFormData(e.target.value, 'title')} />
                            </div>
                        </div>
                        <div className='col-6'>
                            <div className="form-group mt-3 mb-3 ">
                                {
                                    form.image ? <div>
                                        <i style={{
                                            position: 'absolute',
                                            top: 10,
                                            left: 5,
                                            padding: 10,
                                            cursor: 'pointer',
                                        }} className="fa fa-times" onClick={() => removeImage(0, 'image')} />
                                        <img src={DOMAIN_URL + form.image} width="100" className="img-responsive" />
                                    </div> : <input type="file" accept='image/*' onChange={e => onChangeFile(e, 'image')} style={{ marginTop: 3 }} key={form.sliderImages.length} />
                                }
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-12'>
                            <div className="form-group mt-3 mb-3 ">
                                <Editor
                                    toolbar={{
                                        image: {
                                            uploadCallback: uploadCallback,
                                            previewImage: true,
                                            inputAccept: 'image/*',
                                        },
                                    }}
                                    editorState={editor}
                                    onEditorStateChange={setEditor}
                                    wrapperClassName="wrapper-class"
                                    editorClassName="editor-class"
                                    toolbarClassName="toolbar-class"
                                />
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-12 mb-2'>
                            <div className='row'>
                                <div className='col-6'>
                                    <h5>Slider Images</h5>
                                </div>
                                <div className='col-6'>
                                    <input type="file" accept='image/*' onChange={e => onChangeFile(e, 'slider')} style={{ marginTop: 3 }} multiple />
                                </div>
                            </div>
                        </div>
                        {
                            form.sliderImages.map((e, i) => <div key={i} className='col-3'>
                                <i style={{
                                    position: 'absolute',
                                    top: 10,
                                    right: 10,
                                    padding: 10,
                                    cursor: 'pointer',
                                }} className="fa fa-times" onClick={() => removeImage(i, 'slider')} />
                                <img src={DOMAIN_URL + e} className="img-responsive mt-2 mb-2" style={{ width: '100%' }} />
                            </div>)
                        }

                    </div>
                    <div className='row'>
                        <div className='col-12 d-flex justify-content-end'>
                            <button type='button' className='btn btn-sm btn-secondary' onClick={() => resetForm()}>Cancel</button>
                            <button type='button' onClick={handleSave} className='btn btn-sm btn-success ml-1'>Save</button>
                        </div>
                    </div>
                    {
                        formLoading ? <div className='model-overlay'>
                            <ThreeCircles
                                height="100"
                                width="100"
                                color="#4fa94d"
                                wrapperStyle={{}}
                                wrapperClass=""
                                visible={true}
                                ariaLabel="three-circles-rotating"
                                outerCircleColor=""
                                innerCircleColor=""
                                middleCircleColor=""
                            />
                        </div> : null
                    }

                </Box>
            </Modal>
        </>
    );
}

export default CMSPages;
