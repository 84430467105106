import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import Multiselect from 'multiselect-react-dropdown';
import { useTranslation } from 'react-i18next';
import OwlCarousel from 'react-owl-carousel';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import _ from 'lodash';

import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import BGImage11 from '../assets/fe/images/menubg.png';

import { API } from '../Helpers/ApiService';
import { DOMAIN_URL } from '../Helpers/Constants';
import { URL } from '../Helpers/URL';
import { RoutesPath } from '../Routes';

const ContentPage = () => {
    const { t, i18n } = useTranslation();
    let { id } = useParams();
    const navigate = useNavigate();
    const auth = useSelector(state => state.auth);
    const [searchParams] = useSearchParams();

    const [data, setData] = useState(null);
    const [form, setForm] = useState({});
    const [formLoading, setFormLoading] = useState(false);
    const [addons, setAddons] = useState([]);
    const [glimpsesVideos, setGlimpsesVideos] = useState([]);

    useEffect(() => {
        if (id) {
            setData(null);
            API({
                url: URL.GET_PAGE + id,
                method: 'GET',
            }).then(res => {
                if (res?.code) {
                    navigate(`/404`);
                    return;
                }
                // document.title = res?.pageTitle[auth.language]
                setData(res);
                setFormData('support', [{
                    addon: '',
                    otherDetails: ''
                }]);
            });
        } else {
            navigate(`/404`);
        }
    }, [id]);

    useEffect(() => {
        getData();

        if (auth.settings?.glimpsesVideos) {
            setGlimpsesVideos(JSON.parse(auth.settings?.glimpsesVideos))
        }
    }, [auth.language]);

    const getData = () => {
        API({
            url: URL.REGISTER_DATA,
            method: 'GET',
        }).then(res => {
            if (res?.code) {
                toast.error(res.message);
                return;
            }
            setAddons([]);
            let addOns = [];
            res?.addons?.map(e => {
                addOns.push({ id: e.id, addon: e.addon[auth.language] });
            })
            setAddons(addOns);
        });
    }

    const setFormData = (name, value) => {
        let data = _.cloneDeep(form);
        data[name] = value;
        setForm(data);
    }

    const setSupportData = (name, index, value) => {
        let data = _.cloneDeep(form);
        if (name == 'addon') {
            if (data['support'].find(e => e[name] == value)) {
                toast.error('Already selected!');
                return;
            }
        }
        data['support'][index][name] = value;
        setForm(data);
    }

    const addSupportData = () => {
        let data = _.cloneDeep(form);
        data['support'].push({
            addon: '',
            otherDetails: ''
        });
        setForm(data);
    }

    const removeSupportData = (index) => {
        let data = _.cloneDeep(form);
        data['support'].splice(index, 1);
        setForm(data);
    }

    const handleSubmitSupport = () => {
        setFormLoading(true);
        API({
            url: URL.SUPPORT,
            method: 'POST',
            data: form
        }).then(res => {
            setFormLoading(false);
            if (res?.code) {
                toast.error(res.message)
                return;
            }
            toast.success('Message sent successfully!');
            setForm({
                support: [{
                    addon: '',
                    otherDetails: ''
                }]
            });
        });
    }

    return (
        <>
            <section className="page-title content-page-title" style={searchParams.get('hidelayout') ? { backgroundImage: "url(" + BGImage11 + ")", marginTop: 0 } : { backgroundImage: "url(" + BGImage11 + ")" }} >
                <div className="anim-icons full-width">
                    <span className="icon icon-bull-eye"></span>
                    <span className="icon icon-dotted-circle"></span>
                </div>
                <div className="auto-container">
                    <div className="title-outer">
                        <h1>{data?.pageTitle[auth.language]}</h1>
                        {/* <ul className="page-breadcrumb">
                            <li><Link to={RoutesPath.Home.path}>{t('home')}</Link></li>
                            <li>{data?.pageTitle[auth.language]}</li>
                        </ul> */}
                    </div>
                </div>
            </section>
            {
                data?.type == 'InformationDesk' ? <section className="about-section-two pb-0">
                    <div className="auto-container">
                        <div className="row">
                            <div className="col-12">
                                <a class="weatherwidget-io" href="https://forecast7.com/en/26d8580d95/lucknow/" data-label_1="LUCKNOW" data-label_2="WEATHER" data-theme="original" >LUCKNOW WEATHER</a>

                            </div>
                        </div>
                    </div>
                </section> : null
            }
            <section className={`about-section-two ${data?.type == 'InformationDesk' ? 'pt-0' : ''}`}>
                <div className="auto-container">
                    <div className="row">
                        <div className="content-column col-lg-12 col-md-12 col-sm-12">
                            <div className="inner-column">
                                <div className="sec-title">
                                    {/* <span className="sub-title">{data?.pageTitle[auth.language]}</span> */}
                                    <h2>{data?.title[auth.language]}</h2>
                                    <span className="divider"></span>
                                    {
                                        data?.image ? <figure className="image mt-2 text-center"><img src={DOMAIN_URL + data?.image} alt="" /></figure> : null
                                    }

                                    <div className="text cms-text" dangerouslySetInnerHTML={{ __html: data?.content[auth.language] }}>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {
                data?.sliderImages.length ? <section className="about-section-two pt-lg-0">
                    <div className="auto-container">
                        <div className="row">
                            <div className="col-12">
                                <OwlCarousel
                                    className='owl-carousel owl-theme content-slider'
                                    loop
                                    margin={10}
                                    dots={true}
                                    items={3}
                                    nav={true}
                                    autoplay
                                    autoplayTimeout={4000}
                                    smartSpeed={450}
                                >
                                    {
                                        data?.sliderImages.map((e, i) => <div key={i} className='item'>
                                            <img className='img-responsive' src={DOMAIN_URL + e} />
                                        </div>)
                                    }

                                </OwlCarousel>
                            </div>
                        </div>
                    </div>
                </section> : null
            }
            {
                glimpsesVideos.length && data?.type == 'Glimpse' ? <section className="about-section-two pt-lg-0">
                    <div className="auto-container">
                        <div className="row">
                            <div className='col-12'>
                                <OwlCarousel
                                    className='owl-theme glimpses-slider'
                                    loop={false}
                                    margin={10}
                                    nav
                                    responsive={{
                                        0: {
                                            items: 1
                                        },
                                        600: {
                                            items: 3
                                        },
                                        1000: {
                                            items: 3
                                        }
                                    }}
                                >
                                    {
                                        glimpsesVideos.map((e, i) => <div key={i} className='item'>
                                            <video width="100%" height="240" controls>
                                                <source src={DOMAIN_URL + e} type="video/mp4" />
                                            </video>
                                        </div>)
                                    }

                                </OwlCarousel>
                            </div>
                        </div>
                    </div>
                </section> : null
            }
        </>
    );
}
export default ContentPage;
