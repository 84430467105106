import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useSearchParams } from "react-router-dom";

import BGImage11 from '../assets/fe/images/menubg.png';

import { RoutesPath } from "../Routes";
import { API } from "../Helpers/ApiService";
import { URL } from "../Helpers/URL";
import { useSelector } from "react-redux";
import moment from "moment";


const SupportDeskPage = () => {
    const url = window.location.pathname;
    const navigate = useNavigate();
    const auth = useSelector(state => state.auth);
    const { t, i18n } = useTranslation();
    const [searchParams] = useSearchParams();

    const [page, setPage] = useState('');
    const [activeTab, setActiveTab] = useState('');
    const [list, setList] = useState([]);

    useEffect(() => {
        getSupportDesk();
    }, [])

    const getSupportDesk = async () => {
        API({
            url: URL.GET_SUPPORT_DESK,
            method: 'GET',
        }).then(res => {
            setList(res);
            if (res?.length) {
                setActiveTab(res[0]?.id);
            }
        });
    }

    return (
        <>
            <section className="page-title" style={searchParams.get('hidelayout') ? { backgroundImage: "url(" + BGImage11 + ")", marginTop: 0 } : { backgroundImage: "url(" + BGImage11 + ")" }}>
                <div className="anim-icons full-width">
                    <span className="icon icon-bull-eye"></span>
                    <span className="icon icon-dotted-circle"></span>
                </div>
                <div className="auto-container">
                    <div className="title-outer">
                        <h1>{t('support')}</h1>
                    </div>
                </div>
            </section>
            <center>
                <section className="register-section" style={{ overflowY: 'auto', minHeight: '63vh', paddingTop: 10 }}>
                    <div className="auto-container">
                        <div className="col-12 event-list">
                            <div className="tab">
                                {
                                    list?.map((e, i) => <button key={i} className={activeTab == e.id ? "tablinks active" : 'tablinks'} onClick={() => setActiveTab(e.id)}>{e.name[auth.language]}</button>)
                                }
                            </div>


                            {
                                list?.map((e, i) => {
                                    return (
                                        <div key={i} className="tabcontent" style={{ display: activeTab == e.id ? 'block' : 'none' }}>
                                            {e?.content ? <div className="text cms-text" dangerouslySetInnerHTML={{ __html: e.content[auth.language] }}></div> : null}
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </section>
            </center>
        </>
    );
}
export default SupportDeskPage;
