import { useEffect, useState } from 'react';
import { ThreeCircles } from 'react-loader-spinner';
import { confirmAlert } from 'react-confirm-alert';
import DataTable from 'react-data-table-component';
import { Box, Modal } from '@mui/material';
import { toast } from 'react-toastify';
import _ from 'lodash';
import axios from "axios"

import { modelBoxStyle, DOMAIN_URL } from '../../Helpers/Constants';
import { API } from '../../Helpers/ApiService';
import { RoutesPath } from "../../Routes";
import { URL } from '../../Helpers/URL';
import Language from '../../Component/Language';


const Glimpses = () => {

    const [update, setUpdate] = useState(false);
    const [settings, setSettings] = useState({});
    const [uploading, setUploading] = useState(null);

    useEffect(() => {
        getData();
    }, []);

    useEffect(() => {
        if (update) {
            API({
                url: URL.UPDATE_SETTINGS,
                method: 'POST',
                data: settings
            }).then(res => {
                setUpdate(false)
                if (res?.code) {
                    toast.error(res.message)
                    return;
                }
                toast.success('Action performed successfully')
            });
        }
    }, [settings]);

    const getData = () => {
        API({
            url: URL.GET_SETTINGS,
            method: 'GET',
        }).then(res => {
            if (res?.code) {
                return;
            }
            res.sliderImages = res?.sliderImages ? JSON.parse(res.sliderImages) : [];
            res.glimpsesImages = res?.glimpsesImages ? JSON.parse(res.glimpsesImages) : [];
            res.glimpsesVideos = res?.glimpsesVideos ? JSON.parse(res.glimpsesVideos) : [];
            res.tickerArray = res?.tickerArray ? JSON.parse(res.tickerArray) : [];
            setSettings(res);
        });
    }

    const uploadVideo = (e, type = 'video') => {
        setUpdate(true)
        const video = e.target.files[0]
        if (!video) {
            return;
        }
        const TOKEN = localStorage.getItem('token');
        let formData = new FormData();
        formData.append("file", video);

        var ajax = new XMLHttpRequest();
        ajax.upload.addEventListener("progress", (event) => {
            var percent = parseInt((event.loaded / event.total) * 100);
            setUploading({
                total: (event.total / 1000000).toFixed('2'),
                loaded: (event.loaded / 1000000).toFixed('2'),
                percentage: percent,
            })
        }, false);

        ajax.onreadystatechange = function () {
            e.target.value = null;
            setUploading(null)
            if (this.readyState == 4 && this.status == 201) {
                const response = JSON.parse(this.responseText);
                var data = _.cloneDeep(settings);
                if (type == 'video') {
                    data.glimpsesVideos.push('uploads/' + response[0].filename);
                } else {
                    data.glimpsesImages.push('uploads/' + response[0].filename);
                }
                setSettings(data);
            }
        };
        ajax.open("POST", DOMAIN_URL + 'v1/' + URL.UPLOAD);
        ajax.setRequestHeader("Authorization", 'Bearer ' + TOKEN);
        ajax.send(formData);
    }

    const removeVideo = (index, type = 'video') => {
        if (!confirm('Are you sure, want to delete video?')) {
            return;
        }
        setUpdate(true);
        setTimeout(() => {
            var data = _.cloneDeep(settings);
            if (type == 'video') {
                data.glimpsesVideos.splice(index, 1);
            } else {
                data.glimpsesImages.splice(index, 1);
            }
            setSettings(data);
        }, 1000);
    }

    return (
        <>
            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="m-0">Glimpses</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><a href={RoutesPath.Admin.Dashboard}>Home</a></li>
                                <li className="breadcrumb-item active">Glimpses</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>

            <div className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card">
                                <div className="card-header">
                                    <div className='row'>
                                        <div className='col-6'>
                                            Videos
                                        </div>
                                        <div className='col-6 d-flex justify-content-end'>
                                            <div className="upload-btn-wrapper ml-3">
                                                <button className="btn-fileUpload"><i className='fa fa-plus' ></i></button>
                                                <input type="file" accept='video/mp4' onChange={e => uploadVideo(e)} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className='row'>
                                        <div className='col-12'>
                                            {
                                                uploading ? <>
                                                    <progress className='mt-2' id="progressBar" value={uploading?.percentage ?? 0} max="100" style={{ width: '100%' }}></progress>
                                                    <b>Uploaded {uploading?.loaded ?? 0} MB of {uploading?.total ?? 0} MB</b><br />
                                                </> : null
                                            }
                                        </div>

                                        {
                                            settings?.glimpsesVideos?.length ? <>
                                                {
                                                    settings?.glimpsesVideos.map((e, i) => <div key={i} className='col-4 mt-2'>
                                                        <i className='fa fa-times'
                                                            onClick={() => removeVideo(i)}
                                                            style={{
                                                                position: 'absolute',
                                                                top: 5,
                                                                right: 0,
                                                                background: '#000',
                                                                color: '#fff',
                                                                fontWeight: '700',
                                                                padding: '5px 8px',
                                                                borderRadius: 100,
                                                                cursor: 'pointer',
                                                                zIndex: 9999
                                                            }}></i>
                                                        <video width="100%" height="240" controls>
                                                            <source src={DOMAIN_URL + e} type="video/mp4" />
                                                        </video>
                                                    </div>)
                                                }
                                            </> : <div className='col-12 text-center'>
                                                <b>Video not found!</b>
                                            </div>
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-12">
                            <div className="card">
                                <div className="card-header">
                                    <div className='row'>
                                        <div className='col-6'>
                                            Images
                                        </div>
                                        <div className='col-6 d-flex justify-content-end'>
                                            <div className="upload-btn-wrapper ml-3">
                                                <button className="btn-fileUpload"><i className='fa fa-plus' ></i></button>
                                                <input type="file" accept='image/*' onChange={e => uploadVideo(e, 'image')} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className='row'>
                                        <div className='col-12'>
                                            {
                                                uploading ? <>
                                                    <progress className='mt-2' id="progressBar" value={uploading?.percentage ?? 0} max="100" style={{ width: '100%' }}></progress>
                                                    <b>Uploaded {uploading?.loaded ?? 0} MB of {uploading?.total ?? 0} MB</b><br />
                                                </> : null
                                            }
                                        </div>

                                        {
                                            settings?.glimpsesImages?.length ? <>
                                                {
                                                    settings?.glimpsesImages.map((e, i) => <div key={i} className='col-4 text-center mt-2'>
                                                        <i className='fa fa-times'
                                                            onClick={() => removeVideo(i, 'image')}
                                                            style={{
                                                                position: 'absolute',
                                                                top: 5,
                                                                right: 0,
                                                                background: '#000',
                                                                color: '#fff',
                                                                fontWeight: '700',
                                                                padding: '5px 8px',
                                                                borderRadius: 100,
                                                                cursor: 'pointer',
                                                                zIndex: 9999
                                                            }}></i>
                                                        <img src={DOMAIN_URL + e} width="100%" />
                                                    </div>)
                                                }
                                            </> : <div className='col-12 text-center'>
                                                <b>Images not found!</b>
                                            </div>
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </>
    );
}

export default Glimpses;
