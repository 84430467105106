import { useEffect, useState } from 'react';
import { ThreeCircles } from 'react-loader-spinner';
import { confirmAlert } from 'react-confirm-alert';
import DataTable from 'react-data-table-component';
import { Box, Modal } from '@mui/material';
import { toast } from 'react-toastify';
import _ from 'lodash';

import { DOMAIN_URL, modelBoxStyle } from '../../Helpers/Constants';
import { API } from '../../Helpers/ApiService';
import { RoutesPath } from "../../Routes";
import { URL } from '../../Helpers/URL';
import Language from '../../Component/Language';


const Download = () => {
    const fd = {
        title: {
            es: "",
            fr: "",
            de: "",
            it: "",
            en: "",
            hi: "",
        },
        description: {
            es: "",
            fr: "",
            de: "",
            it: "",
            en: "",
            hi: "",
        },
        file: ''
    }
    const columns = [
        {
            name: 'Title',
            center: true,
            selector: row => row.title['en'],
        },
        {
            name: 'Action',
            center: true,
            cell: row => <ActionColumn row={row} />
        },
    ];
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [model, setModel] = useState(null);
    const [form, setForm] = useState(fd);
    const [formLoading, setFormLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [language, setLanguage] = useState('en');
    const [uploading, setUploading] = useState(null);

    useEffect(() => {
        getData();
    }, []);

    useEffect(() => {
        getData();
    }, [perPage]);

    const getData = (page = 1) => {
        setLoading(true);
        API({
            url: URL.DOWNLOADS + '?page=' + page + '&limit=' + perPage,
            method: 'GET',
        }).then(res => {
            setLoading(false);
            if (res?.code) {
                return;
            }
            setTotalRows(res.totalResults);
            setData(res.results);
        });
    }

    const handleCreate = () => {
        setModel({
            mode: 'create',
        });
    }

    const handleEdit = (data) => {
        API({
            url: URL.DOWNLOADS + '/' + data?.id,
            method: 'GET',
        }).then(res => {
            if (res?.code) {
                toast.error(res.message)
                return;
            }
            delete res.id;
            delete res.isDeleted;
            setForm(res);
            setModel({
                mode: 'edit',
                editId: data.id
            });
        });
    }

    const handleSave = () => {
        setFormLoading(true);
        API({
            url: model?.mode == 'edit' ? URL.DOWNLOADS + '/' + model?.editId : URL.DOWNLOADS,
            method: model?.mode == 'edit' ? 'PATCH' : 'POST',
            data: form
        }).then(res => {
            setFormLoading(false);
            if (res?.code) {
                toast.error(res.message)
                return;
            }
            resetForm();
        });

    }

    const ActionColumn = ({ row }) => {
        const removeItem = () => {
            confirmAlert({
                title: 'Are you sure?',
                message: 'Want to delete item.',
                buttons: [
                    {
                        label: 'Yes',
                        onClick: () => API({
                            url: URL.DOWNLOADS + '/' + row?.id,
                            method: 'DELETE',
                        }).then(res => {
                            if (res?.code) {
                                toast.error(res.message)
                                return;
                            }
                            toast.success('Media deleted succesfully');
                            getData();
                        })
                    },
                    { label: 'No', }
                ]
            });

        }
        return (
            <>
                <button className='btn btn-info btn-sm' onClick={() => handleEdit(row)}>< i className='fa fa-edit' /></button>
                <button className='ml-1 btn btn-danger btn-sm' onClick={removeItem}>< i className='fa fa-trash' /></button>
            </>
        );
    }

    const setFormData = (value, name) => {
        let data = _.cloneDeep(form);
        if (name == 'file') {
            data['file'] = value;
        } else {
            data[name][language] = value;
        }
        setForm(data);
    }

    const resetForm = () => {
        setModel(null);
        setForm(fd);
        setLanguage('en');
        getData();
    }

    const uploadMedia = (e) => {
        const video = e.target.files[0]
        if (!video) {
            return;
        }

        const TOKEN = localStorage.getItem('token');
        let formData = new FormData();
        formData.append("file", video);

        var ajax = new XMLHttpRequest();
        ajax.upload.addEventListener("progress", (event) => {
            var percent = parseInt((event.loaded / event.total) * 100);
            setUploading({
                total: (event.total / 1000000).toFixed('2'),
                loaded: (event.loaded / 1000000).toFixed('2'),
                percentage: percent,
            })
        }, false);

        ajax.onreadystatechange = function () {
            e.target.value = null;
            setUploading(null)
            if (this.readyState == 4 && this.status == 201) {
                const response = JSON.parse(this.responseText);
                setFormData('uploads/' + response[0].filename, 'file')
            }
        };
        ajax.open("POST", DOMAIN_URL + 'v1/' + URL.UPLOAD);
        ajax.setRequestHeader("Authorization", 'Bearer ' + TOKEN);
        ajax.send(formData);
    }

    return (
        <>
            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="m-0">Downloads</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><a href={RoutesPath.Admin.Dashboard}>Home</a></li>
                                <li className="breadcrumb-item active">Downloads</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>

            <div className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card">
                                <div className="card-header">
                                    <div className='row'>
                                        <div className='col-6 d-flex align-items-center'>
                                            <h3 className="card-title">Downloads Listing</h3>
                                        </div>
                                        <div className='col-6 d-flex justify-content-end'>
                                            <button className='btn btn-primary' onClick={handleCreate}>
                                                Create
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className='row'>
                                        <div className='col-12'>
                                            <DataTable
                                                columns={columns}
                                                data={data}
                                                progressPending={loading}
                                                pagination
                                                paginationServer
                                                paginationRowsPerPageOptions={[10, 25, 50, 100, 200]}
                                                paginationTotalRows={totalRows}
                                                onChangeRowsPerPage={(newPerPage, page) => { setPerPage(newPerPage); getData(page); }}
                                                onChangePage={page => getData(page)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal
                open={model ? true : false}
                onClose={() => resetForm()}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={modelBoxStyle}>
                    <div className='row'>
                        <div className='col-6'>
                            <h5>{model?.mode == 'create' ? 'Create' : 'Edit'}</h5>
                        </div>
                        <div className='col-6'>
                            <i style={{
                                position: 'absolute',
                                top: -15,
                                right: -15,
                                cursor: 'pointer',
                                padding: 10,
                            }} className='fa fa-times' onClick={() => resetForm()} />
                        </div>
                    </div>
                    <Language lang={language} onChange={lang => setLanguage(lang)} />
                    <div className='row'>
                        <div className='col-12'>
                            <div className="form-group mt-3 mb-3 ">
                                <input type="email" className="form-control" value={form['title'][language]} placeholder="title" onChange={e => setFormData(e.target.value, 'title')} />
                            </div>
                        </div>
                        <div className='col-12'>
                            <div className="form-group mt-3 mb-3 ">
                                <textarea value={form['description'][language]} rows="5" cols="50" name="description" placeholder='Enter Message' className="form-control" onChange={(e) => setFormData(e.target.value, 'description')} />
                            </div>
                        </div>
                        <div className='col-12'>
                            <div className="form-group mt-3 mb-3 ">
                                {
                                    form.file ? <div >
                                        <i className='fa fa-times'
                                            onClick={() => setFormData('', 'file')}
                                            style={{
                                                position: 'absolute',
                                                top: 5,
                                                right: 0,
                                                background: '#000',
                                                color: '#fff',
                                                fontWeight: '700',
                                                padding: '5px 8px',
                                                borderRadius: 100,
                                                cursor: 'pointer',
                                                zIndex: 9999
                                            }}></i>
                                        <video width="100%" height="240" controls>
                                            <source src={DOMAIN_URL + form.file} type="video/mp4" />
                                        </video>
                                    </div> : <>
                                        {
                                            uploading ? <>
                                                <progress className='mt-2' id="progressBar" value={uploading?.percentage ?? 0} max="100" style={{ width: '100%' }}></progress>
                                                <b>Uploaded {uploading?.loaded ?? 0} MB of {uploading?.total ?? 0} MB</b><br />
                                            </> : <input type="file" className="form-control" accept='video/mp4' onChange={uploadMedia} />
                                        }
                                    </>
                                }

                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-12 d-flex justify-content-end'>
                            <button type='button' className='btn btn-sm btn-secondary' onClick={() => resetForm()}>Cancel</button>
                            <button type='button' onClick={handleSave} className='btn btn-sm btn-success ml-1'>Save</button>
                        </div>
                    </div>
                    {
                        formLoading ? <div className='model-overlay'>
                            <ThreeCircles
                                height="100"
                                width="100"
                                color="#4fa94d"
                                wrapperStyle={{}}
                                wrapperClass=""
                                visible={true}
                                ariaLabel="three-circles-rotating"
                                outerCircleColor=""
                                innerCircleColor=""
                                middleCircleColor=""
                            />
                        </div> : null
                    }

                </Box>
            </Modal>
        </>
    );
}

export default Download;