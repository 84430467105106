import '../../assets/plugins/fontawesome-free/css/all.min.css';
import '../../assets/plugins/icheck-bootstrap/icheck-bootstrap.min.css';
import '../../assets/css/adminlte.min.css';

import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { toast } from "react-toastify";

import { API } from '../../Helpers/ApiService';
import { URL } from '../../Helpers/URL';
import { RoutesPath } from '../../Routes';
import { setUser } from '../../reducers/authReducer';



function AdminLogin() {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth);

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    useEffect(() => {
        if (["admin", "manager", 'supportDesk', 'judge', 'superAdmin'].includes(auth?.value?.role)) {
            navigate(`${RoutesPath.Dashboard.path}`);
        }
    }, [auth]);

    const onSubmit = (e) => {
        e.preventDefault();
        const payload = {
            email: email,
            password: password
        }
        API({
            url: URL.adminLogin,
            method: 'POST',
            data: payload
        }).then(res => {
            if (res?.code) {
                toast.error(res.message)
                return;
            }
            dispatch(setUser(res.user));
            localStorage.setItem('token', res.tokens.access.token);
            toast.success('Logged in successfully!');
            if (location.state?.redirect) {
                navigate(location.state?.redirect)
            } else {
                navigate(`${RoutesPath.Dashboard.path}`);
            }
        })
    }

    return (
        <div className='hold-transition login-page'>
            <div className="login-box">
                <div className="card card-outline card-primary">
                    <div className="card-header text-center">
                        <a className="h1">AIPDM</a>
                    </div>
                    <div className="card-body">
                        <p className="login-box-msg">Sign in to start your session</p>

                        <form onSubmit={onSubmit} >
                            <div className="input-group mb-3">
                                <input type="email" className="form-control" placeholder="Email" onChange={e => setEmail(e.target.value)} />
                                <div className="input-group-append">
                                    <div className="input-group-text">
                                        <span className="fas fa-envelope"></span>
                                    </div>
                                </div>
                            </div>
                            <div className="input-group mb-3">
                                <input type="password" className="form-control" placeholder="Password" onChange={e => setPassword(e.target.value)} />
                                <div className="input-group-append">
                                    <div className="input-group-text">
                                        <span className="fas fa-lock"></span>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-8">

                                </div>
                                <div className="col-4">
                                    <button type="submit" className="btn btn-primary btn-block">Sign In</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AdminLogin;
