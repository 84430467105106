import React from 'react';

function Language({ lang, onChange }) {

    return (
        <div className='row language'>
            {/* <div className="col-4">
                <div className="form-group">
                    <div className="form-check">
                        <input className="form-check-input radio-btn" type="radio" onChange={e => onChange('es')} name="language" checked={lang == 'es'} />
                        <label className="form-check-label">Spanish</label>
                    </div>
                </div>
            </div>
            <div className="col-4">
                <div className="form-group">
                    <div className="form-check">
                        <input className="form-check-input radio-btn" type="radio" onChange={e => onChange('fr')} name="language" checked={lang == 'fr'} />
                        <label className="form-check-label">French</label>
                    </div>
                </div>
            </div>
            <div className="col-4">
                <div className="form-group">
                    <div className="form-check">
                        <input className="form-check-input radio-btn" type="radio" onChange={e => onChange('de')} name="language" checked={lang == 'de'} />
                        <label className="form-check-label">German</label>
                    </div>
                </div>
            </div>
            <div className="col-4">
                <div className="form-group">
                    <div className="form-check">
                        <input className="form-check-input radio-btn" type="radio" onChange={e => onChange('it')} name="language" checked={lang == 'it'} />
                        <label className="form-check-label">Italian</label>
                    </div>
                </div>
            </div> */}
            <div className="col-6">
                <div className="form-group">
                    <div className="form-check">
                        <input className="form-check-input radio-btn" type="radio" onChange={e => onChange('en')} name="language" checked={lang == 'en'} />
                        <label className="form-check-label">English</label>
                    </div>
                </div>
            </div>
            <div className="col-6">
                <div className="form-group">
                    <div className="form-check">
                        <input className="form-check-input radio-btn" type="radio" onChange={e => onChange('hi')} name="language" checked={lang == 'hi'} />
                        <label className="form-check-label">Hindi</label>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Language;
