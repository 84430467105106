import { createSlice } from '@reduxjs/toolkit'

export const authSlice = createSlice({
    name: 'user',
    initialState: {
        value: null,
        language: 'en',
        settings: {},
        notificationCount: 0,
        notification: [],
    },
    reducers: {
        setUser: (state, action) => {
            state.value = action.payload
        },
        logout: (state) => {
            localStorage.removeItem('token')
            state.value = null
        },
        setLanguage: (state, action) => {
            state.language = action.payload;
        },
        setSettings: (state, action) => {
            state.settings = action.payload;
        },
        setNotificationCount: (state, action) => {
            state.notificationCount = action.payload;
        },
        setNotification: (state, action) => {
            state.notification = action.payload;
        },
    }
})

export const { setUser, logout, setLanguage, setSettings, setNotificationCount, setNotification } = authSlice.actions

export default authSlice.reducer;