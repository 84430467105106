import { RoutesPath } from "../Routes";
import { BASE_URL } from "./Constants";

export const API = async ({ url, method, data, formData }) => {
    let Headers = {};

    const TOKEN = await localStorage.getItem('token');
    const lang = await localStorage.getItem('lang');
    if (TOKEN) {
        Headers["Authorization"] = 'Bearer ' + TOKEN
    }

    Headers["language"] = lang ? lang : 'en';
    Headers["Accept"] = "application/json";
    if (!formData) {
        Headers["Content-Type"] = "application/json";
    }

    let options = {
        method: method,
        headers: Headers
    };
    if (!formData && data) {
        options['body'] = JSON.stringify(data);
    }
    if (formData) {
        let formData = new FormData();
        for (let key in data) {
            if (Array.isArray(data[key])) {
                for (var i = 0; i < data[key].length; i++) {
                    formData.append(`${key}[${i}]`, data[key][i]);
                }
            } else {
                formData.append(key, data[key]);
            }
        }
        options['body'] = formData;
    }
    // console.log(url, options)
    const response = fetch(BASE_URL + url, options).then(response => response.json()).then(res => {
        if (res?.code) {
            handleError(res, url)
        }
        return res
    }).catch(error => {
        console.log(error);
        return null;
    });
    return response
}
const handleError = (res, url) => {
    if (res.code === 401 && !url.includes('auth/me') && !url.includes('login') && !url.includes('register')) {
        if (window.location.pathname.includes('backoffice')) {
            window.location.href = RoutesPath.Admin.path;
        } else {
            window.location.href = RoutesPath.Login.path;
        }
        return;
    }
}